<div class="container-fluid ">

  <div class="row mt-1" >

    <div class="col-lg-1 col-2 p-2 d-flex justify-content-center " >
      <img class="img-fluid " src="../../../../../assets/images/rec-payments/trash_button.svg">
    </div>

    <div class="col-lg-10 col-8 d-flex align-items-center">
      <p class="p-title my-2 "
      >Delete Alert</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-center  cancel-edit" >
      <img (click)="cancelDeleteAlert()" class="img-fluid button-pointer"
           src="../../../../../assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row m-2 ">
    <p id="msgConfirm" class="p-wording ">Confirm you want to delete the alerts selected.</p>
  </div>

  <div class="row text-center my-3">
    <div>
      <img src="../../../../../assets/images/alerts/warning_triangle.svg" class="img-fluid" alt="Warning">
    </div>
  </div>

  <div class="row my-4 mx-1">
    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center align-items-center">
      <button class="violet-button  py-3 w-100 rounded-3 button-pointer " id="btnDelete" (click)="deleteAlert()">Delete</button>
    </div>

    <div class="col-lg-6 col-12  my-2 d-flex justify-content-center align-items-center">
      <button class="yellow-button  w-100 py-3 rounded-3 button-pointer" id="btnCancel" (click)="cancelDeleteAlert()">Cancel</button>
    </div>
  </div>

</div>
