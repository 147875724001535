<div class="d-block d-lg-none">
  <app-nav-bar-horizontal></app-nav-bar-horizontal>
</div>

<app-nav-bar></app-nav-bar>

<div class="div-size-sidebar">
    <app-nav-buttons></app-nav-buttons>
    <div>
        <div>
          <button id="newDealsBtn" routerLink="/main/couponsDiscounts" >
            Add new deals
          </button>
        </div>

        <h2 id="allDiscounts" (click)="seeAllDiscounts()" class="div-cursor" >Your Coupons and Discounts</h2>
        <div *ngIf="CsAndDs && !displayDescBoolean" class="display-flex-div" >
          <div *ngFor="let cd of CsAndDs ; let idx = index" class="display-coupon">
            <div  id="{{idx}}" (click)="displayDescription(cd) "  >
              <p class="p-merchant mt-3">{{cd.merchant_name}}</p >
              <p *ngIf="cd.type==1" class="p-reward">USD {{cd.amount}} BACK </p>
              <p id="perc" *ngIf="cd.type==0" class="p-reward">{{cd.percentage}} BACK </p>
              <p id="date" class="p-reward">EXP DATE: {{cd.exp_date}} </p>
            </div>
          </div>
      </div>

      <div *ngIf="CsAndDs && displayDescBoolean" >
        <div *ngFor="let cd of CsAndDs ;let ix = index" class="display-flex-div">
          <div id="{{ix}}" class="display-coupon"   >
            <p class="p-merchant mt-3">{{cd.merchant_name}}</p>
            <p *ngIf="cd.type==1" class="p-reward">USD {{cd.amount}} BACK </p>
            <p *ngIf="cd.type==0" class="p-reward">{{cd.percentage}} BACK </p>
            <p class="p-reward">EXP DATE: {{cd.exp_date}} </p>
          </div>
          <p>{{cd.description}}</p>
        </div>
      </div>

    </div>
</div>
