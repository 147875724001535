import { Component } from '@angular/core';

@Component({
  selector: 'app-recip-modal',
  templateUrl: './recip-modal.component.html',
  styleUrls: ['./recip-modal.component.css']
})
export class RecipModalComponent {

}
