import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CardLimitGlComponent} from "../../card-limit-gl/card-limit-gl.component";

import {CardLimitMccComponent} from "../../card-limit-mcc/card-limit-mcc.component";
import {Subject} from "rxjs";
import {CardLimitSummaryComponent} from "../../card-limit-summary/card-limit-summary.component";
import {Router} from "@angular/router";
import {EditCardGLLimit, MCCCard, MCCDataAll} from "../../../../../services/card.service";
import {CardLMerchEditComponent} from "../card-l-merch-edit/card-l-merch-edit.component";
import {CardLMccEditComponent} from "../card-l-mcc-edit/card-l-mcc-edit.component";

@Component({
  selector: 'app-card-limit-edit',
  templateUrl: './card-limit-edit.component.html',
  styleUrls: ['./card-limit-edit.component.css']
})
export class CardLimitEditComponent implements OnInit{

  // List of Limits
  @Input() limitsListMCC !: MCCDataAll[]
  @Input() limitsListMCCCodes !: string[]
  @Input() limitsListMerch !: any[]
  @Input() limitsListGL !: EditCardGLLimit
  @Input() loan_id !:string
  @Input() card_id !:string
  @Output() closeEditLimits = new EventEmitter<boolean>()

  isGeneralLimit :boolean = false
  isMerchantLimit !:boolean
  isMCCLimit !:boolean


  constructor(
    public router: Router,
    public dialog : MatDialog,

  ) { }

  isGeneralLimitEE :Subject<boolean> = new Subject<boolean>()
  isMerchLimitEE :Subject<boolean> = new Subject<boolean>()
  isMCCLimitEE :Subject<boolean> = new Subject<boolean>()

  //Access Child components
  @ViewChild(CardLimitGlComponent) GeneralLimitComponent:any ;
  @ViewChild(CardLMerchEditComponent) MerchantLimitComponent:any ;
  @ViewChild(CardLMccEditComponent) CardLimitMccComponent:any ;

  cancelSetLimit():void{
    this.closeEditLimits.emit(false)
    const currentUrl = '/main/cards';
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})
  }

  submitLimits():void{

    // Check errors and display summary
    if(this.isGeneralLimit == true) {
      this.limitsListGL = this.GeneralLimitComponent.returnLimitData()
      let errorsGL = this.GeneralLimitComponent.getvalidationErrors()
      if( errorsGL ==!{}) return
    }

    if(this.isMerchantLimit == true) {
      this.limitsListMerch = this.MerchantLimitComponent.returnMerchantData()
    }


    if(this.isMCCLimit == true) {
      this.limitsListMCC = this.CardLimitMccComponent.returnMCCData()
      this.limitsListMCCCodes = this.CardLimitMccComponent.returnMCCCodes()
    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      glLimits :this.limitsListGL ,
      merchLimits : this.limitsListMerch,
      mccLimits : this.limitsListMCC,
      mccCodes : this.limitsListMCCCodes,
      card_id : this.card_id,
      loan_id : this.loan_id,
      isEdit:  true,
     // getData: false,
    }

      dialogConfig.panelClass = 'full-60-dialog';

      this.dialog.open(CardLimitSummaryComponent, dialogConfig)

  }
  ngOnInit() {

      if(this.limitsListGL) {
        this.isGeneralLimit = true
      } else {
        this.isGeneralLimit = false
      }

      if(this.limitsListMerch){
        this.isMerchantLimit = true
      } else {
        this.isMerchantLimit = false
      }

      if(this.limitsListMCC){
        this.isMCCLimit = true
      } else {
        this.isMCCLimit = false
      }

    }


}


