import {Component, OnInit} from '@angular/core';
import {PayrollService} from "../../../services/payroll.service";
import {SharedataService} from "../../../services/sharedata.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/auth.service";
declare const Argyle: any;
@Component({
  selector: 'app-payroll-manage',
  templateUrl: './payroll-manage.component.html',
  styleUrls: ['./payroll-manage.component.css']
})
export class PayrollManageComponent implements OnInit{

  argyleAccountId !:string
  ddsConfigData !:string



  constructor(
    private payrollService: PayrollService,
    private router : Router,
    private authService: AuthService,

    private shareDataService: SharedataService
  ) { }

  accessArgyle(data:string):void{

    this.shareDataService.getConfigDDS().subscribe((value:string)=>{
      this.ddsConfigData = value;

      const linkInstance = Argyle.create({
        linkKey: environment.ARGYLE_LINKKEY,
        userToken:  data,
        sandbox: true, // Set to false for production environment.
        flowId: 'VKDF86WX',
        // (Optional) Add a Link flow customization created in Console:
        //    flowId: '<ID of the Link flow>',
        // (Optional) Add a deposit switch flow:
        //    ddsConfig: '<Encrypted target deposit destination value>',
        ddsConfig: this.ddsConfigData,
        // (Optional) Limit Link search to specific Items:
        //    items: ['item_000001422', 'item_000025742'],
        // (Optional) Connect directly to an existing account:
        //    accountId: '<ID of the account>', // ID of a disconnected account
        accountId: this.argyleAccountId,
        // (Optional) A few recommended callbacks:
        onAccountConnected: (payload:any) => {
          //console.log(payload)
          //console.log('onAccountConnected')
          this.router.navigateByUrl('/main/home')
        },
        onAccountError: (payload:any)=> {
          //console.log('onAccountError', payload)
          linkInstance.close()
          this.authService.logout()
          localStorage.clear()
          this.router.navigateByUrl('/login')

        },
        onDDSSuccess: (payload:any) => {
          //console.log('onDDSSuccess', payload)
          this.router.navigateByUrl('/main/home')
        },
        onDDSError: (payload:any) => {
          //console.log('onDDSError', payload)
          linkInstance.close()
          this.authService.logout()
          localStorage.clear()
          this.router.navigateByUrl('/login')
        },
        onTokenExpired: (updateToken:any) => {
          //console.log('onTokenExpired')
          linkInstance.close()
          this.authService.logout()
          localStorage.clear()
          this.router.navigateByUrl('/login')
        },
      })
      linkInstance.open()

    })


  }
  ngOnInit() {
    this.payrollService.getPayrollUser().subscribe({
      next:(res)=>{ if(res.e_code ==1){
          this.accessArgyle(res.res.payroll_user_token)
          this.argyleAccountId = res.res.payroll_accounts[0].account_id
      }}
    })
  }

}
