import {Component, Inject, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-error-dialog-msg',
  templateUrl: './error-dialog-msg.component.html',
  styleUrls: ['./error-dialog-msg.component.css']
})
export class ErrorDialogMsgComponent {
  title !: string
  status !: number
  statusText !: string
  url!: string
  message !: string

  constructor(
   public dialogRef: MatDialogRef<ErrorDialogMsgComponent>,
   public authService: AuthService,
   @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeMessage():void{
    this.dialogRef.close()
    this.authService.logout()
  }
  ngOnInit( ) {

    this.status = this.data.status|| 500
    this.statusText = this.data.e_msg || "We were unable to process your request"
  }
}
