<div class="container-fluid" >

  <div class="row p-1" >

    <div class="col-lg-1 col-2 p-0 text-center" >
      <img class="img-fluid m-0"
           src="assets/images/recipients/recipient_icon.svg"
      />
    </div>

    <div class="col-lg-10 col-8 d-flex align-items-center">
      <p class="p-title mb-0 ">Recipient Details</p>
    </div>

    <div class="col-lg-1 col-2 p-0 text-center">
      <img (click)="cancelDetailDisplay()" class="img-fluid m-0 button-pointer"
           src="assets/images/edit_profile/cancel_edit.svg"
      />
    </div>

  </div>

  <div class="row m-1">
    <div class="text-center">
      <img src="assets/images/recipients/recipient_details_logo.svg">
      <p id="recFullName" class="p-fullName">{{selectedRecipient.first_name}} {{selectedRecipient.last_name}}</p>
    </div>
  </div>

  <!--Confirm recipient data  showConfirmInitialData *ngIf="selectedRecipient.email"  -->

  <div *ngIf="this.showConfirmInitialData">
        <div class="row my-3"    >
          <div class="col-lg-6 col-12 text-center">
            <p class="fw-bold my-2">Email</p>
            <div id="recEmail" class="p-data rounded-3 py-2" *ngIf="selectedRecipient.email">
              {{selectedRecipient.email}}
            </div>
          </div>
          <div class="col-lg-6 col-12 text-center"  >
            <p class="fw-bold my-2">Phone</p>
              <div id="recPhone"  class="p-data rounded-3 py-2" *ngIf="selectedRecipient.phone">
                {{selectedRecipient.phone}}
              </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center my-2">

          <div class="col-lg-6  col-12 my-2">
            <button id="btnAlertDelete"
                    (click)="alertDeleteRecipient()"
                    class="  violet-button w-100 rounded-4 py-3  p-2 button-pointer border-0"  >
              Delete Recipient
            </button>
          </div>

        </div>
  </div> <!--close ngif-->


  <!--Confirm delete recipient showConfirmDelete-->

  <div *ngIf="this.showConfirmDelete">

     <div class="row my-3">
       <div class="col-12 text-center">
         <p class="p-title">Confirm to delete recipient or close window to cancel.</p>
       </div>
     </div>

     <div class="row d-flex justify-content-center my-3">
       <div class=" col-12 col-lg-8">
         <button id="btnDelete"
                 class=" p-2 py-3 violet-button rounded-4 w-100 button-pointer"
                 (click)="deleteSelectedRecipient()">
           Delete Recipient
         </button>
       </div>

     </div>

   </div>  <!--close ngif-->


  <!-- Success Delete deleted_success -->

  <div *ngIf="this.deleted_success">

    <div class="row my-3">
      <div class="col-12 text-center">
        <p class="p-title">The recipient was deleted successfully.</p>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 text-center">
        <img src="assets/images/recipients/success_recipient.svg"
        class="img-fluid my-3">
      </div>
    </div>

    <div class="row d-flex justify-content-center my-3">
      <div class="col-lg-8 col-12">
        <button
          id="btnCloseSuccess"
          class="text-center p-2 py-3 violet-button rounded-3 mx-2 w-100 border-0"
          (click)="cancelDetailDisplay()">
          Close
        </button>
      </div>

    </div>

  </div> <!--close ngif-->


  <!-- Failure Delete deleted_success -->

  <div *ngIf="!this.deleted_success">

    <div class="row my-3">
      <div class="col-12 text-center">
        <p class="p-title">The recipent has not been remove form your list. </p>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 text-center">
        <img src="assets/images/recipients/failure_recipient.svg"
             class="img-fluid my-3">
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-8 col-12">
        <button
          id="btnCloseFailure"
          class="text-center p-2 py-3 violet-button rounded-4 mx-2 w-100 border-0"
          (click)="cancelDetailDisplay()">
          Try Again
        </button>
      </div>
    </div>

  </div> <!--close ngif-->


</div>
