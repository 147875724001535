<div class="container-fluid ">

  <!--Display Spending Limits -->
  <div class="row my-2" *ngIf="merchantsList.length > 0" >
    <div class="col-lg-10 offset-lg-1 col-12  my-2 ">
      <div class="row text-center my-3" >
        <p  class="p-merchant-name ">Remove Merchant Limits</p>
      </div>
      <div class="row p-merchant-border rounded-3">
        <div *ngFor="let merch of merchantsList">
          <div class="row py-2 p-merchant-border ">
            <div class="col-lg-1 col-2 "  >
              <img id="btnDelete" src="assets/images/cards/merchant_icon.svg"
                   (click)="deleteMerchantLimitAlert(merch.merchants_info[0])"
                   class="img-fluid m-0 ">
            </div>
            <div class="col-lg-7 col-6 d-flex  align-content-center button-pointer p-0">
              <p id="p-merchant-name" class="p-merchant-name mb-0 ">{{merch.merchants_info[0]}}</p>
            </div>
            <div class="col-2 d-flex justify-content-center align-content-center p-0">
              <p id="p-merchant-amount" class="p-merchant-amount mb-0" >${{merch.amount}}</p>
            </div>
            <div class="col-2 d-flex justify-content-center align-content-center p-0">
              <p id="p-merchant-period" class="p-merchant-period  mb-0  ">{{merch.period}}</p>
            </div>
          </div>
        </div>
      </div>
    </div><!--Close col -->
  </div> <!--Close row -->

  <!-- Confirm Delete Message  -->
  <div *ngIf="isDeleteMechanLimit">
    <app-modal-new-acc>
      <app-card-limit-msg
        [title]="'Edit Merchant Limit: ' + nameDeleteMerchant"
        [message]="'The current general limit will be removed.'"
        (confirmEditLimit)= delete($event)
      ></app-card-limit-msg>
    </app-modal-new-acc>
  </div>

</div>
