import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent} from "../components/Errors/error-dialog/error-dialog.component";
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  constructor(
    private modalService: NgbModal,

  ) {}

  errorsDetails :string[] = []
  openDialog(error: HttpErrorResponse): void {
    console.log("ERROR")
    console.log(error)

    const modalRef = this.modalService.open(ErrorDialogComponent);
    modalRef.componentInstance.status = error.status;
    modalRef.componentInstance.statusText = error.error.e_msg || error.error.response.e_msg;
    modalRef.componentInstance.url = error.url;
    modalRef.componentInstance.all = error
    modalRef.componentInstance.errorBE = error.error.error
    modalRef.componentInstance.errorsDetails = error.error.data || []

  }
}
