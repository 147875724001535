import {Component, Input} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {CardService, MerchantCard} from "../../../../../services/card.service";
import {HomeService} from "../../../../../services/home.service";
import {FormFieldConfig} from "../../../../Shared/forms-shared/typesForm";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-card-l-merch-edit',
  templateUrl: './card-l-merch-edit.component.html',
  styleUrls: ['./card-l-merch-edit.component.css']
})
export class CardLMerchEditComponent {

  @Input() editDataMerc !:any[]
  @Input() validateOn: string = 'change'
  @Input() isMerchLimit: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router : Router,
    private cardService : CardService,
  ) { }


  // Delete Merchants
  isDeleteMechanLimit : boolean = false
  nameDeleteMerchant !:string


  merchantsFilter :string[] =[]
  merchants : string[] = []


  // Merchants
  merchantsList : any [] = []


  deleteMerchantLimitAlert(merch:string){
    this.isDeleteMechanLimit = true
    this.nameDeleteMerchant = merch
  }

  delete(data:boolean){
    this.isDeleteMechanLimit = false
    if (data) {
      this.deleteMerchant(this.nameDeleteMerchant)
    }
  }
  deleteMerchant(data:string):void {
      let dataMerc = this.editDataMerc.filter((m:any) => m.merchants_info[0] === data)[0]
      let payload = {
        spendinglimit_id: dataMerc.spendinglimit_id,
        card_id:dataMerc.card_id,
        loan_id:dataMerc.loan_id,  limited: 1
      }
      this.cardService.deleteSpendLimit(payload).subscribe({ next:(res) =>{
          let  newMerchantList = this.merchantsList.filter((m:MerchantCard) => m.merchants_info[0]!==data)
          this.merchantsList = newMerchantList
          this.merchantsFilter = this.merchantsList.map((m:MerchantCard) => m.merchants_info[0])
        }})
    }


  returnMerchantData(){  // The function is executed by Card Limit Summary
    if (this.merchantsList.length === 0){
      return undefined
    } else {
      return this.merchantsList
    }
  }

  getMerchantsFilter(data:any) {
    this.merchantsFilter = data.map((limit:any)=>limit.merchants_info[0])
  }
  ngOnInit() {
      // Filter out merchant names already included
      this.getMerchantsFilter(this.editDataMerc)

      for(let limit of this.editDataMerc){
        let lim = {
          merchants_info: limit.merchants_info,
          amount:limit.amount,
          period: limit.period,
          type: limit.type,
          spendinglimit_id : limit.spendinglimit_id
        }
        this.merchantsList.push(lim)
      }
  }
}

