import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-none',
  templateUrl: './modal-none.component.html',
  styleUrls: ['./modal-none.component.css']
})
export class ModalNoneComponent {

}
