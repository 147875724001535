import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-vertical',
  templateUrl: './modal-vertical.component.html',
  styleUrls: ['./modal-vertical.component.css']
})
export class ModalVerticalComponent {

}
