import {Component, OnInit} from '@angular/core';
import { RecpaymentsService, RecurrentItem } from "../../../services/recpayments.service";
import {CardService} from "../../../services/card.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {RecPayNewComponent} from "../rec-pay-new/rec-pay-new.component";
import {RecPayDeleteComponent} from "../rec-pay-delete/rec-pay-delete.component";

@Component({
  selector: 'app-rec-payments',
  templateUrl: './rec-payments.component.html',
  styleUrls: ['./rec-payments.component.css']
})
export class RecPaymentsComponent implements OnInit{

  your_route:string = "Recurring Payments"
  search_word !:string

  rec_items !: RecurrentItem[]
  rec_items_all !: RecurrentItem[]

  isDeleteAll !:boolean
  deleteData !:RecurrentItem
  senders_list !: string[][]
  constructor(
    private recPaymentsService : RecpaymentsService,
    private cardService : CardService,
    public dialog : MatDialog
  ) { }


  showDeletePayment(data:RecurrentItem):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { deleteData: data, isDeleteAll: true  }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecPayDeleteComponent,dialogConfig)
  }

  filterRecPayments(data:string): void{
    if(data){
      const filteredList = this.rec_items_all.filter(rp=>rp.memo.toLowerCase()
        .includes(data.toLowerCase()))
      this.rec_items = filteredList
    }
    if(data === ''){
      this.rec_items = this.rec_items_all
   }
  }

  getSendersList():void { // CHECK ACCOUNT ID !!

    this.cardService.getCards().subscribe({ next:(res)=>{
        if(res.e_code ===1){
          let cards_list = res.ret.map((card:any)=>[
            card.card.id, card.card.network+" ****" + card.card.number.toString().slice(-4)])

          let accounts_list = Array.from(new Set(res.ret.map((acc:any)=>
            //[acc.id, "Kredio ****" + acc.number.toString().slice(-4)])))
            [acc.account_id, "Kredio ****" + acc.number.toString().slice(-4)])))

          this.senders_list = [...cards_list ,... accounts_list]
        }}
    })
  }

  addRecPayments():void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { accounts_sender: this.senders_list }
    dialogConfig.panelClass = 'full-40-dialog'
    this.dialog.open(RecPayNewComponent,dialogConfig)
  }

  ngOnInit() {
    this.recPaymentsService.getRecurrentItems().subscribe({
      next:(res)=>{
        this.rec_items_all = res.ret
        this.rec_items = this.rec_items_all
      }})
    this.getSendersList()
  }

}


