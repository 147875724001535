import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CardService, EditCardGLLimit, MCCCard, MCCDataAll, MerchantCard} from "../../../../../services/card.service";
import {Router} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";


@Component({
  selector: 'app-card-l-sum-edit',
  templateUrl: './card-l-sum-edit.component.html',
  styleUrls: ['./card-l-sum-edit.component.css']
})
export class CardLSumEditComponent implements OnInit{
  @Input() card_id !:string
  @Input() loan_id !:string
  limitsSummaryMCC !:MCCDataAll[]
  limitsSummaryMerch !:any[]
  limitsSummaryGL !: EditCardGLLimit


  periods = ["Weekly", "Bi-weekly" , "Monthly"]
  limitsSummaryMCCCodes !: string[]
  isCardLimit : boolean = false

  isEditLimit : boolean = false
  @Output() closeEditFeature = new EventEmitter<boolean>()

  message !:string
  constructor(
    private cardService: CardService,

  ) { }

  editSpendingLimits(b:boolean):void {
    if (this.isCardLimit === true) {
      this.isEditLimit = b
    } else {
      this.isEditLimit = false
    }

  }
  cancelEditLimits():void {
    this.closeEditFeature.emit(false)
  }

  ngOnInit() {
    // Edit Limits
      let payload = { loan_id: this.loan_id, card_id: this.card_id }

      this.cardService.getSpendLimit(payload).subscribe({ next:(res)=>{
          if(res.e_code === 1 ) {
            this.isCardLimit = true
            if (res.ret.general.length>0){
              this.limitsSummaryGL = res.ret.general[0]
              let periodGL = this.periods[res.ret.general[0].period]
              this.limitsSummaryGL.period = periodGL
              this.limitsSummaryGL.spendinglimit_id = res.ret.general[0].spendinglimit_id
            }
            if(res.ret.merchants.length >0) {
              this.limitsSummaryMerch = []
              res.ret.merchants.forEach((limit:any)=>{

                let merchName = limit.merchants.map((m:any) => m.merchant_name)
                limit['merchants_info'] = merchName

                let periodML = this.periods[limit.period]
                limit['period'] = periodML

                this.limitsSummaryMerch.push(limit)
              })
            }
            if(res.ret.categories.length>0) {
              this.limitsSummaryMCC = []
              this.limitsSummaryMCCCodes = res.ret.categories.map((c:any) => c.merchants[0].mcc_code)
              res.ret.categories.forEach((cat:any)=>{
                let merchName = cat.merchants.map((m:any) => m.mcc_name)
                cat['merchants_info'] = merchName
                let periodML = this.periods[cat.period]
                cat['period'] = periodML
                this.limitsSummaryMCC.push(cat)
              })
            }
          }
        }})
    }
  }

