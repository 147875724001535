import {Component, Input} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {CardService, MCCCard, MerchantCard} from "../../../../services/card.service";
import {HomeService} from "../../../../services/home.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-card-limit-mcc',
  templateUrl: './card-limit-mcc.component.html',
  styleUrls: ['./card-limit-mcc.component.css']
})
export class CardLimitMccComponent {

  @Input() validateOn: string = 'change'
  @Input() isMCCLimit: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router : Router,
    private cardService : CardService,
    private homeService: HomeService

  ) { }

  // Web socket Token
  socket !: WebSocket
  token !:string

  // MCC Codes
  mccCodes:string[] = [] // SSE complete list
  codeList:string[] = []


  // MCCs
  viewMCCList:boolean = false
  mccs : string[] = []

  mccsFilter :string[] =[]
  codeMccsFilter :string[] =[]

  mccsList : MCCCard [] = []
  fieldWasEdited: any = {}
  fieldsConfig !: FormFieldConfig[]
  validationErrors : any = {}
  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }

  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:any) {
    this.openSSE()
    if(fieldName === 'merchants_info') {
      this.sendMsgMCCSSE(newValue)
    } else {
      this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
    }

  }

  // Merchants
  addMCC():void{
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }

    let merch = this.fieldValues.merchants_info
    let pushObj = { ...this.fieldValues, 'merchants_info':[merch]}
    this.mccsList.push(pushObj)

    // Add data to fliter arrays
    this.mccsFilter.push(merch)
    let idx = this.mccs.indexOf(merch)
    this.codeMccsFilter.push(this.mccCodes[idx])

    this.getEmptyForms()
    this.validationErrors = {}
    this.fieldWasEdited = {}
  }
  deleteMCC(data:string):void {
      let  newMCCList = this.mccsList.filter((m:any) => m.merchants_info[0]!==data)
      this.mccsList = newMCCList

      let idx = this.mccsFilter.indexOf(data)
      let code = this.codeMccsFilter[idx]
      this.mccsFilter = this.mccsFilter.filter((mcc:string) => mcc!== data)
      this.codeMccsFilter = this.codeMccsFilter.filter((c:string) =>c!== code)
  }
  getEmptyForms():void {
    this.fieldsConfig = this.cardService.formFieldsMercLimit
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''
    this.fieldValues = {...this.fieldValues , ['type'] : 2}
    this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}
  }
  returnMCCData(){ // Executed by parent component
    return this.mccsList
  }

  returnMCCCodes(){ // Executed by parent component
    return this.codeList
  }
  // SSE
  setMCCValue(data:string){
    // Set field Value
    this.fieldValues = {...this.fieldValues, ['merchants_info']: data}
    this.setFieldToEdited('merchants_info')

    // Set field code
    let code = this.mccCodes[this.mccs.indexOf(data)]
    this.codeList.push(code)

    this.viewMCCList = false
    this.mccs = []
    this.closeSSE()
  }

  openSSE ():void{
    this.homeService.getWSToken().subscribe(
      (res) => { if (res.e_code == 1) {
        // Open websocket to get merchants
        this.startSSE(res.Token)
      }});
  }

  startSSE(data:string):void{
    const websocketUrl = environment.wsUrl + '/merchant_name/?Token='+data;
    this.socket = new WebSocket(websocketUrl);
    this.socket.onopen = () => {
      console.log("OPEN");
    };
  }

  sendMsgMCCSSE(data:string){
    let mccObj = { merchant_info: data, type:1}
    this.socket.send(JSON.stringify(mccObj))
    this.socket.onmessage = (event) => {
      const mmcListSSE = JSON.parse(event.data).results.map((res:any)=> res.description)
      const mmcCodeListSSE = JSON.parse(event.data).results.map((res:any)=> res.code)
      if(mmcListSSE.length>0 && data!==""){
        this.viewMCCList = true
        this.mccs = mmcListSSE.filter((m:string) => !this.mccsFilter.includes(m))
        this.mccCodes = mmcCodeListSSE.filter((m:string) => !this.codeMccsFilter.includes(m))

      } else {
        this.viewMCCList = false
        this.mccs = []
      }
    };}

  // Close websocket
  closeSSE() :void {
    this.socket.close(1000, "CLOSE SUCCESS")
   // console.log("CLOSE")
  }

  getMCCsFilter(data:any) {
    this.mccsFilter = data.map((lim:any)=> lim.merchants_info[0])
    this.codeMccsFilter = data.map((lim:any)=> lim.merchants[0].mcc_code)
  }

  ngOnInit() {
    this.getEmptyForms()
    this.openSSE()
    this.mccsList = []
    this.isMCCLimit.subscribe(response => {
      if(response === true){
        this.fieldValues = {...this.fieldValues , ['type'] : 2}
        this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}
      } else {
        this.validationErrors = {}
      }
    })
  }

}
