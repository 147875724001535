<div class="container-fluid">

  <!--User is enrolled to Kredio rewards-->
  <div *ngIf="isEnrolled" class="row" >
    <div class="col-6" >
      <p class="text-kredio-hint color-kredio mb-0"  >Paid Cashback</p>
      <div class="align-items-center my-2">
        <p id="paidCBPos"
           class="p-first-row my-1">${{benefitsAccount.paid_cashback|number :'.2'}}</p>
      </div>
     </div>
    <div class="col-6" >
      <p class="text-kredio-hint color-kredio mb-0" >Pending Cashback</p>
      <div class="align-items-center my-2">
        <p  id="pendindCBPos"
            class="text-muted p-first-row mb-0">${{benefitsAccount.pending_cashback|number :'.2'}}</p>
      </div>
    </div>




  </div><!--Close row-->


  <div *ngIf="!isEnrolled">

   <div class="row my-2" >
     <div class="col-1 offset-2  p-0 ">
       <img src="assets/images/home/star.svg" alt="Star" class="img-fluid"  >
     </div>
     <div class="col-9  p-0 d-flex align-items-center">
       <p class="text-kredio-hint  mb-0">
         Enroll to your Kredio benefits
       </p>
     </div>
   </div>

    <div class="row col-8 offset-2">
      <button id="btnActivateRewards"
        (click)="activateRewards()"
        class="yellow-button w-100 py-3 rounded-4 button-pointer fw-bold">Enroll</button>
    </div>

  </div><!--Close ngIf-->

</div>
