<div class="container-fluid">

  <!--Title Add new Card-->
  <div class="row py-2 px-1" >

        <div class="col-lg-1 col-2 p-0 d-flex justify-content-center" >
          <img class="img-fluid" src="assets/images/cards/cards_icon.svg">
        </div>

        <div class="col-lg-10 col-8 d-flex align-items-center">
          <p class="p-title mb-0 "
          >Add New Card</p>
        </div>

        <div class="col-lg-1 col-2 p-0 d-flex justify-content-center  button-pointer" >
          <img (click)="cancelAddCard()" class="img-fluid "
               src="assets/images/edit_profile/cancel_edit.svg"/>
        </div>

  </div>

  <div *ngIf="selectTypeOfCard" class="px-1">

    <div class="row d-sm-flex d-none mt-2">
      <p class="p-subtitle">Select the type new card</p>
    </div>

    <div class="row my-3" >
      <div class="col-12" >
        <select
          id = "selectedCardType"
          class="form-select py-3"
          [(ngModel)]="newCardType"
        >
          <option [ngValue]="undefined"  [disabled] = "true" [selected]="true" >New Card Type</option>
          <option *ngFor="let option of card_options" [value] = option[1]
          >{{option[0]}}</option>
        </select>
      </div>
    </div>

    <div class="row mt-5 mb-3" >
      <div class="col-12">
        <button class="border-0 violet-button p-3 rounded-4  w-100"
            id="btnSelect"    (click)="selectCardType()"> Select
        </button>
      </div>
    </div>

  </div>

  <div  *ngIf="!selectTypeOfCard" class="px-1">

    <!--Request  Virtual -->
    <div *ngIf="!this.newCardHolder">

      <div class="row d-sm-flex d-none mt-2">
        <p class="p-subtitle">Set up your new virtual card</p>
      </div>

      <div class="row" >
        <div class="col-12  my-3" >
          <select
            id = "selectedAccount"
            class="form-select py-3"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
            <option [ngValue]="undefined"  [disabled] = "true" [selected]="true" >Link to Account</option>
            <option *ngFor="let option of cardList" [value] = option.id
            >{{option.name.toString()}}</option>
          </select>

          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
            {{this.validationErrors[fieldsConfig[0].name]}}
          </p>
        </div>
      </div>

      <div class="row" >
        <div class="col-12 my-3 ">
          <div class="form-floating  ">
            <input
              id = "cardName"
              [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control '"
              [placeholder]="fieldsConfig[1].placeHolder"
              [type]="fieldsConfig[1].type"
              [ngModel]="fieldValues[fieldsConfig[1].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[1].name)"
            >
            <label class="form-label">{{fieldsConfig[1].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]" >
            {{this.validationErrors[fieldsConfig[1].name]}}</p>
        </div>
      </div> <!-- End Row-->

      <div class="row text-center  my-3" >
        <div class="col-12">
          <button id="btnCreate" class="border-0 violet-button p-3 rounded-4  w-100"
                  (click)="submitNewCardRequest('newVirtualCard')"> Create Virtual Card
          </button>
        </div>
      </div><!-- End Row-->

    </div> <!--End Request Card-->

    <!--Request New Card Holder-->
    <div *ngIf="this.newCardHolder">

      <div class="row d-sm-flex d-none mt-2">
        <p class="p-subtitle">Enter the information to add a new card holder.</p>
      </div>

      <!-- isSumbitDataPhyCard == false -->
      <div *ngIf="!isSumbitDataPhyCard">

        <div class="row my-3 " >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <div class="form-floating">
              <input
                [id] ="'inputName'"
                [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[0].placeHolder"
                [type]="fieldsConfig[0].type"
                [ngModel]="fieldValues[fieldsConfig[0].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[0].name)"
              >
              <label class="form-label">{{fieldsConfig[0].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
              {{this.validationErrors[fieldsConfig[0].name]}}
            </p>
          </div>
        </div> <!-- End Row-->

        <div class="row my-3" >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <div class="form-floating">
              <input
                [id] ="'inputSurName'"
                [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[1].placeHolder"
                [type]="fieldsConfig[1].type"
                [ngModel]="fieldValues[fieldsConfig[1].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[1].name)"
              >
              <label class="form-label">{{fieldsConfig[1].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
              {{this.validationErrors[fieldsConfig[1].name]}}
            </p>
          </div>
        </div> <!-- End Row-->

        <div class="row my-3" >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <div class="form-floating">
              <input
                [id]="'inputSNN'"
                [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[2].placeHolder"
                [type]="fieldsConfig[2].type"
                [ngModel]="fieldValues[fieldsConfig[2].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[2].name)"
              >
              <label class="form-label">{{fieldsConfig[2].displayName}}</label>
            </div>
            <p [id]="'errorSNN'" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
              {{this.validationErrors[fieldsConfig[2].name]}}
            </p>
          </div>
        </div> <!-- End Row-->

        <div class="row my-3" >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <div class="input-group ">
              <input
                id ="inputDOB"
                placeholder="Date of Birth"
                [class]="validationErrors[fieldsConfig[3].name]? 'form-control formError py-3': 'form-control py-3'"
                name="dp"
                [ngModel]="dateOfBirth"
                (ngModelChange)="updateFieldValue(fieldsConfig[3].name,$event)"
                (blur)="setFieldToEdited(fieldsConfig[3].name)"
                ngbDatepicker
                #dob="ngbDatepicker"
                      />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="dob.toggle()" type="button"></button>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
              {{this.validationErrors[fieldsConfig[3].name]}}
            </p>
          </div>
        </div> <!-- End Row-->

        <div class="row  my-4" >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <button id="btnNewHodler" class="border-0 violet-button p-3 rounded-4  w-100"
                     (click)="submitNewCardRequest('newHolder')">Add New Holder
            </button>
          </div>
        </div>

      </div> <!-- End Row-->

      <!-- isSumbitDataPhyCard ==  true-->
      <div *ngIf="isSumbitDataPhyCard">

        <div class="row my-3 " >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <div class="form-floating">
              <input
                [id] ="'inputNamePC'"
                [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[0].placeHolder"
                [type]="fieldsConfig[0].type"
                [ngModel]="fieldValues[fieldsConfig[0].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[0].name)"
              >
              <label class="form-label">{{fieldsConfig[0].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
              {{this.validationErrors[fieldsConfig[0].name]}}
            </p>
          </div>
        </div> <!-- End Row-->

        <div class="row my-4" >
          <div class="col-lg-10 offset-lg-1 col-12 offset-0">
            <button  class="border-0 violet-button p-3 rounded-4  w-100"  id="btnAddChCard"
                     (click)="addChildCard()"> Add New Holder
            </button>
          </div>
        </div>
      </div>

    </div> <!--End Request Card-->

  </div>

</div>



