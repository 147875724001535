import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {ActivatePasswordCode, AuthService, ChangePassword, ValidatePhoneNumber} from "../../../services/auth.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ChangePswMsgComponent} from "../change-psw-msg/change-psw-msg.component";

@Component({
  selector: 'app-change-psw',
  templateUrl: './change-psw.component.html',
  styleUrls: ['./change-psw.component.css']
})
export class ChangePswComponent {

  confirmPhoneNumber :boolean = true
  confirmCodeNumber :boolean = false
  changePassword :boolean = false
  phone_number !:string
  dataObject !:ActivatePasswordCode


  constructor(
    private router : Router,
    private authService : AuthService,
    public dialog : MatDialog
  ) { }


  submitNewPassword(data: ChangePassword):void {
    this.authService.changePassword(data).subscribe({ next:(res)=>{
        if(res.e_code ==1){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = { message :'Your password has been updated'}
          dialogConfig.panelClass = 'full-30-dialog';
          this.dialog.open(ChangePswMsgComponent,dialogConfig)
        }
      }})
  }

  submitCode(data:any):void{
    this.dataObject = {'code': parseInt(data.code), 'phone': this.phone_number}
    this.authService.activateCodeChangePassword(this.dataObject).subscribe({ next:(res)=>{
        if(res.e_code ==1){
          this.confirmCodeNumber = false
          this.changePassword  = true
        }
      }})
  }

  sendCodeAgain(): void{
    if(this.phone_number){
      this.authService.validatePhoneNumber({'phone':this.phone_number}).subscribe({ next:(res)=>{
        }})}
  }

  submitPhoneNumber(data:ValidatePhoneNumber):void{

    this.authService.validatePhoneNumber(data).subscribe({ next:(res)=>{
       if(res.e_code ==1){
         this.phone_number = data.phone
         this.confirmPhoneNumber  = false
         this.confirmCodeNumber = true
       }
      }})
  }

  onCodeCompleted(code: string) {
    this.submitCode(code)
  }

}
