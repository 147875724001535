<div class="container-fluid h-100">

  <div class="row">
    <div class="col-12" >
      <img (click)="changeViewBW(this.currentView)"
           id="btnArrow"
        src="assets/images/create_profile/arrow_back.svg" alt="Arrow">
    </div>
  </div>

    <!-- Enter LegalName-->
    <div *ngIf="enterLegalName" >
      <br>
      <br>
      <p class="p-title">Enter your legal name</p>
      <p class="p-small my-2">As stated on your official ID. We need to verify your identity.</p>

      <div class="my-5">
        <div class="form-floating">
          <input
            [id] ="'inputName'"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
          <label [class]="validationErrors[fieldsConfig[0].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" id='error_name' *ngIf="this.validationErrors[fieldsConfig[0].name]">
          {{this.validationErrors[fieldsConfig[0].name]}}
        </p>
        <br>
        <div class="form-floating">
          <input
            [id] ="'inputSurname'"
            [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[1].placeHolder"
            [type]="fieldsConfig[1].type"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
          <label [class]="validationErrors[fieldsConfig[1].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[1].displayName}}</label>
        </div>
        <p  class="error my-1" id='error_surname' *ngIf="this.validationErrors[fieldsConfig[1].name]">
          {{this.validationErrors[fieldsConfig[1].name]}}
        </p>
      </div>

      <div class="fixed-bottom my-5 px-2">
        <button id="btnContinueFullName"
          (click)="continueTestErrors('fullName')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>

    </div>

    <!-- Enter DOB-->
    <div *ngIf="enterDOB">
      <br>
      <br>
      <p class="p-title">Enter your date of birth</p>
      <p class="p-small my-2">You must be 18 years or older to open a Kredio account.</p>
      <div class="my-5">
        <div class="input-group ">
          <input
            id ="inputDOB"
            placeholder="Date of Birth"
            [class]="validationErrors[fieldsConfig[2].name]? 'form-control formError py-3': 'form-control py-3'"
            name="dp"
            [ngModel]="dateOfBirth"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name,$event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
            ngbDatepicker
            #dob="ngbDatepicker"
            [maxDate] = maxDob
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="dob.toggle()" type="button"></button>
        </div>
        <p *ngIf="this.validationErrors[fieldsConfig[2].name]"  class="error my-1" id="error_dob" >
          {{this.validationErrors[fieldsConfig[2].name]}}
        </p>
      </div>
      <div class="fixed-bottom my-5 px-2">
        <button
          (click)="continueTestErrors('dob')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>

    </div>

    <!-- Enter Address-->
    <div *ngIf="enterAddress">
      <br>
      <br>
      <p class="p-title">Enter your address information</p>
      <p class="p-small my-2">We need yo verify your home address where we will deliver the account documentation.</p>
      <div class="my-5">
        <div class="form-floating">
          <input
            id ='inputAddress'
            [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[3].placeHolder"
            [type]="fieldsConfig[3].type"
            [ngModel]="fieldValues[fieldsConfig[3].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[3].name)"
          >
          <label [class]="validationErrors[fieldsConfig[3].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[3].displayName}}</label>
        </div>
        <p  class="error my-1" id="err_address" *ngIf="this.validationErrors[fieldsConfig[3].name]">
          {{this.validationErrors[fieldsConfig[3].name]}}
        </p>

        <br>
        <div class="form-floating">
          <input
            id ='inputApt'
            [class] ="validationErrors[fieldsConfig[4].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[4].placeHolder"
            [type]="fieldsConfig[4].type"
            [ngModel]="fieldValues[fieldsConfig[4].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[4].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[4].name)"
          >
          <label [class]="validationErrors[fieldsConfig[4].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[4].displayName}}</label>
        </div>
        <p  class="error my-1"  id="err_apt" *ngIf="this.validationErrors[fieldsConfig[4].name]">
          {{this.validationErrors[fieldsConfig[4].name]}}
        </p>

        <br>
        <div class="form-floating">
          <input
            id ='inputZipCode'
            [class] ="validationErrors[fieldsConfig[5].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[5].placeHolder"
            [type]="fieldsConfig[5].type"
            [ngModel]="fieldValues[fieldsConfig[5].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[5].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[5].name)"
          >
          <label [class]="validationErrors[fieldsConfig[5].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[5].displayName}}</label>
        </div>
        <p  class="error my-1" id="err_zip" *ngIf="this.validationErrors[fieldsConfig[5].name]">
          {{this.validationErrors[fieldsConfig[5].name]}}
        </p>

        <br>
        <div class="form-floating">
          <input
            id ='inputCity'
            [class] ="validationErrors[fieldsConfig[6].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[6].placeHolder"
            [type]="fieldsConfig[6].type"
            [ngModel]="fieldValues[fieldsConfig[6].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[6].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[6].name)"
          >
          <label [class]="validationErrors[fieldsConfig[6].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[6].displayName}}</label>
        </div>
        <p  class="error my-1" id="err_city" *ngIf="this.validationErrors[fieldsConfig[6].name]">
          {{this.validationErrors[fieldsConfig[6].name]}}
        </p>
        <br>


        <!-- State -->
        <div>
          <select
            id ='inputState'
            [class]="validationErrors[fieldsConfig[7].name]? 'form-select py-3 formError': 'form-select border-0 py-3'"
            [ngModel]="fieldValues[fieldsConfig[7].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[7].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[7].name)"
          >
            <option value="" [disabled] = true [selected]="true" > State</option>
            <option
              *ngFor="let option of fieldsConfig[7].options"
              [value] = option
            >{{option}}</option>
          </select>
        </div>
        <p  class="error my-1" id="err_state" *ngIf="this.validationErrors[fieldsConfig[7].name]">
          {{this.validationErrors[fieldsConfig[7].name]}}
        </p>
      </div>
      <div class=" my-5 px-2">
        <button
          (click)="continueTestErrors('address')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>

    </div>

    <!-- Enter ID DOCS DATA-->
    <div *ngIf="enterIDDocsData">
      <br>
      <br>
      <p class="p-title">About your Identity</p>
      <p class="p-small my-2">Please confirm tell us about your ID to open your Kredio account.</p>

      <div class="my-2">
        <br>
        <!--Id type-->
        <div >
          <select
            id ='inputId'
            [class]="validationErrors[fieldsConfig[8].name]? 'form-control formError py-3': 'form-control py-3'"
            [ngModel]="fieldValues[fieldsConfig[8].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[8].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[8].name)"
          >
            <option  value="" [disabled] = true [selected]="true" >Id Type</option>
            <option
              *ngFor="let option of this.idTypes"
              [value] = option[1]
            >{{option[0]}}</option>
          </select>
        </div>
        <p id="error-idtype" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[8].name]">
          {{this.validationErrors[fieldsConfig[8].name]}}
        </p>
        <br>
        <br>

        <!-- Id Number-->
        <div class="form-floating">
          <input
            id ='inputIdNumber'
            [class] ="validationErrors[fieldsConfig[9].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[9].placeHolder"
            [type]="fieldsConfig[9].type"
            [ngModel]="fieldValues[fieldsConfig[9].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[9].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[9].name)"
          >
          <label [class]="validationErrors[fieldsConfig[9].name]? 'form-label labelError': 'form-label'">
            {{fieldsConfig[9].displayName}}</label>
        </div>
        <p id="error-num"   class="error my-1" *ngIf="this.validationErrors[fieldsConfig[9].name]">
          {{this.validationErrors[fieldsConfig[9].name]}}
        </p>
        <br>
        <br>

        <p class="p-sub-title"> Issue Date</p>
        <div class="input-group ">
          <input
            id ='inputIssueDate'
            [class]="validationErrors[fieldsConfig[10].name]? 'form-control formError py-3': 'form-control py-3'"
            placeholder="Issue Date"
            [ngModel]="dateOfIssue"
            (ngModelChange)="updateFieldValue(fieldsConfig[10].name,$event)"
            (blur)="setFieldToEdited(fieldsConfig[10].name)"
            ngbDatepicker
            #doi="ngbDatepicker"
            [maxDate] = maxAllowedDateOfIssue
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="doi.toggle()" type="button"></button>
        </div>
        <p  id="error-issueDate"  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[10].name]">
          {{this.validationErrors[fieldsConfig[10].name]}}
        </p>
        <br>
        <br>

        <!-- Expiration date -->

        <p class="p-sub-title">Expiration Date</p>
        <div class="input-group ">
          <input
            id ='inputExpDate'
            [class]="validationErrors[fieldsConfig[11].name]? 'form-control formError py-3': 'form-control py-3'"
            placeholder="Expiration Date"
            [ngModel]="dateExpiration"
            (ngModelChange)="updateFieldValue(fieldsConfig[11].name,$event)"
            (blur)="setFieldToEdited(fieldsConfig[11].name)"
            ngbDatepicker
            #doe="ngbDatepicker"
            [minDate]="minAllowedDateOfExp"
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="doe.toggle()" type="button"></button>
        </div>
        <p id="error-expDate"  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[11].name]">
          {{this.validationErrors[fieldsConfig[11].name]}}
        </p>
        <br>
        <br>


        <p class="p-sub-title">Verification Date</p>
        <div class="input-group ">
          <input
            id ='inputVerDate'
            class="form-control py-3"
            placeholder="Verification Date"
            name="dp"
            [ngModel]="dateVerif"
            (ngModelChange)="updateFieldValue(fieldsConfig[12].name,$event)"
            (blur)="setFieldToEdited(fieldsConfig[12].name)"
            ngbDatepicker
            #dov="ngbDatepicker"
            [maxDate] = maxAllowedDateOfVer
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="dov.toggle()" type="button"></button>
        </div>
        <p id="error-verDate" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[12].name]">
          {{this.validationErrors[fieldsConfig[12].name]}}
        </p>
        <br>
        <br>

        <!--Issuing State-->
        <div>
          <select
            id ='inputIdState'
            [class]="validationErrors[fieldsConfig[13].name]? 'form-select py-3 formError': 'form-select border-0 py-3'"
            [ngModel]="fieldValues[fieldsConfig[13].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[13].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[13].name)"
          >
            <option value="" [disabled] = true [selected]="true" > State</option>
            <option
              *ngFor="let option of fieldsConfig[13].options"
              [value] = option
            >{{option}}</option>
          </select>
        </div>
        <p  id="error-issState" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[13].name]">
          {{this.validationErrors[fieldsConfig[13].name]}}
        </p>
        <br>

      </div>

      <div class="my-5 px-2">
        <button
          (click)="continueTestErrors('idDocs')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>

    </div>

    <!-- Enter SSN ITIN-->
    <div *ngIf="enterSSNITIN">
      <br>
      <br>
      <p class="p-title">What's your SSN/ITIN number</p>
      <p class="p-small">The federal regulation requires that we verify your SSN or ITIN number.
        This will not affect your credit score.</p>
      <div class="my-2">
        <div class="form-floating">
          <input
            id="inputSNN"
            [class] ="validationErrors[fieldsConfig[14].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[14].placeHolder"
            [type]="fieldsConfig[14].type"
            [ngModel]="fieldValues[fieldsConfig[14].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[14].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[14].name)"
          >
          <label class="form-label">{{fieldsConfig[14].displayName}}</label>
        </div>
        <p id="errorSNN" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[14].name]">
          {{this.validationErrors[fieldsConfig[14].name]}}
        </p>
      </div>
      <div class="fixed-bottom my-5 px-2">
        <button
          (click)="continueTestErrors('ssnItin')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>
    </div>

    <!-- Enter Account Activity-->
    <div *ngIf="enterAccountActivity">
      <br>
      <br>
      <p class="p-title">Account Activity Monitor</p>
      <p class="p-small">Please, submit the following information regarding the expected account activity.</p>

      <div class="my-2">

        <!--Monthly Transactions-->
        <p class="p-sub-title">Expected Number of Monthly Transactions</p>
        <div>
          <select
            id ='inputTrNumber'
            [class]="validationErrors[fieldsConfig[15].name]? 'form-select py-3 formError': 'form-select border-0 py-3'"
            [ngModel]="fieldValues[fieldsConfig[15].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[15].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[15].name)"
          >
            <option value="" [disabled] = true [selected]="true">Select Number</option>
            <option
              *ngFor="let option of fieldsConfig[15].options"
              [value] = option[1]
            >{{option[0]}}</option>
          </select>
        </div>
        <p  class="error my-1" id="errorTrAmount" *ngIf="this.validationErrors[fieldsConfig[15].name]">
          {{this.validationErrors[fieldsConfig[15].name]}}
        </p>
        <br>
        <br>

        <!--Transactions Size-->
        <p class="p-sub-title">Expected Transactions Size</p>
        <div>
          <select
            id ='inputTrSize'
            [class]="validationErrors[fieldsConfig[16].name]? 'form-select py-3 formError': 'form-select border-0 py-3'"
            [ngModel]="fieldValues[fieldsConfig[16].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[16].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[16].name)"
          >
            <option value="" [disabled] = true [selected]="true" >Select Size</option>
            <option
              *ngFor="let option of fieldsConfig[16].options"
              [value] = option[1]
            >{{option[0]}}</option>
          </select>
        </div>
        <p  class="error my-1" id="errorTrNum" *ngIf="this.validationErrors[fieldsConfig[16].name]">
          {{this.validationErrors[fieldsConfig[16].name]}}
        </p>
        <br>
        <br>


        <!--Cross Border Activity -->
        <p class="p-sub-title">Expected Cross Border Activity</p>
        <div>
          <select
            id ='inputTrCrossBorder'
            [class]="validationErrors[fieldsConfig[17].name]? 'form-select py-3 formError': 'form-select border-0 py-3'"
            [ngModel]="fieldValues[fieldsConfig[17].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[17].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[17].name)"
          >
            <option value="" [disabled] = true [selected]="true" >Select Number</option>
            <option
              *ngFor="let option of fieldsConfig[17].options"
              [value] = option[1]
            >{{option[0]}}</option>
          </select>
        </div>
        <p  class="error my-1" id="errorCBA" *ngIf="this.validationErrors[fieldsConfig[17].name]">
          {{this.validationErrors[fieldsConfig[17].name]}}
        </p>
        <br>
        <br>
      </div>

      <div class="fixed-bottom my-5 px-2">
        <button
          (click)="continueTestErrors('accountActivity')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Continue</button>
      </div>
    </div>

    <!-- Confirm Profile Data-->
    <div *ngIf="confirmProfileData">
      <p class="p-title">Please confirm your information</p>
      <p class="p-small">Review the information below.. Once the data is confirmed,
          you will not be able to change your date of bith, not your SSN/ITIN.</p>

      <div  class="my-2">
        <div class="row">
          <div class="col-9  ">
            <p class="p-summary mb-0" >Personal Information Summary</p>
          </div>
          <div class="col-3 button-pointer  text-center" id="btn_edit"
               (click)="changeViewBW('confirmData')">
            <p class="p-edit mb-0 ">Edit</p></div>
        </div>
        <div class="row"  *ngIf="!isEdit" style="margin-top:2%">
          <div *ngFor="let field of fieldsConfig" style="background-color: white">
            <div *ngIf="field.name !='email'"  class="form-floating my-2">
              <input
                id = {{field.name}}
                [class] ="'form-control'"
                [(ngModel)]="fieldValues[field.name]"
              >
              <label class="form-label">{{field.displayName}}</label>
            </div>
          </div>
        </div> <!-- Close ngif -->
      </div>

      <div class=" my-5 px-2">
        <button id="btnConfirm"
          (click)="confirmInformation('confirmData')"
          class="violet-button text-center rounded-4 py-3 px-4 button-border w-100  ">Confirm</button>
      </div>

    </div>

  <div *ngIf="spinningOn" >
    <app-spinn-wheel
      [message]="weMessage"
    ></app-spinn-wheel>
  </div>

</div>
