<div class="container-fluid  ">

  <!--  Display transactions found -->
  <div >

      <!--Select account to be displayed --->

      <div class = "row ">
          <div class="col-6">
            <div class="form-floating " >
              <select class="form-select div-form"
                      id="selectedAccount"
                      [ngModel]="fieldValues[formFields[0].name]"
                      (ngModelChange)="updateFieldValue(formFields[0].name, $event)"
              >
                <option id="selectedOption"
                  *ngFor="let option of formFields[0].options ; index as i "
                  [value] = account_ids[i]
                >{{option}}</option>
              </select>
              <label class="form-label">{{[formFields[0].displayName]}}</label>
            </div> <!--Close col -->
          </div>

          <div class="col-6">
            <div class="form-floating " >
              <input
                id="filterFiled"
                class="form-control div-form"
                type="string"
                style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
                [ngModel]="search_word"
                (ngModelChange)="filterTransaction($event)"
              >
              <label class="form-label">Search</label>
            </div>
          </div> <!--Close col -->

        </div> <!--Close row -->

      <!--Accounts Transactions Display large screen --->

      <div *ngIf="displayTransactions" class="scroll-transactions d-sm-block d-none">
        <table class="table ">
          <thead>
          <tr >
            <th>Transactions</th>
            <th>Description</th>
            <th>Status</th>
            <th class="text-center" >Amount</th>
            <th class="text-center">Balance</th>
          </tr>
          </thead>
          <tbody *ngFor="let tr of transactions_display">
          <tr>
            <td class="td-when">{{tr.dt_when}}</td>
            <td class="td-desc">{{tr.desc}}</td>
            <td class="td-desc">{{tr.status}}</td>
            <td class="td-amount "  (click)="disputeThisTransaction(tr)">
              <button  class="span-btn rounded-4 "
                       data-toggle="tooltip"
                       title="Dispute Transaction"
              > ${{tr.amount|number :'.2'}}
              </button>
            </td>
            <td class="td-balance text-center">$ {{tr.account_balance}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="displayTransactions" class="align-items-center d-sm-block d-none border-top-solid pt-2">
        <button (click)="seeAllActivity()">{{buttonName}}</button>
      </div>


      <!--Accounts Transactions Display Resposive --->

      <div *ngIf="displayTransactions"  class="d-sm-none d-block" >
        <button id="seeAllBtn" (click)="seeAllActivity()" class="my-3">{{buttonName}}</button>
      </div>

      <div *ngIf="displayTransactions"  class="scroll-transactions-small d-sm-none d-block border border-light ">
        <div class="row responsive-tran " *ngFor="let tr of transactions_display">

              <div class="col-7">
                  <p class="p-desc-r mb-0">{{tr.desc}}</p>
                  <p class="p-status mb-0">{{tr.status}}</p>
              </div>
              <div class="col-5">
                <p   (click)="disputeThisTransaction(tr)">
                  <button  class="td-amount border-0"
                           data-toggle="tooltip"
                           title="Dispute Transaction"
                  > ${{tr.amount|number :'.2'}}
                  </button>
                </p>
                <p class="text-center p-desc border-0 text-muted">{{tr.account_balance}}</p>
              </div>

            </div>
      </div>

  </div>

<!--  No transactions to display-->
  <div *ngIf="!displayTransactions">
    <br>
    <div class="d-flex justify-content-center text-center">
      <img src="assets/images/transactions/dollar.svg" class="img-fluid">
    </div>

    <p class="p-b-nt mb-0">No transactions yet</p>
    <p class="p-b-nt mb-0">Start using your card and you will see the transactions displayed here.</p>

  </div>

</div>

