import {CreditScore} from "../../../services/creditsc.service"
export const myCreditScore : CreditScore = {
  userId: 'user1',
  rating: 820,
  ratingText: 'Excellent',
  date_updated: '12/02/2022',
  score_histo: [
    { date:'12/02/2022',
      score:750},
    { date:'11/02/2022',
      score:700},
    { date:'10/02/2022',
      score:650},
    { date:'09/02/2022',
      score:620},
    { date:'08/02/2022',
      score:600},
  ],
  isEnrolled: true,
  checkProcess: true
}
