<div class="container-fluid">

  <div class="row">

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-around " >
      <img class="img-fluid m-0 d-block"

           src="assets/images/recipients/card_img.svg"
      />
    </div >

    <div class="col-lg-10 col-8  d-flex align-items-center"  >
      <p class="p-title  my-md-3 m-0 ">Edit Recipient Information</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-around" >
      <img (click)="cancelEditRecipient()" class="img-fluid button-pointer"
           src="assets/images/edit_profile/cancel_edit.svg"
      />
    </div>

  </div>

  <div *ngIf="this.edit_rec == true" >

    <div class="row">

      <p class=" div-gi mt-2">Current Recipient Information</p>

    </div>

    <div class="row my-2" >

      <div class="col-lg-2 offset-lg-5 col-4 offset-4" >
        <div class="text-center ">
          <img src="assets/images/recipients/new_picture.svg" class="img-fluid w-100">
        </div>
      </div>
    </div>

    <div class="row my-2 ">
      <div class="col-lg-4 offset-lg-4 col-12 text-center" >
        <div >
          <input
            id="inputFile"
            class = "file-input"
            type="file"
            (change)="handleFileInput($event)"
          >
        </div>
      </div>
    </div>

    <div class="row my-4 mx-md-4" >

      <div class="col-sm-6 col-12 my-2 " >
        <div class="form-floating" >
          <input
            id="inputName"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
          <label class="form-label  ">{{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" id="errName" *ngIf="this.validationErrors[fieldsConfig[0].name]">
          {{this.validationErrors[fieldsConfig[0].name]}}
        </p>
      </div>

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="inputSurName"
            [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[1].placeHolder"
            [type]="fieldsConfig[1].type"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
          <label class="form-label ">{{fieldsConfig[1].displayName}}</label>
        </div>
        <p  class="error my-1" id="errSurName" *ngIf="this.validationErrors[fieldsConfig[1].name]">
          {{this.validationErrors[fieldsConfig[1].name]}}
        </p>
      </div>

    </div>

    <div class="row my-4 mx-md-4 ">

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="inputEmail"
            [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[2].placeHolder"
            [type]="fieldsConfig[2].type"
            [ngModel]="fieldValues[fieldsConfig[2].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
          >
          <label class="form-label ">{{fieldsConfig[2].displayName}}</label>
        </div>
        <p  class="error my-1" id="errEmail" *ngIf="this.validationErrors[fieldsConfig[2].name]">
          {{this.validationErrors[fieldsConfig[2].name]}}
        </p>
      </div>

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="inputPhone"
            [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[3].placeHolder"
            [type]="fieldsConfig[3].type"
            [ngModel]="fieldValues[fieldsConfig[3].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[3].name)"
          >
          <label class="form-label ">{{fieldsConfig[3].displayName}}</label>
        </div>
        <p  class="error my-1" id="errPhone" *ngIf="this.validationErrors[fieldsConfig[3].name]">
          {{this.validationErrors[fieldsConfig[3].name]}}
        </p>
      </div>

    </div>


    <div class="row" >

      <div class="col-md-6 d-flex justify-content-center" >

        <button class="yellow-button w-100 rounded-3 px-2 py-3 my-3  " id="btnCancel"
                (click)="cancelEditRecipient()"> Cancel Edit</button>

      </div>

      <div class="col-md-6 d-flex justify-content-center" >

        <button class="violet-button w-100 rounded-3 px-2 py-3 my-3" id="btnSave"
                (click)="submitEditRecipient()"> Save</button>

      </div>

    </div>

  </div>

  <div *ngIf="this.edit_rec == false">

      <div class="row text-center">
        <div class="my-3">
          <img src="assets/images/recipients/success_recipient.svg"
               class="img-fluid">
        </div>

        <div class="my-3">
          <p class="p-message">{{edit_message}}</p>
        </div>

      </div>

  </div>

</div> <!--Close container-->
