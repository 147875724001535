import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile-confirm-change',
  templateUrl: './profile-confirm-change.component.html',
  styleUrls: ['./profile-confirm-change.component.css']
})
export class ProfileConfirmChangeComponent {

  @Output() confirmChanges = new  EventEmitter<boolean>()

  constructor(
    private router:Router
  ) {
  }

  updateInformation (): void{
    this.confirmChanges.emit(true)
  }

  cancelEdit():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }

}
