import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

export interface VerifyEmail {
  email_id: string
}

export interface VerifyToken {
  email: string,
  token: string
}
@Injectable({
  providedIn: 'root'
})

export class AgreeemailService {

  constructor(
    private api : ApiService
  ) { }

  sendVerifyEmailId(data:VerifyEmail): Observable<any> {
    return this.api.post('/send_verification_email/',data)
  }

  verifyToken(data:VerifyToken): Observable<any> {
    return this.api.post('/verify_email/',data)
  }

}
