export const environment = {
  production: false,
  apiUrl:"https://staging.kredio.com/pub",
  apiArgyleUrl: "https://api-sandbox.argyle.com/v2",
  ARGYLE_ID: "b9e1f337-aa11-4ab5-b8ed-90ab5dd7c1a5",
  ARGYLE_LINKKEY: "0180193b-43b1-4d58-2773-4b5493214880",
  wsUrl: "wss://staging.kredio.com/ws"
  //  apiUrl: "https://staging.kredio.com/pub",
  // wsUrl: "wss://staging.kredio.com/ws",

};
