<div class="container-fluid p-0 h-100">

  <!--   Display Transaction List-->
    <div *ngIf="this.displayTransactions">

      <div class ="row" *ngIf="!isHome"  >
          <div class="col-sm-7 col-12 py-lg-2 py-3">
            <div class="form-floating ">
              <input  id="searchWord" class ='form-control inputStyle'
                     [placeholder]="'Search'"
                     [ngModel]="search_word"
                     (ngModelChange)="filterTransaction('search_word', $event)"
                     style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
              >
              <label class="form-label">Search</label>
            </div>  <!--Close form -->
          </div> <!--Close col -->

        </div>

      <!--Table displayed on large screens -->

      <div class="d-none d-sm-inline">
        <div [ngClass]=" transactions_display.length > 4 ? 'scroll-transactions' : '' ">
          <table id="tableTransactions" class="table">
            <thead>
            <tr>
              <th>Transactions</th>
              <th>Description</th>
              <th>Status</th>
              <th class="text-center">Amount</th>
              <th class="text-center">Balance</th>
            </tr>
            </thead>
            <tbody  *ngFor="let tr of transactions_display">
            <tr id="transactions">
              <td class="td-when">{{tr.dt_when}}</td>
              <td class="td-desc">{{tr.desc}}</td>
              <td class="td-desc">{{tr.status}}</td>
              <td class="td-amount text-center"  (click)="disputeThisTransaction(tr)" >
                <button  class="span-btn rounded-4 text-center "
                         data-toggle="tooltip"
                         title="Dispute Transaction"
                > ${{tr.amount|number :'.2'}}
                </button>
              </td>
              <td id="balance" class="text-center td-balance">${{tr.account_balance|number :'.2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--Table displayed on small screens -->

      <div class=" d-sm-none d-inline">

        <div class ="row" *ngIf="isHome"  >
          <div class="col-sm-7 col-12 py-lg-2 py-3">
            <div class="form-floating ">
              <input   class ='form-control inputStyle'
                      [placeholder]="'Search'"
                      [ngModel]="search_word"
                      (ngModelChange)="filterTransaction('search_word', $event)"
                      style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
              >
              <label class="form-label">Search</label>
            </div>  <!--Close form -->
          </div> <!--Close col -->
        </div>

        <div [ngClass]=" transactions_display.length >=3  ? 'scroll-transactions'  : '' " >
          <div class="row responsive-tran " *ngFor="let tr of transactions_display">
            <div class="col-3 d-flex align-items-center">
              <div>
                <p class="p-date-sm">{{tr.dt_when}}</p>
              </div>
            </div>
            <div class="col-5 d-flex align-items-center">
              <div>
                <p class="p-desc">{{tr.desc}}</p>
                <p class="p-status">{{tr.status}}</p>
              </div>
            </div>
            <div class="col-4">
              <p class=""  (click)="disputeThisTransaction(tr)">
                <button  class="p-amount border-0"
                         data-toggle="tooltip"
                         title="Dispute Transaction"
                > ${{tr.amount|number :'.2'}}
                </button>
              </p>
              <p class="text-center p-desc border-0 text-muted">{{tr.account_balance}}</p>
            </div>
          </div>
        </div>

      </div>

      <div class="border-top-solid d-sm-none d-md-none d-lg-block">
        <button id="buttonSeeAll1"  class=" button-toggle mb-0 pt-2" (click)="seeAllActivity()">{{buttonName}}</button>
      </div>

      <div class="d-none d-sm-block d-md-block d-lg-none ">
        <button id="buttonSeeAll" class=" button-toggle mb-0 pt-2" (click)="seeAllActivity()">{{buttonName}}</button>
      </div>

    </div>

  <!--   Display No Transaction found message-->
    <div *ngIf="!this.displayTransactions" class="scroll-transactions" >
      <div class="  h-100 d-flex justify-content-center text-center align-items-center">
        <div>
          <div class="d-flex justify-content-center text-center mb-3">
            <img src="assets/images/transactions/dollar.svg">
          </div>
          <p id="emptyMsg1" class="p-b-nt" >No transactions yet</p>
          <p id="emptyMsg2" class="p-b-nt">Start using your card and you will see the transactions displayed here.</p>
        </div>
      </div>
    </div>


</div>




