import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";


export interface RecurrentItem {
  r_payment_id: string,
  account_id: string,
  number: number,
  routing: string,
  memo: string,
  network: string,
  amount: string,
  recipient: string,
  frequency: string,
  end_date: string,
  is_active: true

}

export interface NextPayment {
  id: string,
  due_date: string,
  status: string
}


export interface RecurrentPymnt {
  id: string,
  memo: string,
  network: string,
  amount: string,
  recipient: string,
  frequency: string,
  next_payments : NextPayment[] | []
}
export interface RecPayment{
  lead_id: string,
  r_payment_id: string,
  loan_id: string,
  account_number: number,
  routing: string,
  memo: string,
  network: string,
  amount: string,
  recipient: string,
  due_date: string,
  status: string
}

export interface DeleteRecPayment {
  r_payment_id:string
}
export interface CancelPayment {
  loan_id:string,
  r_payment_id :string,
  lead_id:string
}
@Injectable({
  providedIn: 'root'
})
export class RecpaymentsService {

  constructor(
    private api : ApiService
  ) { }

  getRecPayments():Observable<any>{
    return this.api.get('/recurring_payments/')
  }

  getNextPayments():Observable<any>{
    return this.api.get('/next_payments/')
  }

  getRecurrentItems():Observable<any>{
    return this.api.get('/recurring_payments_no_next/')
  }
  deleteRecPayments(data: DeleteRecPayment):Observable<any>{
    return this.api.post('/delete_r_payment/', data)
  }

  cancelPayment(data:CancelPayment):Observable<any> {
    return this.api.post('/cancel_payment/', data)
  }
}
