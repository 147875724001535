<div class="container-fluid">

  <div class="row text-center p-wording my-3" >
    <p> Your card will have the following limits</p>
  </div>

  <div class="row mx-2 my-2">

    <!-- General Limit-->
    <div class="col-lg-4 col-12 ">
      <p class=" mb-1 p-title title-color text-center">General Limit</p>
      <div *ngIf="limitsSummaryGL" class="mx-1">
        <div class="row border-title rounded-2 py-2"  >
          <div class="col-4 p-wording-m title-color  p-0 text-center">
            <p class="m-0  title-color ">General Limit</p>
          </div>
          <div class="col-4 p-wording-m grey-color p-0 text-center">
            <p id="limitGLAmount" class="m-0 ">${{limitsSummaryGL.amount}}</p>
          </div>
          <div class="col-4 p-wording-sm grey-color p-0 text-center ">
            <p id="limitGLPeriod" class="m-0 ">{{limitsSummaryGL.period}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="!limitsSummaryGL" class="text-center mt-1 p-wording-m">
        <p class="m-0 title-color">No limits to display</p>
      </div>
    </div>

    <!-- Merchant Limit-->
    <div class="col-lg-4 col-12">
      <p class=" mb-1 p-title title-color text-center">Merchant Limit</p>
      <div *ngIf="limitsSummaryMerch" class="mx-1">
        <div *ngFor="let lm of limitsSummaryMerch">
          <div class="row border-title rounded-2 py-2"  >
            <div class="col-4 p-wording-m title-color  p-0 text-center">
              <p id="limitMInfo" class="m-0  title-color ">{{lm.merchants_info[0]}}</p>
            </div>
            <div class="col-4 p-wording-m grey-color p-0 text-center">
              <p id="limitMAmount" class="m-0 ">${{lm.amount}}</p>
            </div>
            <div class="col-4 p-wording-sm grey-color p-0 text-center ">
              <p id="limitMPeriod" class="m-0 ">{{lm.period}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!limitsSummaryMerch" class="text-center mt-1 p-wording-m">
        <p class="m-0 title-color">No limits to display</p>
      </div>
    </div>

    <!-- MCC Limit-->
    <div class="col-lg-4 col-12 ">
      <p class=" mb-1 p-title title-color text-center">Expense Categories Limits</p>
      <div *ngIf="limitsSummaryMCC" class="mx-1">
        <div *ngFor="let lmcc of limitsSummaryMCC">
          <div class="row border-title rounded-2 py-2"  >
            <div class="col-4 p-wording-m title-color  p-0 text-center">
              <p id="limitMCCInfo" class="m-0  title-color ">{{lmcc.merchants_info[0]}}</p>
            </div>
            <div class="col-4 p-wording-m grey-color p-0 text-center">
              <p id="limitMCCAmount" class="m-0 ">${{lmcc.amount}}</p>
            </div>
            <div class="col-4 p-wording-sm grey-color p-0 text-center ">
              <p id="limitMCCPeriod"  class="m-0 ">{{lmcc.period}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!limitsSummaryMCC" class="text-center  mt-1 p-wording-m">
        <p class="title-color  mb-0 ">No limits to display</p>
      </div>
    </div>

  </div>

  <div class="row p-wording my-3 px-1">
    <p class="fw-bold">Important to remember:</p>
    <p > Any transaction related to any merchant or expense category of your choice will affect the general limit, if set.
      This means that a single transaction can increase the consumption of multiple limits.
    </p>
    <p>
      If there is no general limit, but any other set then, the limits will be applied only to the transactions related to
      the selected merchants of categories. Any other transaction will be processed normally if the card contains enough funds.
    </p>
  </div>

  <div class="row d-flex justify-content-around my-3 px-1">
    <div class="col-md-6 col-12 my-2 " >
      <button class="yellow-button  rounded-3 w-100 py-3"
              (click)="editSpendingLimits(true)" >Edit Limits</button>
    </div>
    <div class="col-md-6 col-12 my-2" >
      <button class="violet-button rounded-3 w-100 py-3"
              (click)="cancelEditLimits()">Cancel</button>
    </div>
  </div>

  <div *ngIf="isEditLimit">
    <app-modal-card-limits>
      <app-card-limit-edit
      [loan_id]="this.loan_id"
      [card_id]="this.card_id"
      [limitsListGL]  = "this.limitsSummaryGL"
      [limitsListMCC]="this.limitsSummaryMCC"
      [limitsListMerch]="this.limitsSummaryMerch"
      [limitsListMCCCodes]="limitsSummaryMCCCodes"
      ></app-card-limit-edit>
    </app-modal-card-limits>
  </div>

</div>
