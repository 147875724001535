<div class="container-fluid ">

  <!--Display MCC Limits -->
  <div class="row my-2 " *ngIf="mccsList.length>0" >
    <div class="col-lg-10 offset-lg-1 col-12  my-2 ">

      <div class="row text-center my-3 ">
        <p  class="p-merchant-name ">Remove Category Limits</p>
      </div>
      <div class="row p-merchant-border rounded-3">
        <div *ngFor="let merch of mccsList">
          <div class="row py-2 p-merchant-border ">
            <div class="col-lg-1 col-2 "  >
              <img id="imgDelete" src="assets/images/cards/merchant_icon.svg"
                   (click)="deleteMCCLimitAlert(merch.merchants_info[0])"
                   class="img-fluid m-0 ">
            </div>
            <div class="col-lg-7 col-6 d-flex  align-content-center button-pointer p-0">
              <p id="merchInfo" class="p-merchant-name mb-0 ">{{merch.merchants_info[0]}}</p>
            </div>
            <div class="col-2 d-flex justify-content-center align-content-center p-0">
              <p id="merchAmount" class="p-merchant-amount mb-0" >${{merch.amount}}</p>
            </div>
            <div class="col-2 d-flex justify-content-center align-content-center p-0">
              <p id="merchPeriod"  class="p-merchant-period  mb-0  ">{{merch.period}}</p>
            </div>
          </div>
        </div>
      </div>
    </div><!--Close col -->
  </div> <!--Close row -->

  <!-- Confirm Delete Message  -->
  <div *ngIf="isDeleteMCCLimit">
    <app-modal-new-acc>
      <app-card-limit-msg
        [title]="'Edit MCC Limit: ' + nameDeleteMCC"
        [message]="'The current general limit will be removed.'"
        (confirmEditLimit)= delete($event)
      ></app-card-limit-msg>
    </app-modal-new-acc>
  </div>

</div>
