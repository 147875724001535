import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-card-limits',
  templateUrl: './modal-card-limits.component.html',
  styleUrls: ['./modal-card-limits.component.css']
})
export class ModalCardLimitsComponent {

}
