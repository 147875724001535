<div class="container-fluid  " >

  <div class="row py-2 px-1" >

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-center " >
      <img class="img-fluid h-100" src="assets/images/pay_tf/green_dot_logo.svg">
    </div>

    <div class="col-lg-10 col-8 d-flex align-items-center">
      <p class="transfer-text mb-0 ">Transfer Cash</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-center  cancel-edit" >
      <img (click)="cancelActivate()" class="img-fluid button-pointer"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class = "row mx-2 my-4" >

    <!-- Search bar: responsive -->
    <div class="col-lg-10 offset-lg-1 col-12  align-content-center  p-0">
      <div class="form-floating">
        <input
          class ="form-control inputStyle "
          [ngModel]="search_word"
          (ngModelChange)="filterData($event)"
          placeholder="'Input amount'"
        >
        <label class="form-label">Input amount</label>
      </div>  <!--Close form -->
    </div> <!--Close col -->

    <div class="row">
      <p *ngIf="error!==''" class="error my-1" >{{error}}</p>
    </div>

    </div>

    <div class="row my-5 py-3">
    <div class="col-10 offset-1  my-2 d-flex justify-content-center align-items-center">
      <button class="yellow-button w-100 py-3 rounded-3" (click)="cancelActivate()" id="sign-button" >Geneate Barcode</button>
    </div>

    <div class="col-10 offset-1 my-2 d-flex justify-content-center align-items-center">
      <button class="violet-button  w-100 py-3 rounded-3"(click)="cancelActivate()" id="create-button">Cancel</button>
    </div>

  </div>


</div>  <!--Close container-->
