<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div class="row h-100" >
<!--    <div class="row h-100" *ngIf="complete_signup==true">-->

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5">

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started d-flex align-items-end justify-content-center">
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center ">
          <img src="../../../../assets/images/login/logo.svg" class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none py-5" style="height: 70%">
        <div class="d-flex justify-content-center h-100">
          <img class="img-fluid" src="../../../../assets/images/login/picture_asset.svg" alt="">
        </div>
      </div>
      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none">
        <div class="text-center" >
          <a class="bottom-a-white" routerLink="'/'"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="'/'"> Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="'/'"> Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div class="col-lg-4 offset-lg-1 h-100 py-0">

      <div class="row d-lg-none" style="height:5% "></div>

      <!-- Logo appears in small screen -->
      <div class="row d-lg-none my-3 px-1" >
        <img class="img-fluid " src="../../../../assets/images/login/logo_blue.svg" alt="Kredio">
      </div>

      <!-- Log In -->
      <div *ngIf="!forgotPassword" class="h-100" id="signInForm">
        <br>
        <br>

        <!--New Account title: Small screen-->
        <div class="row  d-lg-none " >
          <p class="p-title mb-0 py-2 ">Sign In</p>
        </div>

        <!--New Account title: Large screen-->
        <div class="row  d-none d-lg-block   " >
          <p class="p-title mb-0 py-2">Sign In</p>
        </div>

        <div class="row h-50">
          <div class="h-100">
            <div class="form-floating my-3">
              <input
                id="inputEmail"
                [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
                [type]="fieldsConfig[0].type"
                [placeholder]="fieldsConfig[0].placeHolder"
                [ngModel]="fieldValues[fieldsConfig[0].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[0].name)"
              >
              <label [class]="validationErrors[fieldsConfig[0].name]? 'form-label labelError ms-2': 'form-label ms-2'"
              >{{fieldsConfig[0].displayName}}</label>
            </div>
            <p  class="error my-1" id="errorEmail" *ngIf="this.validationErrors[fieldsConfig[0].name]">
              {{this.validationErrors[fieldsConfig[0].name]}}
            </p>

            <div class="form-floating my-3">
              <input
                id="passwordEmail"
                [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
                [type]="fieldsConfig[1].type"
                [placeholder]="fieldsConfig[1].placeHolder"
                [ngModel]="fieldValues[fieldsConfig[1].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[1].name)"
              >
              <label [class]="validationErrors[fieldsConfig[1].name]? 'form-label labelError ms-2': 'form-label ms-2'"
              >{{fieldsConfig[1].placeHolder}}</label>
            </div>
            <p class="error my-1" id="errorPswd" *ngIf="this.validationErrors[fieldsConfig[1].name]">
              {{this.validationErrors[fieldsConfig[1].name]}}
            </p>

            <div class="row mt-3">
              <div class="d-flex justify-content-center align-items-end p-wording ">
                Forgot your&nbsp;<span (click)="changePassword()" id="forgotPswd">password?</span>
              </div>
            </div>

            <div class="row" *ngIf="this.errorLogin !=='' ">
              <p class="error my-1">
                {{this.errorLogin}}
              </p>
            </div>

          </div>
        </div>

        <div class="row">

          <div class="d-flex justify-content-center bot-buffer1">
            <button (click)="submitForm()" class="violet-button rounded-4 w-100 py-3 m-2 "
            id="submit-button">
              Submit
            </button>
          </div>

          <div class="row d-flex justify-content-center  mt-3 ">
            <div class="d-flex justify-content-center p-wording" >
              Don't have an account?&nbsp;&nbsp; <span routerLink="/signup">Register</span>
            </div>
          </div>

        </div>

      </div>

      <!-- Recover Password -->
      <div *ngIf="forgotPassword" class="h-100" id="recoverPswForm">
        <app-change-psw></app-change-psw>
      </div>



    </div>

  </div>

</div>



