import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";

export interface Discount {
  id: string,
  name: string,
  description: string,
  type: number,
  amount: string,
  percentage: string,
  exp_date: string,
  merchant_name: string,
}

export interface ActivateDiscount {
  loan_id:string,
  discount_id:string

}
@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private api: ApiService) { }

  getDiscounts() {
    return this.api.get('/discounts/')
  }
  getActiveDiscounts() {
    return this.api.get('/active_discounts/')
  }

  activateDiscount(data:ActivateDiscount){
   return this.api.post('/activate_discount/', data)
  }


}
