<div class="container-fluid  " >

  <div *ngIf="startActivation" class="px-2 mb-2 ">

    <div class="row mt-2">

      <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
        <div class="w-100  h-100">
          <img class="img-fluid h-100" src="assets/images/cards/activate_card.svg" alt="Card Img">
        </div>
      </div>

      <div class="col-md-10 col-8 p-0 d-flex align-items-center" >
        <p class="transfer-text  p-0 mb-0"> Activate Physical Card</p>
      </div>

      <div class="col-md-1 col-2  p-0 d-flex align-items-center justify-content-center button-pointer" >
        <img class=" w-75 img-fluid"  (click)="cancelActivate()"
             src="assets/images/edit_profile/cancel_edit.svg"/>
      </div>

    </div>

    <div class="row text-center d-none d-sm-inline">
      <p class="p-info">Enter the information requested to activate your card.</p>
    </div>

    <div class="row mb-3" >
      <div class=" d-flex justify-content-center">
        <div class="form-floating w-100 ">
          <input
            id="cardNumberInput"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError ': 'form-control inputStyle'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
          <label class="form-label ms-2">{{fieldsConfig[0].displayName}}</label>
        </div>
      </div>
      <p  class="error" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>
    </div> <!--Close row-->

    <div class="row my-3">
      <div class=" d-flex justify-content-center">
        <div class="form-floating w-100 ">
          <input
            id="cvvNumberInput"
            [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError ': 'form-control inputStyle'"
            [placeholder]="fieldsConfig[1].placeHolder"
            [type]="fieldsConfig[1].type"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
          <label class="form-label ms-2">{{fieldsConfig[1].displayName}}</label>
        </div>
      </div>
      <p  class="error" *ngIf="this.validationErrors[fieldsConfig[1].name]">
        {{this.validationErrors[fieldsConfig[1].name]}}
      </p>
    </div> <!--Close row-->

    <div class="row my-3">
      <div class="input-group ">
        <input
          id="expirationDateInput"
          [class]="validationErrors[fieldsConfig[2].name]? 'form-control formError py-3': 'form-control py-3'"
          placeholder="Expiration Date"
          [ngModel]="dateExpiration"
          (ngModelChange)="updateFieldValue(fieldsConfig[2].name,$event)"
          (blur)="setFieldToEdited(fieldsConfig[2].name)"
          ngbDatepicker
          #doe="ngbDatepicker"
          [minDate]="minAllowedDateOfExp"
        />
        <button class="btn btn-outline-secondary bi bi-calendar3 btn-color" (click)="doe.toggle()" type="button"></button>
      </div>
      <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
        {{this.validationErrors[fieldsConfig[2].name]}}
      </p>
    </div>

    <div class="row">

      <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
        <button id="activateBtn" class="violet-button w-lg-75 w-100 rounded-2 py-3" (click)="activateCard()">Continue</button>
      </div>
      <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
        <button class="yellow-button w-lg-75 w-100 rounded-2 py-3"  (click)="cancelActivate()">Cancel</button>
      </div>
    </div> <!--Close row-->


  </div>  <!--Close ngif-->

  <div *ngIf="cardWasActivated==true">

    <div class="row mt-3">

    <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
      <div class="m-0 p-0 h-100">
        <img class="img-fluid m-0 h-100" src="assets/images/cards/activate_card.svg" alt="Card Img">
      </div>
    </div>

    <div class="col-md-11 col-10 py-0" >
      <p class="transfer-text my-2 p-0"> Your card is now active!</p>
    </div>

  </div>

    <div class="row text-center my-4">
      <p class="p-info">Now your card is ready to make purchases</p>
    </div>

    <div class="text-center my-5">
      <img src="assets/images/cards/card_ready.svg">
    </div>

    <div class="col-lg-6 offset-lg-3 col-12  d-flex justify-content-center align-items-center my-2 ">
      <button class="violet-button w-lg-75 w-100 rounded-2 py-3" (click)="cancelActivate()">OK</button>
    </div>


  </div>  <!--Close ngif-->

  <div *ngIf="cardWasActivated==false">

      <div class="row mt-3">

    <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
      <div class="m-0 p-0 h-100">
        <img class="img-fluid m-0 h-100" src="assets/images/cards/activate_card.svg" alt="Card Img">
      </div>
    </div>

    <div class="col-md-11 col-10 py-0" >
      <p class="transfer-text my-2 p-0"> Your card was not activated, try again</p>
    </div>

  </div>

      <div class="row text-center my-4">
        <p class="p-info">There was an inconvenient during the activation process.</p>
        <p class="p-info">Please, try again.</p>
      </div>

      <div class="text-center my-5">
        <img src="assets/images/cards/card_not_ready.svg">
      </div>

      <div class="col-lg-6 offset-lg-3 col-12  d-flex justify-content-center align-items-center my-2  ">
        <button class="yellow-button w-lg-75 w-100 rounded-2 py-3"  (click)="cancelActivate()">Try Again</button>
      </div>


</div>  <!--Close ngif-->

</div>  <!--Close container-->
