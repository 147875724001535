import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {EmptyObject} from "chart.js/dist/types/basic";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  isHigher0Lower100Validator,
  isNotEmptyValidator
} from "../components/Shared/forms-shared/validators";

export interface BenefitsAccount {
  id: string,
  pending_cashback: string,
  paid_cashback: string,
  total_saved: string,
  owner: string,
  is_primary: number,
  pri_account_id: string,
  has_benefits: boolean
}
export interface Payroll {
  userId: string,
  is_enrolled: boolean,
  available_wage: number,
  total_expenses: number,
  total_wage: number,
  pay_period: string,
}
export interface PayrollRecord {
  date: string,
  description:string,
  amount: string,
}

export interface ArgyleData {
  event: string,
  name: string,
  data: AddedData
}

export interface AddedData {
  account: string,
  user: string

}
export interface ArgylePaystubs {
  argyle_account_id :string,
  from_date ?:string,
  end_date ?:string,
  limit ?: string
}

export interface DDSConfigPaylod {
  loan_id: string,
  allocation_value ?:string,
  allocation_max ?:string,
  allocation_min ?: string,
  allocation_percentage ?: string,
  type: number
}

export interface PayrollDataRequest{
  argyle_account_id:string
}

@Injectable({
  providedIn: 'root'
})
export class PayrollService {
  constructor( private api: ApiService) { }
  payrollFormFields : FormFieldConfig[] = [
    {
      name: 'type',
      type: 'number',
      displayName: 'Allocation Type',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'allocation_min',
      type: 'string',
      displayName: 'Min USD Fixed Amount',
    },
    {
      name: 'allocation_max',
      type: 'string',
      displayName: 'Max Fixed Amount',
    },
    {
      name: 'allocation_percentage',
      type: 'string',
      displayName: 'Percentage Number',

    },
    {
      name: 'allocation_value',
      type: 'string',
      displayName: 'Fixed USD Amount ',
    },
    {
      name: 'loan_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
  ]

  getPayrollAccount(data:PayrollDataRequest) {
    return this.api.post('/get_payroll_account/', data)
  }

  // Argyle //
  createPayrollUser(data:EmptyObject):Observable<any>{
    return this.api.post('/create_payroll_user/',data)
  }

  sendPayrollData(data:ArgyleData):Observable<any>{
    return this.api.post('/payroll/added_account/',data)
  }

  getPayrollUser(): Observable<any>{
    return this.api.get('/get_payroll_user/')
  }

  getArgylePaystubs(data:ArgylePaystubs):Observable<any>{
    return this.api.post('/get_paystubs/',data)
  }

  deletePayrollUser():Observable<any>{
    return  this.api.post('/delete_payroll_user/',{})
  }
  getDDSConfig(data:DDSConfigPaylod):Observable<any>{
    return  this.api.post('/get_dds_config/',data)
  }
}
