
<div class="container-fluid">

    <div class="row">

    <div class="col-2 d-flex align-items-center justify-content-center" >
      <img  class="img-fluid"  src="assets/images/edit_profile/profile_img.svg" />
    </div>

    <div class="col-8 d-flex align-items-center" >
      <p class="p-full-name m-0" >{{this.first_name}} {{this.last_name}}</p>
    </div>

    <!--    <div class="cancel-edit d-flex justify-content-end">-->
    <div class="col-2 d-flex align-items-center justify-content-center button-pointer">
      <img id="imgCancel" class="img-fluid" (click)="cancelEditAddress()" src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <!-- Edit Information -->
    <div class="row my-3 d-flex justify-content-center" *ngIf="isDataEdited === false">

    <div class="col-12">
      <p class="p-personalInfo">Postal Address Information</p>
    </div>

    <div *ngFor="let field of fieldsConfig" id="dataDisplay">

      <div *ngIf="(field.name !== 'state' )"
           class="form-floating my-2">
        <input
          [class] ="validationErrors[field.name]? 'form-control formError': 'form-control'"
          [placeholder]="field.placeHolder"
          [type]="field.type"
          [ngModel]="fieldValues[field.name]"
          (ngModelChange)="updateFieldValue(field.name, $event)"
          (blur)="setFieldToEdited(field.name)"
        >
        <label class="form-label">{{field.displayName}}</label>
      </div>

      <div *ngIf="field.name === 'state'" class="form-floating my-3 ">
        <select
          [id]="'inputState'"
          class = "form-select"
          [ngModel]="fieldValues[field.name]"
          (ngModelChange)="updateFieldValue(field.name, $event)"
          (blur)="setFieldToEdited(field.name)"
        >

          <option value="" disabled selected>Select state</option>
          <option
            *ngFor="let option of field.options"
            [value] = option
          >{{option}}</option>

        </select>
        <label class="form-label" >{{this.field.displayName}}</label>
      </div>


      <p  class="error"
          *ngIf="this.validationErrors[field.name] && field.name !='email' ">
        {{this.validationErrors[field.name]}}
      </p>

    </div> <!--Close ngfor-->

    <div class="mt-5">
      <button id="btnSubmit" class="violet-button py-3 w-100 rounded-4" (click)="confirmChanges()"
      >Save</button>
    </div>


  </div> <!--Close row-->

  <!-- Response -->
    <div *ngIf="isDataEdited === true">
      <div *ngIf="dataEditSuccess === true">
        <div class="offset-2 col-8 text-center py-3  ">
          <img  class=" img-fluid"  src="assets/images/edit_profile/success_logo.svg" />
        </div>

        <div class="offset-2 col-8 p-0 ">
          <p id="msg_1" class="p-response text-center fw-bold my-2 ">Your profile data has been updated.</p>
          <p id="msg_2" class="p-response text-center fw-bold my-2">You will be redirected shortly.</p>
        </div>
      </div>
    </div>

  <div class="d-lg-none d-block" style="height: 3rem;"></div>

  <div *ngIf="confirmChangesModal==false">
    <app-modal-new-acc>
      <app-profile-confirm-change
        (confirmChanges)="summitEditAddress($event)"
      ></app-profile-confirm-change>
    </app-modal-new-acc>
  </div>

</div> <!-- Close container -->



