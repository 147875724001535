
  <div class="container-fluid">

    <div class="row" >
      <div class="col-lg-1 col-2 p-0 d-flex align-content-around" >
        <img class="img-fluid m-0" src="assets/images/cards/cards_icon.svg">
      </div>
      <div class="col-lg-10 col-8 d-flex align-content-center " >
        <p id="pTitle" class="p-title my-2 mb-0  " >{{this.title}}</p>
      </div>
      <div  id="btnClose" class="col-lg-1 col-2 p-0 d-flex align-content-around button-pointer"
            (click)="cancel()">
        <img class="img-fluid m-0"  src="assets/images/accounts/close.svg">
      </div>
    </div> <!--Close row-->

    <div class="row text-center my-3" >
      <div>
        <img src="assets/images/accounts/success.svg" alt="bullet">
      </div>
      <div class="row">
        <p id="message" class="p-title-msg my-4 text-center">{{this.message}}</p>
      </div>

    </div>
    <div class="row my-2">
      <div  class="text-center my-2"  (click)="confirm()">
        <button class="yellow-button py-3 px-5 w-100 rounded-3"(click)="confirm()" >Confirm</button>
      </div>
      <div  class="text-center my-2"  (click)="cancel()">
        <button class="violet-button py-3 px-5 w-100 rounded-3" (click)="cancel()">Close</button>
      </div>
    </div>

  </div>


