import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-spinn-wheel',
  templateUrl: './spinn-wheel.component.html',
  styleUrls: ['./spinn-wheel.component.css']
})
export class SpinnWheelComponent {
  @Input() message !:string
}
