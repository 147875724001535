<div class="container-fluid   scroll-acc-cards-display"  >
    <div *ngFor="let acc of accounts_list ; let idx = index" id="accountsDisplay" class = "row">

      <div class="row my-2  mx-0 px-0" id = "{{idx}}">

        <div class="col-1 p-0  d-none d-lg-block d-xl-block" >
          <div class="d-flex align-items-center justify-content-center h-100 w-100 p-0" >
            <img src="assets/images/accounts/accounts_icon.svg" class="img-fluid w-75" >
          </div>
        </div>

        <!-- Display Data -->
        <div class="col-5 ">
          <p  *ngIf="acc.type=='Physical'" class="counterparty my-1" id="accNumber"> Account ({{acc.number.toString().slice(-4)}})</p>
          <p  *ngIf="acc.type=='Virtual'" class="counterparty my-1" id="accNumberV"> Account ({{acc.number.toString().slice(-4)}})
            <span class="p_accType">Virtual</span></p>
          <p  *ngIf="acc.type=='Kids Physical'" class="counterparty my-1" id="accNumberP"> Account ({{acc.number.toString().slice(-4)}})
            <span class="p_accType">Physical</span></p>
          <p  *ngIf="acc.type=='Cashback'" class="counterparty my-1" id="accNumberCB"> Account ({{acc.number.toString().slice(-4)}})
            <span class="p_accType">Cashback</span></p>

          <p class="p-wage-title my-1">Available Balance</p>
          <p class="text-kredio-hint color-kredio p-wage-amount my-0" id="accBalance">${{acc.balance|number :'.2'}}</p>
        </div>

        <!--Transfer: Large Screen-->
        <div class="col-3 fr-button   d-none d-lg-block d-xl-block" id="iconTransfers">
            <div class="text-center button-pointer rounded-4 py-2   button-border">
              <div  id="btnPayTrs" routerLink='/main/paymentsTransfers' class="button-pointer">
                <img src="assets/images/accounts/transfer_icon.svg" routerLink='/main/paymentsTransfers'
                     class="img-fluid"
                     alt="Transfer">
              </div>
              <p class="p-button mb-0">Transfer</p>
            </div>
        </div>

        <!--Transfer: Small Screen-->
        <div class="col-4 fr-button  p-0   d-sm-block  d-lg-none" id="iconTransfersSmall">
          <div class=" d-flex justify-content-center align-items-center h-100">
            <div class="text-center button-pointer rounded-4 py-2 px-4 button-border">
              <div id="btnPayTrsSmall" routerLink='/main/paymentsTransfers' class="button-pointer">
                <img src="assets/images/accounts/transfer_icon.svg"
                     class="img-fluid"
                     alt="Transfer">
              </div>
              <p class="p-button  mb-0">Transfer</p>
            </div>
          </div>

        </div>

        <!--More Button-->
        <div class="col-3 fr-button   d-none d-lg-block " id="iconMore">
<!--          <div class=" d-flex justify-content-center align-items-center h-100">-->
            <div id="btnMorediv"  class="text-center button-pointer rounded-4 py-2  button-border">
              <div id="btnMore" routerLink="/main/accounts" class="button-pointer">
                <img src="assets/images/accounts/more_icon.svg" routerLink="/main/accounts"
                     class="img-fluid"
                     alt="More">
              </div>
              <p class="p-button  mb-0" >More</p>
            </div>
<!--          </div>-->
        </div>

        <div class="col-2 d-flex align-items-center justify-content-center d-lg-none"  id="smallSceenRow">
          <h2 class="fw-bold" class="button-pointer" routerLink="/main/accounts" ><b>...</b></h2>
        </div >

      </div>

    </div>
<!--  </div>-->
</div>

