import {Component, OnInit, ViewChild,} from '@angular/core';
import {HomeService} from "../../../services/home.service";
import {Account, AccountService} from "../../../services/account.service";
import {Transaction, TransactionService} from "../../../services/transaction.service";
import {TransactionsDisplayComponent} from "../transactions-display/transactions-display.component";
import {SharedataService} from "../../../services/sharedata.service";
import {CONTROL} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit{

  @ViewChild(TransactionsDisplayComponent) TrDisplayComponent !: TransactionsDisplayComponent;

  your_route :string = 'Transactions'

  month_object :string[]=['Jan', 'Feb','Mar', 'Apr', 'May', 'Jun','Jul', 'Aug','Sep','Oct' , 'Nov', 'Dec']

  period_selected !:number

  d = new Date()
  home_default_year:number = this.d.getFullYear()
  month_picked :number = this.d.getMonth()+1

  main_account !: Account
  balance !: string
  accounts_list !:string[][]
  account_picked !: string


  picked_labels !: string[]
  picked_expenses !: number[]
  picked_income !:number[]

  total_expenses !:number
  total_income !:number

  transactions_list : Transaction[] = []
  transactions_display: Transaction[] = []


  selected_accoint_id !:string
  constructor(
    private accountService: AccountService,
    private homeService: HomeService,
    private transactionService:TransactionService,
  ) { }


  chartData = [
    {
      data: this.picked_expenses,
      label: 'Expenses',
      backgroundColor: '#C6355C',
      borderRadius:6
    },
    {
      data:this.picked_expenses,
      label: 'Income',
      backgroundColor: '#5FC197',
      borderRadius:6
    },
  ];
  chartLabels = this.picked_labels
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay:1,
  };

   updateChart(expArr: number[], incArr:number[], labArr: string[]) {
    this.chartData[0].data  = expArr
    this.chartData[1].data  = incArr
    this.chartLabels = labArr
  }

   getMonthName(num: number, yr:number){
    return this.month_object[num-1] +'-'+yr.toString()
   }
   getTransfersChartData(period:number){

     this.period_selected = period
     this.picked_labels = []
     this.picked_expenses = []
     this.picked_income = []


     let payload = {
       loan_id: this.account_picked,
       year: 0, // this.home_default_year
       month: 0, //this.month_picked
       period: period, type_in: [], limit: 50000 }


     this.transactionService.getTransactions(payload).subscribe({ next:(res)=>{

         if(res.e_code === 1){

           const yr_transaction = [...new Set(res.transfers.map((tr:Transaction)=> tr.year))]

           for(let yr of yr_transaction) { // filter transactions per year
             const annual_transactions = res.transfers.filter((tr: Transaction) => tr.year === yr)
             const month_list =[...new Set(annual_transactions.map((tr: Transaction) => tr.month))]

             for(let m of month_list){
               this.picked_labels.push(this.getMonthName(Number(m), Number(yr)))
               const month_transactions = annual_transactions.filter((tr:Transaction)=> tr.month === m)

               // Filter Expenses
               const expenses = month_transactions.filter((tr:Transaction)=> Number(tr.amount)<0).map((tr:Transaction)=>Number(tr.amount))
               // Filter Income
               const income = month_transactions.filter((tr:Transaction)=> Number(tr.amount)>=0).map((tr:Transaction)=>Number(tr.amount))

               // // Save Income and expenses per month
               const total_expenses = - expenses.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)
               const total_income = income.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)

               this.picked_expenses.push(total_expenses)
               this.picked_income.push(total_income)

             }

           }
         }

         this.updateChart(this.picked_expenses, this.picked_income, this.picked_labels)
     }})

   }

   filterTransactionsMonth(data:number){

     // Pass transaction data to Transactions Display Component
     this.TrDisplayComponent.getTransactionsMonthList(this.account_picked, Number(data), this.home_default_year)

     let payload = {
       loan_id: this.account_picked,
       year:this.home_default_year, month:Number(data),
       type_in: [], limit: 1000, period: 0 }

    this.transactionService.getTransactions(payload).subscribe({ next:(res)=>{

        this.transactions_list = [...res.transfers].reverse()

        // Filter Expenses
        const expenses = this.transactions_list.filter((tr:Transaction)=> Number(tr.amount)<0).map((tr:Transaction)=>Number(tr.amount))
        // Filter Income
        const income = this.transactions_list.filter((tr:Transaction)=> Number(tr.amount)>=0).map((tr:Transaction)=>Number(tr.amount))

        // // Save Income and expenses per month
        this.total_expenses = - expenses.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)
        this.total_income = income.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)

        // Get new balance
        if (this.transactions_list.length > 0) {
          this.balance = this.transactions_list[0].account_balance
        }
        else { this.balance = "0.00" }
      }})
  }

   filterTransactions(data:string){

     // Update Chart displayed on the right
     this.account_picked = data
     this.getTransfersChartData(this.period_selected)


     // Pass transaction data to Transactions Display Component
     this.TrDisplayComponent.getTransactionsList(data)

     let payload =  {
       loan_id: data, year:this.home_default_year,
       month:this.month_picked,
       type_in: [], limit: 1000, period: 0
     }
      this.transactionService.getTransactions(payload).subscribe({ next:(res)=>{
        if(res.e_code === 1 ) {
          this.transactions_list = [...res.transfers].reverse()

          // // Filter Expenses
          const expenses = this.transactions_list.filter((tr:Transaction)=>
            Number(tr.amount)<0).map((tr:Transaction)=>Number(tr.amount)
          )
          // Filter Income
          const income = this.transactions_list.filter((tr:Transaction)=>
            Number(tr.amount)>=0).map((tr:Transaction)=>Number(tr.amount))

          // Save Income and expenses per month
          this.total_expenses = - expenses.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)
          this.total_income = income.reduce((accumulator:number, currentValue:number) => accumulator + currentValue,0)

          // Get new balance
          if (this.transactions_list.length > 0) {
            this.balance = this.transactions_list[0].account_balance
          } else {  this.balance = "0.00" }
      }}})
  }

  ngOnInit(): void {

     // Set initial period to this month : Chart
    this.period_selected = 1

    // Get Accounts and transactions data
    this.accountService.getAccounts().subscribe({ next:(res) => {
        if(res.e_code === 1 ) {

          this.accounts_list = res.accounts.map((acc:Account)=>[acc.id,acc.number.toString()])
          this.main_account = res.accounts.filter((acc:Account) => acc.is_primary === 1)[0]

          this.account_picked = this.main_account.id
          this.balance = this.main_account.balance

          this.getTransfersChartData(this.period_selected)
          this.filterTransactions(this.main_account.id)
        }
      }})
  }

}
