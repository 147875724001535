import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse , HttpHeaders} from "@angular/common/http";
import { environment } from "../../environments/environment";
import {map, Observable, throwError} from "rxjs";
import { catchError } from 'rxjs/operators';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.status === 0) {
  //     console.error('An error occurred: ', error.error);
  //   } else {
  //     console.error(`Request error status: ${error.status}, body: ${error.error}`);
  //     alert(error.message )
  //   }
  //   return throwError(
  //     () => new Error('An error occurred when making a request to the server, please try again later.')
  //   );
  //
  // }

  get(url: string): Observable<any>{
    return this.http.get<any>(environment.apiUrl + url).pipe(
      map(res => res),
    //  catchError(this.handleError)
    );
  }

  post(url: string, body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + url, body).pipe(
  //   catchError(this.handleError)
    );
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + url).pipe(
    //  catchError(this.handleError)
    );
  }

}
