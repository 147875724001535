<div class="container-fluid scroll-acc-cards-display">
  <div *ngIf="cards_list" id="displayCards">
    <div *ngFor="let card of cards_list ; let i = index"  id="displayCardsList">
      <div class="row my-3 mx-0 px-0" id = "{{i}}" >

        <div class="col-1 p-0  d-none d-lg-block d-xl-block">
          <div class="d-flex align-items-center justify-content-center h-100 w-100 p-0" >
            <img src="assets/images/home/cards_icon.svg" class="img-fluid w-75" >
          </div>
        </div>

        <div class="col-7 ps-3 pb-0" >
          <p  *ngIf="card.card_type=='Physical'"  class="counterparty my-1" id="cardNamePh" >{{card.name}}</p>
          <p *ngIf="card.card_type=='Virtual'" class="counterparty my-1" id="cardNameV" >{{card.name}}<span class="p_cardType">  Virtual</span></p>
          <p *ngIf="card.card_type=='Kids Physical'" class="counterparty my-1" id="cardNameP" >{{card.name}}<span class="p_cardType">  Physical</span></p>
          <p class="text-kredio-hint   my-1" >Available Balance</p>
          <p class="text-kredio-hint color-kredio  p-wage-amount my-0">${{card.balance|number :'.2'}}</p>
        </div>

        <div class="col-4 ps-2 text-end d-none d-lg-block d-xl-block" >
          <div  class="d-flex align-items-center justify-content-center h-100 w-100 p-0">
            <p class="p-card-spending">${{card.current_spending|number :'.2'}}</p>
          </div>

        </div>
        <div class="col-5 ps-2 text-end d-lg-none d-xl-none">
          <p class="p-card-spending">${{card.current_spending|number :'.2'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>


