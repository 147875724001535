import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {emailValidator, isNotEmptyValidator} from "../components/Shared/forms-shared/validators";


export interface ActivateDevice {
  user_id: string;
  code: number;
}

export interface Cred {
  email: string;
  password: string;
}

export interface User {
  username: string,
  email: string,
  user_id: string,
  first_name: string,
  last_name: string,
  company_name: string,
  is_email_verified:number
}

export interface ChangePassword {
  new_password: string,
  new_password_2: string
}

export interface ValidatePhoneNumber {
  phone: string,
}

export interface ActivatePasswordCode {
  phone: string,
  code: number
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInUser: User | undefined;

  formFields: FormFieldConfig[] = [
    {
      name: 'email',
      displayName: 'Email',
      type: 'string',
      placeHolder: 'Email',
      validators: [isNotEmptyValidator, emailValidator]
    },
    {
      name: 'password',
      displayName: 'Password',
      type: 'password',
      placeHolder: 'Password',
      validators: [isNotEmptyValidator]
    }
    ]

  formFieldsDevice: FormFieldConfig[] = [
    {
      name: 'code',
      displayName: 'Verification code',
      type: 'number',
      placeHolder: 'Code',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'user_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    }
  ]
  get isUserLoggedIn() {
    const user = JSON.parse(sessionStorage.getItem('isLoggedIn') as string)
    return user !== null ? true : false
  }


  constructor(private api: ApiService,
              private router: Router) {
  }

  isValid(): boolean {
    this.check();
    return !!(this.loggedInUser || sessionStorage.getItem('isLoggedIn'))
  }

  check(): void {
    this.api.get('/get_auth/').subscribe(res => {
      this.loggedInUser = res as User;
      sessionStorage.setItem('isLoggedIn', 'true');
    });
  }

  login(cred: Cred) : Observable<any>{
    return this.api.post('/auth/', cred)
  }

  logout(): void {
    localStorage.clear();
    this.router.navigateByUrl('/login')
    this.api.delete('/get_auth/').subscribe(res => { });
  }


  validatePhoneNumber(data:ValidatePhoneNumber):Observable<any>{
    return this.api.post('/forgot_passwd_phone/', data)
  }

  activateCodeChangePassword(data:ActivatePasswordCode):Observable<any>{
    return this.api.post('/activate_paswd_phone_code/', data)
  }

  changePassword(data:ChangePassword):Observable<any>{
    return this.api.post('/profile/change_password/', data)
  }
  activateDevice(data:ActivateDevice):Observable<any>{
    return this.api.post('/activate_device/', data)
  }
}
