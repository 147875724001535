import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AccountService, ExtAccount} from "../../../services/account.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-account-ext-add',
  templateUrl: './account-ext-add.component.html',
  styleUrls: ['./account-ext-add.component.css']
})
export class AccountExtAddComponent implements OnInit{

  @Input() account_id !:string
  @Input() validateOn: string = 'change'

  add_account_initial :boolean = true
  fieldsConfig !: FormFieldConfig[]
  fieldWasEdited: any = {}
  validationErrors : any = {}


  constructor(
    private router : Router,
    private accountsService : AccountService,
    public dialogRef: MatDialogRef<AccountExtAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  _fieldValues : any = {}

  get fieldValues(){
    return this._fieldValues
  }

  set fieldValues(newFieldValues:any){
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){

    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }


  submitNewAccount():void{
    this.dialogRef.close()
    // this.validationErrors = this.getvalidationErrors(true)
    // if(Object.keys(this.validationErrors).length > 0)
    //    return
    // this.accountsService.createExternalAccount(this.fieldValues).subscribe({
    //   next:(res)=>{
    //     if(res.e_code ==1){
    //       this.add_account_initial = false
    //     }}
    // })
  }

  cancelAddAccount():void{
    this.dialogRef.close()
  }

  ngOnInit() {

    this.account_id = this.data.account_id

    this.fieldsConfig = this.accountsService.formFields
    for(let field of this.fieldsConfig){
      this.fieldValues[field.name] = ''
    }
    this.fieldValues = {...this.fieldValues, 'primary_loan_id': this.account_id }
    this.fieldValues = {...this.fieldValues, 'typ': 1 }
  }

}
