import {Component, OnInit} from '@angular/core';
import {Account} from "../../../services/account.service";
import {AccountService} from "../../../services/account.service";

@Component({
  selector: 'app-accounts-display',
  templateUrl: './accounts-display.component.html',
  styleUrls: ['./accounts-display.component.css']
})
export class AccountsDisplayComponent implements OnInit {
  accounts_list !: Account[]
  constructor(
    private accountsService: AccountService,
    ) { }

  ngOnInit(): void {
    this.accountsService.getAccounts().subscribe({ next:(res)=>{
        if(res.e_code === 1) {
          this.accounts_list = res.accounts
        }}
    })
  }
}
