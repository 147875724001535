import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-mid',
  templateUrl: './modal-mid.component.html',
  styleUrls: ['./modal-mid.component.css']
})
export class ModalMidComponent {

}
