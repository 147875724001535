<div class="container-fluid h-100">

      <app-nav-buttons [current_route]= your_route ></app-nav-buttons>

      <!-- First Row: Large Screen d-none d-sm-block-->
      <div class="row d-none d-md-block h40 p-0  m-3">
        <div class="row bg-white rounded-3 mx-0 h-100 py-1">

          <!--Dropdown options and display data - -->

          <!--Data & dropdown options-->
          <div class="col-5 mt-4 mb-2 ">

            <!--Dropdown options : Large Screen ps-0 ps-lg-3-->
            <div class="row h33" >

              <!--Pick Account : Large Screen-->
              <div class="col-sm-6 col-12 px-0 ">
                <div class="d-flex align-items-center h-100">
                  <div class="form-floating my-1 w-100 px-1">
                    <select  id='accountSelect'
                             class = "form-select form-select-large rounded-4"
                             [ngModel]="account_picked"
                             (ngModelChange)="filterTransactions($event)"
                    >
                      <option
                        *ngFor="let option of accounts_list"
                        [value] = option[0]
                      >{{option[1]}}</option>
                    </select>
                    <label class="form-label">Current account view:</label>
                  </div> <!--Close form floating -->
                </div>

              </div>

              <!--Pick Month: Large Screens -->
              <div class="col-sm-6 col-12 px-0">
                <div class="d-flex align-items-center h-100">
                  <div class="form-floating my-1 w-100 px-1 ">
                    <select  id="monthSelect" class = "form-select form-select-large rounded-4"
                             [ngModel]="month_picked"
                             (ngModelChange)="filterTransactionsMonth($event)"
                    >
                      <option value = 1 >January</option>
                      <option value = 2 >February</option>
                      <option value = 3 >March</option>
                      <option value = 4 >April</option>
                      <option value = 5 >May</option>
                      <option value = 6 >June</option>
                      <option value = 7 >July</option>
                      <option value = 8 >August</option>
                      <option value = 9 >September</option>
                      <option value = 10 >October</option>
                      <option value = 11 >November</option>
                      <option value = 12 >December</option>
                    </select>
                    <label class="form-label">Filter by</label>
                  </div> <!--Close form floating -->
                </div>

              </div> <!--Close col-6 -->

            </div> <!--Close row -->

            <!--Display Data -->
<!--            <div class="row mt-sm-5 mt-2" >-->
            <div class="row  h33" >
              <div class="col-6 d-flex align-items-center">
                <div>
                  <p class="p-figure my-1">${{this.total_income|number :'.2'}}</p>
                  <p class="p-income my-1">Total income</p>
                </div>

              </div>
              <div class="col-6 d-flex align-items-center">
                <div>
                  <p class="p-figure my-1" >${{this.total_expenses|number :'.2'}}</p>
                  <p class="p-expenses my-1 " >Total expenses</p>
                </div>
              </div>
            </div>

<!--            <div class="row mt-sm-5 mt-2" >-->
            <div class="row  h33">
              <div class="col-6  d-flex align-items-center">
                <div >
                  <p class="p-figure my-1  w-100">${{this.balance|number :'.2'}}</p>
                  <p class="p-balance my-1  w-100">Total balance</p>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                  <button class="yellow-button p-3 rounded-4 fw-bold my-1" routerLink="/trial">Simulate Transaction</button>
              </div>
            </div><!--Close row -->

          </div> <!--Close col-6 -->

          <!--Chart - Large Screen-->
          <div class="col-7 bg-white rounded-3">

            <!-- Chart - Period Buttons-->
            <div class="row d-flex justify-content-end py-2 ">
              <div class="col-lg-2 col-4" >
                <button (click)="getTransfersChartData(1)"
                        [ngClass]=" period_selected === 1 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
                >This month</button>
              </div>
              <div class="col-lg-1 col-2 ps-0" >
                <button (click)="getTransfersChartData(3)"
                        [ngClass]=" period_selected === 3 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
                >3m</button>
              </div>
              <div class="col-lg-1 col-2 ps-0">
                <button (click)="getTransfersChartData(6)"
                        [ngClass]=" period_selected === 6 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
                >6m</button>
              </div>
              <div class="col-lg-1 col-2 ps-0">
                <button (click)="getTransfersChartData(12)"
                        [ngClass]=" period_selected === 12 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
                >1y</button>
              </div>
            </div> <!--Close row-->


            <div class="row" style="height: 82%; position: relative" >
              <div class="chart-container h-100 ">
                <canvas baseChart
                        [type]="'bar'"
                        [legend]="true"
                        [datasets]="chartData"
                        [labels]="chartLabels"
                        [options]="chartOptions"
                ></canvas>
              </div>
            </div> <!--Close row-->


          </div> <!--Close col-6-->

        </div>
      </div>

      <!-- First Row: Small Screen-->
      <!--Dropdown options and display data - -->
      <div class="row bg-white rounded-3 m-1 d-block d-md-none">
          <!--Pick Account : Small Screen-->
          <div class="row  px-2">
              <div class="form-floating px-0">
                <select  class = "form-select form-select-small border-0 mx-0"
                         [ngModel]="account_picked"
                         (ngModelChange)="filterTransactions($event)"
                >
                  <option
                    *ngFor="let option of accounts_list"
                    [value] = option[0]
                  >{{option[1]}}</option>
                </select>

                <label class="form-label">Current account view:</label>
              </div> <!--Close form floating -->
          </div> <!--Close row -->
      </div>

      <div class="row bg-white rounded-3 m-1 d-block d-md-none">
          <!--Display Data -->
          <div class="row mt-3 " >
            <!--Display Available Balance : Small Screen -->
            <div class="d-flex justify-content-between align-items-center bg-white rounded-3 py-2 px-2  ">
                  <div class="d-flex align-items-center">
                    <div><img src="assets/images/transactions/dollar_small.svg" class="img-fluid" alt="Arrow up"></div>
                    <p class="p-income-small mb-0">Available Balance</p>
                  </div>
                  <p class="p-figure mb-1">${{this.balance|number :'.2'}}</p>
            </div>
          </div><!--Close row -->
      </div>

      <div class="row  bg-white rounded-3 mx-1 my-3 d-block d-md-none  h-25">

        <div class="row d-flex justify-content-end py-2" >
          <div class="col-lg-2 col-4" >
            <button (click)="getTransfersChartData(1)"
                    [ngClass]=" period_selected === 1 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
            >This month</button>
          </div>
          <div class="col-lg-1 col-2 ps-0" >
            <button (click)="getTransfersChartData(3)"
                    [ngClass]=" period_selected === 3 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
            >3m</button>
          </div>
          <div class="col-lg-1 col-2 ps-0">
            <button (click)="getTransfersChartData(6)"
                    [ngClass]=" period_selected === 6 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
            >6m</button>
          </div>
          <div class="col-lg-1 col-2 ps-0">
            <button (click)="getTransfersChartData(12)"
                    [ngClass]=" period_selected === 12 ? 'button-chart-selected rounded-1 h-100 w-100 mx-0 my-0 py-2' :
                     'button-chart rounded-1 h-100 w-100 mx-0 my-0 py-2' "
            >1y</button>
          </div>
        </div> <!--Close row-->

        <div class="row py-1" style="height: 85%;" >
          <div class="chart-container  ">
            <canvas baseChart
                    [type]="'bar'"
                    [legend]="true"
                    [datasets]="chartData"
                    [labels]="chartLabels"
                    [options]="chartOptions"
            ></canvas>
          </div>
        </div> <!--Close row-->

      </div>

      <div class="row bg-white rounded-3 m-1 m-lg-3  d-block d-md-none ">
        <!--Pick Month: Small Screens -->
        <div class="col-12 my-3 ">
          <div class="row ">
            <div class="form-floating px-0">
              <select  class = "form-select form-select-small border-0 mx-0"
                       [ngModel]="month_picked"
                       (ngModelChange)="filterTransactionsMonth($event)"
              >
                <option value = 1 >January</option>
                <option value = 2 >February</option>
                <option value = 3 >March</option>
                <option value = 4 >April</option>
                <option value = 5 >May</option>
                <option value = 6 >June</option>
                <option value = 7 >July</option>
                <option value = 8 >August</option>
                <option value = 9 >September</option>
                <option value = 10 >October</option>
                <option value = 11 >November</option>
                <option value = 12 >December</option>
              </select>

              <label class="form-label">Filter by</label>

            </div> <!--Close form floating -->
          </div>
        </div> <!--Close responsive filter -->
      </div>

      <div class="row bg-white rounded-3 m-1 m-lg-3  d-block d-md-none ">
        <!--Total Income: Small Screens -->
        <div class="col-12 my-2  py-0">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center bg-white rounded-3 py-2 px-2  ">
              <div class="d-flex align-items-center">
                <div><img src="assets/images/transactions/arrow_up.svg" class="img-fluid" alt="Arrow up"></div>
                <p class="p-income-small mb-0">Total Income</p>
              </div>
              <p class="p-figure-small-up mb-1">+${{this.total_income|number :'.2'}}</p>
            </div>
          </div>
        </div>
      </div>

        <!--Total Expenses: Small Screens -->
      <div class="row bg-white rounded-3 m-1 m-lg-3  d-block d-md-none ">
        <div class="col-12 my-2  py-0">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center bg-white rounded-3 py-2 px-2  ">
              <div class="d-flex align-items-center">
                <div><img src="assets/images/transactions/arrow_dowm.svg" class="img-fluid" alt="Arrow down"></div>
                <p class="p-income-small mb-0">Total Expenses</p>
              </div>
              <p class="p-figure-small-down mb-1">-${{this.total_expenses|number :'.2'}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Second Row: Display Transactions-->
      <div class="row second_row rounded-3 my-2 px-0 mx-lg-3 mx-1 h-25 "  >
          <div class="card rounded-3 border-0 " >
                    <div class="card-body ">
                      <div class="row h-100 " >
                        <app-transactions-display
                          [productId]= this.account_picked
                          [month]=this.month_picked
                          [year]=this.home_default_year
                          [isHome]="false"
                        ></app-transactions-display>
                      </div>
                    </div> <!--Close card body-->
                  </div> <!--Close card-->
        </div> <!--Close row-->

      <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>


</div> <!-- Close container -->



