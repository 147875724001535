<div class="container-fluid py-2">
  <div class="row text-center my-5" >
        <div>
          <img src="assets/images/accounts/success.svg" alt="bullet">
        </div>
        <div class="row">
          <p id="message" class="p-msg my-4 ">{{this.message}}</p>
        </div>
        <div class="row">
          <p id="message-small" class="p-msg-small my-4 ">Continue to Kredio</p>
        </div>
  </div>
  <div  class="row text-center px-3 my-3">
    <button class="violet-button py-3 px-5 w-100 rounded-4 border-0" id="btnCloseBottom"
            (click)="closeCardsMsg()">Close</button>
  </div>
</div>



