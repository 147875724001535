import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {AgreeemailService} from "../../../services/agreeemail.service";
import {AuthService} from "../../../services/auth.service";
import {subscribeOn, switchMap} from "rxjs";
@Component({
  selector: 'app-verify-complete',
  templateUrl: './verify-complete.component.html',
  styleUrls: ['./verify-complete.component.css']
})
export class VerifyCompleteComponent implements OnInit{

  tokenNew !:string
  emailNew !:string
  isEmailVerified !: boolean

  constructor(
    private activatedRoute: ActivatedRoute,
    private agreeEmailService : AgreeemailService,
  ) {
    this.getDataUrl()
  }

  getDataUrl():void{
    this.emailNew = this.activatedRoute.snapshot.paramMap.get('email') || ""
    this.tokenNew = this.activatedRoute.snapshot.paramMap.get('token') || ""
  }

  ngOnInit() {

    const dataObj = {
      "token": this.tokenNew,
      "email": this.emailNew
    }

    this.agreeEmailService.verifyToken(dataObj).subscribe({
      next: (res) => {

        if (res.e_code == 1) {
          this.isEmailVerified = true
        } else {
          this.isEmailVerified = false
        }

      }})
  }



}
