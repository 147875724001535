import {Component, EventEmitter, OnInit} from '@angular/core';
import * as process from "process";
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import {Router} from "@angular/router";
import { isNotEmptyValidator, usaPhoneValidator} from "../../Shared/forms-shared/validators";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {SharedataService} from "../../../services/sharedata.service";
import {SignupService} from "../../../services/signup.service";

@Component({
  selector: 'app-profile-phone-edit',
  templateUrl: './profile-phone-edit.component.html',
  styleUrls: ['./profile-phone-edit.component.css']
})
export class ProfilePhoneEditComponent implements OnInit{

  isDataEdited :boolean = false
  dataEditSuccess !:boolean

  enterNewPhone :boolean = true
  successPhoneRegister !: boolean
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private sharedDataService : SharedataService,
    private signupService : SignupService
  ) { }

// Display current phone number and edit
  profileData !:ProfileEdit
  fieldsConfig :FormFieldConfig[] = [
    {
      name: 'phone_number',
      displayName:'Phone',
      type: 'tel',
      placeHolder:'123-456-7890',
      validators : [isNotEmptyValidator, usaPhoneValidator],
    },
  ]

  validateOn:string = 'change'
  validationErrors : any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues = newFieldValues
    if (this.validateOn == 'change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  submitNewPhone():void {

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0) {
      return
    }

    const newPhone = "+1" + this.fieldValues['phone_number'].replaceAll("-","")

    this.profileService.editPhone({'phone_number': newPhone}).subscribe({
      next:(res)=>{
        this.enterNewPhone = false
        if(res.e_code == 1) {
          // Pass verification code
          this.sharedDataService.setSecurityCodeId(res.phone_id)

          // Request verification code
          this.signupService.sendVerifyCode({'phone_id': res.phone_id})
            .subscribe({next:(res)=>{
              if (res.e_code ===1) {
                // Request verification code succeeds
                  this.successPhoneRegister = true
              }
            }})
        }
      }})
  }

  // Cancel Edit Phone Number
  cancelPhoneEdit():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  ngOnInit() {
    this.profileService.getUserInfo().subscribe({next:(res) =>{
        if(res.e_code == 1){
            this.profileData = res.res
            this.fieldValues['phone_number'] = this.profileData['phone']
        }}
    });
  }

}
