import {Component, OnInit} from '@angular/core';
import {distinctUntilChanged, tap} from "rxjs";
import {BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: 'app-main-responsive',
  templateUrl: './main-responsive.component.html',
  styleUrls: ['./main-responsive.component.css']
})
export class MainResponsiveComponent implements OnInit{

  displayLarge !:boolean

  Breakpoints = Breakpoints;
  currentBreakpoint:string = '';
  readonly breakpoint$ = this.breakpointObserver
    .observe(['(min-width: 100rem)'])
    .pipe( tap(value => { }), distinctUntilChanged());

  constructor(private breakpointObserver: BreakpointObserver) { }


  private breakpointChanged() {
    if(this.breakpointObserver.isMatched('(min-width: 130rem)')) {
      this.currentBreakpoint = '(min-width: 130rem)';
      this.displayLarge = true
    } else {
      this.displayLarge = false
    }
  }
  ngOnInit() {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

}
