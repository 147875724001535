import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cards-success-msg',
  templateUrl: './cards-success-msg.component.html',
  styleUrls: ['./cards-success-msg.component.css']
})
export class CardsSuccessMsgComponent implements OnInit{

  title !: string
  message !:string
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<CardsSuccessMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  closeCardsMsg():void{
    this.dialogRef.close()
    const currentUrl = '/main/cards'; //this.router.url
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})
  }

  ngOnInit() {
    this.title = this.data.title
    this.message = this.data.msg
  }

}
