import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  checkLengthChrValidator, emailOrEmptyValidator,
  emailValidator,
  isNotEmptyValidator, usaPhoneorEmptyValidator,
  usaPhoneValidator
} from "../components/Shared/forms-shared/validators";
import {Observable} from "rxjs";


export interface RecipientId{
  recipient_id:string
}
export interface Recipient {
  id:string,
  email:string,
  first_name:string,
  last_name:string,
  phone:string,
}

export interface RecipientExtended extends Recipient{
  img_url: string,
  kredio: boolean,
  zelle: boolean,
  cashapp: boolean
}

export interface NewRecipient {
  first_name:string,
  last_name:string,
  email:string,
  phone:string
}
@Injectable({
  providedIn: 'root'
})

export class RecipientsService {
  formFields :FormFieldConfig[] = [
    {
      name: 'first_name',
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators:[isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators:[isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'email',
      displayName: 'Email',
      type: 'string',
      placeHolder: 'id',
      validators:[emailOrEmptyValidator]
    },
    {
      name: 'phone',
      displayName: 'Phone',
      type: 'string',
      placeHolder: 'Phone',
      validators:[ usaPhoneorEmptyValidator]
    },
  ]

  constructor(
    private api: ApiService
  ) { }

  getRecipients():Observable<any>{
    return this.api.get('/recipients/')
  }

  addRecipient(data:any){
    return this.api.post('/add_recipient/',data)
   }

   getRecipient(data:RecipientId){
    return this.api.post('/recipient/', data )
   }

  deleteRecipient(data:RecipientId){
    return this.api.post('/delete_recipient/', data)
  }


  editRecipient(data:any){
    return this.api.post('/edit_recipient/',data)
  }
}
