import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CardService, MerchantCard, MerchantSummary} from "../../../../services/card.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {CardLimitEditComponent} from "../EditLimits/card-limit-edit/card-limit-edit.component";


@Component({
  selector: 'app-card-limit-summary',
  templateUrl: './card-limit-summary.component.html',
  styleUrls: ['./card-limit-summary.component.css']
})
export class CardLimitSummaryComponent implements OnInit{
  card_id !:string
  loan_id !:string
  limitsSummaryMCC !:MerchantCard[]
  limitsSummaryMerch !:MerchantSummary[]
  limitsSummaryGL !:MerchantCard
  allCardLimits : any[] =[]
  periods = ["Weekly", "Bi-weekly" , "Monthly"]
  limitsSummaryMCCCodes !: string[]

  editFeature !:boolean

  message !:string
  constructor(
    private cardService: CardService,
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CardLimitSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any


  ) { }

  editSpendingLimits():void {

    if(this.editFeature === true) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        isEdit : true,
        mcc: this.limitsSummaryMCC,
        mccCodes: this.limitsSummaryMCCCodes,
        merch: this.limitsSummaryMerch,
        gl: this.limitsSummaryGL,
        card_id : this.card_id,
        loan_id: this.loan_id
      }
      dialogConfig.panelClass = 'full-50-dialog';

      this.dialog.open(CardLimitEditComponent, dialogConfig)
    }

    this.dialogRef.close()
  }

  submitSpendingLimit(): void {

    // Add general limit
    if(this.limitsSummaryGL!==undefined){
      const limitsFinalGL = {...this.limitsSummaryGL,
        ['period']:  this.periods.indexOf(this.limitsSummaryGL.period),
        ['loan_id']: this.loan_id,
        ['card_id'] : this.card_id
      }

      this.allCardLimits = [limitsFinalGL]
    }

    // Add merch limits
    if(this.limitsSummaryMerch) {
      for (let limit of this.limitsSummaryMerch) {
        let periodLimit = this.periods.indexOf(limit.period);
        const limitMerch = {
          ...limit,
          loan_id: this.loan_id,
          card_id: this.card_id,
          period: periodLimit
        }
        this.allCardLimits = [... this.allCardLimits,  limitMerch]
      }
    }

    // Add MCC Limits
    if(this.limitsSummaryMCC) {
      for (let[idx, limit] of this.limitsSummaryMCC.entries()) {
        let periodLimit = this.periods.indexOf(limit.period);
        let mccLimit = this.limitsSummaryMCCCodes[idx];
        const limitMCC = {
          ...limit,
          loan_id: this.loan_id,
          card_id: this.card_id,
          period: periodLimit,
          merchants_info : [mccLimit]
        }
        this.allCardLimits = [... this.allCardLimits,  limitMCC]
      }
    }

    // Set limits for the first time
    if(this.editFeature === false) {
      for(let limit of this.allCardLimits) {
        this.cardService.setSpendLimit(limit).subscribe({
          next:(res) => {
          }})
      }
    } else {
      // Edit limits
      for(let limit of this.allCardLimits) {
        this.cardService.editSpendLimit(limit).subscribe({
          next:(res) => {
          }})
      }
    }
    this.dialog.closeAll()

    const currentUrl = '/main/cards';
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})

  }
//
  ngOnInit() {

    // Data Summary
      this.editFeature = this.data.isEdit
      this.limitsSummaryMCC = this.data.mccLimits
      this.limitsSummaryMCCCodes = this.data.mccCodes
      this.limitsSummaryMerch = this.data.merchLimits
      this.limitsSummaryGL = this.data.glLimits
      this.loan_id = this.data.loan_id
      this.card_id = this.data.card_id

  }
}
