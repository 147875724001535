import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';


import { AppComponent } from './app.component';
import { NavBarComponent } from './components/NavBar/nav-bar/nav-bar.component';
import { HomeComponent } from './components/Home/home/home.component';
import { AccountsComponent } from './components/Accounts/accounts/accounts.component'
import { CardsComponent } from './components/Cards/cards/cards.component';
import { TransactionsComponent } from './components/Transactions/transactions/transactions.component';

import { ProfileComponent } from './components/Profile/profile/profile.component';
import { NavButtonsComponent } from './components/NavBar/nav-buttons/nav-buttons.component';
import { RecipientsDisplayComponent } from './components/Recipients/recipients-display/recipients-display.component';
import { LoginComponent } from './components/Login/login/login.component';
import { TransactionsDisplayComponent } from './components/Transactions/transactions-display/transactions-display.component';
import { AccountsDisplayComponent } from './components/Accounts/accounts-display/accounts-display.component';
import { CardSelectedComponent } from './components/Cards/card-selected/card-selected.component';
import { CardSummaryComponent } from './components/Cards/card-summary/card-summary.component';
import { CouponsDiscountsComponent } from './components/CouponsDiscounts/coupons-discounts/coupons-discounts.component';
import { CouponsDiscountsInfoComponent } from './components/CouponsDiscounts/coupons-discounts-info/coupons-discounts-info.component';
import { CouponsDiscountsMsgComponent } from './components/CouponsDiscounts/coupons-discounts-msg/coupons-discounts-msg.component';
import { CouponsDiscountsUserComponent } from './components/CouponsDiscounts/coupons-discounts-user/coupons-discounts-user.component';
import { PayrollComponent } from './components/Payroll/payroll/payroll.component';
import { PaymentsTransfers } from "./components/Transfers/payments-tranfers/payments-transfers";
import { CreditScoreComponent } from './components/Accounts/credit-score/credit-score.component';
import { ProfileCreateComponent } from './components/Profile/profile-create/profile-create.component';
import { ModalComponent } from './components/Modals/modal/modal.component';

import { SignupComponent } from './components/Signup/signup/signup.component';
import { WelcomeComponent } from './components/Welcome/welcome/welcome.component';
import { ProfileEditComponent } from './components/Profile/profile-edit/profile-edit.component';
import { UserMenuComponent } from './components/NavBar/user-menu/user-menu.component';

import { LegalTermsComponent } from './components/Signup/legal-terms/legal-terms.component';
import { SuccessComponent } from './components/Profile/success/success.component';
import { ModalVerticalComponent } from './components/Modals/modal-vertical/modal-vertical.component';
import { PayrollUnenrollComponent } from './components/Payroll/payroll-unenroll/payroll-unenroll.component';
import { CardActivateComponent } from './components/Cards/card-activate/card-activate.component';
import { ModalCompleteComponent } from './components/Modals/modal-complete/modal-complete.component';
import { ModalMidComponent } from './components/Modals/modal-mid/modal-mid.component';
import { NgChartsModule} from "ng2-charts";
import { AccountInfoComponent } from './components/Accounts/account-info/account-info.component';
// import { TrDisputeComponent } from './components/Transactions/tr-dispute/tr-dispute.component';
// import { TrDConfirmComponent } from './components/Transactions/tr-d-confirm/tr-d-confirm.component';
import { CoupDiscSummaryComponent } from './components/CouponsDiscounts/coup-disc-summary/coup-disc-summary.component';
import { AccountsTrDisplayComponent } from './components/Accounts/accounts-tr-display/accounts-tr-display.component';
import { RecipientsComponent } from './components/Recipients/recipients/recipients.component';
import { RecipAddNewComponent } from './components/Recipients/recip-add-new/recip-add-new.component';
import { RecipModalComponent } from './components/Modals/recip-modal/recip-modal.component';
import { RecipientDetailsComponent } from './components/Recipients/recipient-details/recipient-details.component';
import { AccountExtAddComponent } from './components/Accounts/account-ext-add/account-ext-add.component';
import { CardAddNewComponent } from './components/Cards/CardNew/card-add-new/card-add-new.component';
import { CardModalAddComponent } from './components/Modals/card-modal-add/card-modal-add.component';
import { ProfileDisplayComponent } from './components/Profile/profile-display/profile-display.component';
import { AccReportBureauComponent } from './components/Accounts/acc-report-bureau/acc-report-bureau.component';
import { CardSpendLimitSetComponent } from './components/Cards/Limits/card-spend-limit-set/card-spend-limit-set.component';
import { HomeCarouselComponent } from './components/Home/home-carousel/home-carousel.component';

import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { PayrollEnrollComponent } from './components/Payroll/payroll-enroll/payroll-enroll.component';
import { CardsDisplayComponent } from './components/Cards/cards-display/cards-display.component';
import { NavBarHorizontalComponent } from './components/NavBar/nav-bar-horizontal/nav-bar-horizontal.component';
import { ModalLTransfersComponent } from './components/Modals/modal-l-transfers/modal-l-transfers.component';
import { AuthService } from "./services/auth.service";
import { AuthGuardGuard } from "./services/auth-guard.guard";
import { CanvasJSAngularChartsModule } from "@canvasjs/angular-charts";
import { AccountNewComponent } from './components/Accounts/account-new/account-new.component';
import { ModalNewAccComponent } from './components/Modals/modal-new-acc/modal-new-acc.component';
import { CardTrDisplayComponent } from './components/Cards/card-tr-display/card-tr-display.component';
import { SignupPhoneComponent } from './components/Signup/signup-phone/signup-phone.component';
import { ModalNoneComponent } from './components/Modals/modal-none/modal-none.component';
import { SpinnWheelComponent } from './components/Shared/spinn-wheel/spinn-wheel.component';
import { ChangePswComponent } from './components/Login/change-psw/change-psw.component';
import { VerifyEmailComponent } from './components/Home/verify-email/verify-email.component';
import { VerifyCompleteComponent } from './components/Home/verify-complete/verify-complete.component';
import { RouterModule } from '@angular/router';
import { ProfileEmailEditComponent } from './components/Profile/profile-email-edit/profile-email-edit.component';
import { ProfilePhoneEditComponent } from './components/Profile/profile-phone-edit/profile-phone-edit.component';
import { ProfilePhCodeEditComponent } from './components/Profile/profile-ph-code-edit/profile-ph-code-edit.component';
import { ProfileAddressEditComponent } from './components/Profile/profile-address-edit/profile-address-edit.component';

import { ProfileConfirmChangeComponent } from './components/Profile/profile-confirm-change/profile-confirm-change.component';
import { CardLimitSummaryComponent } from './components/Cards/Limits/card-limit-summary/card-limit-summary.component';
import { TransferLocalComponent } from "./components/Transfers/transfer-local/transfer-local.component";
import { RecPaymentsComponent } from './components/RecPayments/rec-payments/rec-payments.component';
import { RecPayDeleteComponent } from './components/RecPayments/rec-pay-delete/rec-pay-delete.component';

import {RequestsInterceptor} from "./interceptors/requests.interceptor";
import {ErrorHandlerInterceptor} from "./interceptors/error-handler.interceptor";

import { ErrorDialogComponent } from './components/Errors/error-dialog/error-dialog.component';
import {ErrorDialogMsgComponent} from "./components/Errors/error-dialog-msg/error-dialog-msg.component";
import { ModalMidErrorComponent } from './components/Modals/modal-mid-error/modal-mid-error.component';
import { CardAddNewButtonComponent } from './components/Cards/CardNew/card-add-new-button/card-add-new-button.component';
import { RecPayMonthComponent } from './components/RecPayments/rec-pay-month/rec-pay-month.component';
import { CouponsAddSuccessComponent } from './components/CouponsDiscounts/coupons-add-success/coupons-add-success.component';
import { CardsSuccessMsgComponent } from './components/Cards/cards-success-msg/cards-success-msg.component';
import { RecPayNewComponent } from './components/RecPayments/rec-pay-new/rec-pay-new.component';
import { RecipEditComponent } from './components/Recipients/recip-edit/recip-edit.component';
import { PayrollManageComponent } from './components/Payroll/payroll-manage/payroll-manage.component';
import { PayrollDisplayComponent } from './components/Payroll/payroll-display/payroll-display.component';
import { PayrollTransferFormComponent } from './components/Payroll/payroll-transfer-form/payroll-transfer-form.component';
import { AlertsComponent } from './components/MsgsDocs/Alerts/alerts/alerts.component';
import { AlertsDisplayComponent } from './components/MsgsDocs/Alerts/alerts-display/alerts-display.component';
import { AlertsDeleteComponent } from './components/MsgsDocs/Alerts/alerts-delete/alerts-delete.component';
import { ChangePswMsgComponent} from "./components/Login/change-psw-msg/change-psw-msg.component";
import { AlertsSocketComponent } from './components/MsgsDocs/Alerts/alerts-socket/alerts-socket.component';
import { HomeCashBackComponent } from './components/Home/home-cash-back/home-cash-back.component';
import { HomeCashBackAlertComponent } from './components/Home/home-cash-back-alert/home-cash-back-alert.component';
import { CardLimitGlComponent } from './components/Cards/Limits/card-limit-gl/card-limit-gl.component';
import { CardLimitMercComponent } from './components/Cards/Limits/card-limit-merc/card-limit-merc.component';
import { CardLimitMccComponent } from './components/Cards/Limits/card-limit-mcc/card-limit-mcc.component';

import { ModalCardLimitsComponent } from './components/Modals/modal-card-limits/modal-card-limits.component';
import { CardLimitEditComponent } from './components/Cards/Limits/EditLimits/card-limit-edit/card-limit-edit.component';
import { CardLSumEditComponent } from './components/Cards/Limits/EditLimits/card-l-sum-edit/card-l-sum-edit.component';
import {CardLMccEditComponent} from "./components/Cards/Limits/EditLimits/card-l-mcc-edit/card-l-mcc-edit.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { TrasnfersGreendotComponent } from './components/Transfers/trasnfers-greendot/trasnfers-greendot.component';
import { CardLimitMsgComponent } from './components/Cards/Limits/card-limit-msg/card-limit-msg.component';
import { CardLMerchEditComponent } from './components/Cards/Limits/EditLimits/card-l-merch-edit/card-l-merch-edit.component';

import { AccStatementsComponent } from './components/Accounts/acc-statements/acc-statements.component';
// import { TransctionsCreateComponent } from './components/Transactions/transctions-create/transctions-create.component';


import { MainResponsiveComponent } from './components/main-responsive/main-responsive.component';
import { LoginDeviceComponent } from './components/Login/login-device/login-device.component';
import { CardMoreOptionsComponent } from './components/Cards/card-more-options/card-more-options.component';
import { MsgsDocsComponent } from './components/MsgsDocs/msgs-docs/msgs-docs.component';
import { ProfileCreateSmallComponent } from './components/Profile/profile-create-small/profile-create-small.component';
import {CodeInputModule} from "angular-code-input";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import { SignupMainComponent } from './components/Signup/signup-main/signup-main.component';
import { TrDisputeMsgComponent } from './components/Transactions/tr-dispute-msg/tr-dispute-msg.component';



@NgModule({
  bootstrap: [AppComponent],

  declarations: [

    AppComponent,

    AccountInfoComponent,
    AccountsComponent,
    PaymentsTransfers,
    AccountNewComponent,
    AccountsDisplayComponent,
    AccountsTrDisplayComponent,
    AccountExtAddComponent,
    AccReportBureauComponent,

    CardsComponent,
    CardSelectedComponent,
    CardSummaryComponent,
    CardTrDisplayComponent,
    CardAddNewComponent,
    CardModalAddComponent,
    CardSpendLimitSetComponent,
    CardActivateComponent,
    CardsDisplayComponent,
    CardLMccEditComponent,

    CouponsDiscountsComponent,
    CouponsDiscountsInfoComponent,
    CouponsDiscountsMsgComponent,
    CouponsDiscountsUserComponent,
    CoupDiscSummaryComponent,

    CreditScoreComponent,

    ChangePswComponent,

    ErrorDialogMsgComponent,
    HomeCarouselComponent,
    HomeComponent,

    LoginComponent,
    LegalTermsComponent,

    ModalVerticalComponent,
    ModalLTransfersComponent,
    ModalNoneComponent,
    ModalNewAccComponent,
    ModalComponent,
    ModalCompleteComponent,
    ModalMidComponent,

    NavBarComponent,
    NavButtonsComponent,
    NavBarHorizontalComponent,

    PayrollComponent,
    PayrollEnrollComponent,
    PayrollUnenrollComponent,

    ProfileComponent,
    ProfileCreateComponent,
    ProfileEmailEditComponent,
    ProfilePhoneEditComponent,
    ProfilePhCodeEditComponent,
    ProfileAddressEditComponent,
    ProfileEditComponent,
    ProfileDisplayComponent,
    ProfileConfirmChangeComponent,

    RecipientsComponent,
    RecipAddNewComponent,
    RecipModalComponent,
    RecipientDetailsComponent,
    RecipientsDisplayComponent,

    TransactionsComponent,

    TransferLocalComponent,

    // TrDisputeComponent,
    // TrDConfirmComponent,
    TransactionsDisplayComponent,

    UserMenuComponent,

    VerifyEmailComponent,
    VerifyCompleteComponent,

    SuccessComponent,

    SignupComponent,
    SignupPhoneComponent,

    SpinnWheelComponent,

    WelcomeComponent,

    CardLimitSummaryComponent,
    RecPaymentsComponent,
    RecPayDeleteComponent,
    ErrorDialogComponent,
    ModalMidErrorComponent,
    CardAddNewButtonComponent,
    RecPayMonthComponent,
    CouponsAddSuccessComponent,
    CardsSuccessMsgComponent,
    RecPayNewComponent,
    RecipEditComponent,
    PayrollManageComponent,
    PayrollDisplayComponent,
    PayrollTransferFormComponent,
    AlertsComponent,
    AlertsDisplayComponent,
    AlertsDeleteComponent,
    AlertsSocketComponent,
    ChangePswMsgComponent,
    AlertsSocketComponent,
    HomeCashBackComponent,
    HomeCashBackAlertComponent,
    CardLimitGlComponent,
    CardLimitMercComponent,
    CardLimitMccComponent,

     ModalCardLimitsComponent,
     CardLimitEditComponent,
     CardLSumEditComponent,
     TrasnfersGreendotComponent,
     CardLimitMsgComponent,
     CardLMerchEditComponent,
     AccStatementsComponent,
     // TransctionsCreateComponent,


     MainResponsiveComponent,
     LoginDeviceComponent,
     CardMoreOptionsComponent,
     MsgsDocsComponent,
     ProfileCreateSmallComponent,
     SignupMainComponent,
     TrDisputeMsgComponent,

  ],

  imports: [

    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CanvasJSAngularChartsModule,
    FormsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMomentDateModule,
    MatNativeDateModule,
    NgChartsModule,
    NgbModule,
    RouterModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    CodeInputModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,

  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    AuthService,
    AuthGuardGuard,
  ]
})
export class AppModule { }
