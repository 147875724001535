import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignupService} from "../../../services/signup.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {Router} from "@angular/router";
import {SharedataService} from "../../../services/sharedata.service";
import {fromEvent, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-signup-phone',
  templateUrl: './signup-phone.component.html',
  styleUrls: ['./signup-phone.component.css']
})
export class SignupPhoneComponent implements OnInit{

  userId !: string
  @Input() phone_missing !: string
  @Input() phone_id_missing !: string

  @Output() phoneIsChecked = new  EventEmitter<boolean>()

  fieldsConfig !: FormFieldConfig[]

  constructor(
    private SignUpService : SignupService,
    private router: Router,
    private SharedDataService : SharedataService
  ) { }

  agreeNotifications :boolean = false
  signUpStage :number = 2
  verifyCode !:string
  savePhoneId !: string
  checkCode : boolean = false
  validateOn: string = 'change'
  fieldWasEdited: any = {}
  validationErrors : any = {}

  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  // Handle input values
  updateFieldValue(fieldName: string, newValue: string) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  createPhoneNumberFc():void{

    this.fieldValues = {...this.fieldValues, user_id: this.userId }

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0 || !this.agreeNotifications) {
      return
    }

    // Post form to signUp service
    this.SignUpService.createPhone(this.fieldValues).subscribe((res) => {
        if(res.e_code ==1 ){
          this.savePhoneId = res.phone_id
          this.SharedDataService.setPhoneNumberId(this.savePhoneId)
          this.SharedDataService.setPhoneNumber(this.fieldValues['phone'])
          this.checkCode = true
          this.sendCodeAgain()
        }
      })
  }

  activatePhoneFc():void{
    let payload =  {
      phone_id: this.savePhoneId,
      code : parseInt(this.verifyCode)
    }

    this.SignUpService.activatePhone(payload).subscribe({ next:(res)=>{
          if (res.e_code===1){
            this.phoneIsChecked.emit(true)
            this.router.navigateByUrl('/profile')
          } else {
            this.SignUpService.sendVerifyCode({phone_id:this.savePhoneId})
          }
        }})

  }

  sendCodeAgain():void{
    this.onCodeCompleted("")

    this.SignUpService.sendVerifyCode({phone_id:this.savePhoneId}).subscribe({
      next:(res)=>{ }
    })
}

  onCodeCompleted(code: string) {
    this.verifyCode = code
  }

  acceptMessages():void{
    this.agreeNotifications = !this.agreeNotifications
  }


  private unsubscriber: Subject<void> = new Subject<void>();
  preventBrowsingHistory():void{
    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
      });
  }
  ngOnInit() {

    this.preventBrowsingHistory()

    // Get user Id
    this.SharedDataService.getUserId().subscribe(value=> {
      this.userId = value
    })

    this.fieldsConfig = this.SignUpService.fieldsConfigPhone
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''


    // Signup state

    this.SharedDataService.getSignUpState().subscribe((value)=> {
        this.signUpStage = value })

    if(this.signUpStage == 3) {
      this.checkCode = true
      // Retrieve phone data
      this.SharedDataService.getPhoneNumber().subscribe((valueNum)=>{
        this.fieldValues['phone'] = valueNum
      })
      this.SharedDataService.getPhoneNumberId().subscribe((valueId)=>{
        this.savePhoneId = valueId
      })

    }
    else if (this.signUpStage == 2) { this.checkCode = false }


  }


}
