import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-l-transfers',
  templateUrl: './modal-l-transfers.component.html',
  styleUrls: ['./modal-l-transfers.component.css']
})
export class ModalLTransfersComponent {

}
