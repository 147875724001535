import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";

export interface AlertsRead{
  notifications: string[]
}

export interface AlertsDelete{
  notifications: string[]
}

export interface Alert {
  id: string,
  tittle: string,
  type: number,
  message: string,
  link  :string | null,
  additional_info: {
    status: string
  },
  dt_when?: string,
  read:number,
}

export interface AlertSocket extends Alert{

}
@Injectable({
  providedIn: 'root'
})

export class AlertsService {


  constructor(
    private api: ApiService
  ) { }

  getAlerts():Observable<any>{
    return  this.api.get('/get_notifications/')
  }
  readAlerts(data:AlertsRead):Observable<any>{
    return  this.api.post('/read_notifications/', data)
  }
  deleteAlerts(data:AlertsDelete):Observable<any>{
    return  this.api.post('/delete_notifications/', data)
  }
}
