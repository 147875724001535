<div class="container-fluid h-100">

          <!-- First narrow bar:  -->
          <div class="row  px-3" >
            <div class=" col-12 p-0 my-lg-0 my-2">
              <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0">
                <div class="card-body" >

<!--                  <div class="row ">-->
<!--                    <p class="text-kredio-hint color-kredio ms-2">Alerts & Notifications: Last 30 days</p>-->
<!--                  </div>-->

                  <div class="row">
                    <div class=" col-xl-5 col-12 my-2">
                      <div class="form-floating w-100"  >
<!--                        ngDefaultControl-->
                        <input
                          [id]="'inputFilter'"
                          [class] ="'form-control'"
                          [placeholder]="'Search'"
                          [type]="'string'"
                          [ngModel]="search_word"
                          (ngModelChange)="filterAlert($event)"
                        >

                        <label class="form-label ms-2">Search</label>
                      </div>
                    </div>
                    <div class="col-xl-7 col-12">
                      <div class="row">
                        <div class=" col-lg-3 col-6 d-flex justify-content-center my-2 px-1 ">
                          <button class="text-center py-3 w-100  rounded-4 p-filter" id="btn_filter"> 
                            <img src="../../../../../assets/images/recipients/filter_recp_white.svg">
                            Filters
                          </button>
                        </div>
                        <div class=" col-lg-3 col-6 d-flex justify-content-center my-2 px-1 ">
                          <button class="text-center py-3 w-100  rounded-4 p-filter" (click)="selectAll()"
                          id="btn_select">
                            <img src="../../../../../assets/images/alerts/ok_selec_all.svg">
                            Select All
                          </button>
                        </div>
                        <div class=" col-lg-3 col-6 d-flex justify-content-center my-2 px-1 ">
                          <button class="text-center py-3 w-100  rounded-4 p-filter" (click)="deleteAll()"
                          id="btn_delete">
                            <img src="../../../../../assets/images/alerts/delete_btn.svg"> Delete
                          </button>
                        </div>
                        <div class=" col-lg-3 col-6 d-flex justify-content-center my-2 px-1 ">
                          <button class="text-center py-3 w-100  rounded-4 p-filter" (click)="markAsRead()"
                          id="btn_read">
                            <img src="../../../../../assets/images/alerts/mark_read.svg">
                            Mark Read
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="row my-2">

                    <div id="scollAlerts" [ngClass]=" buttonName === 'See Alerts' ? '' : 'scroll-alerts' ">

                      <!-- Responsive html format: large screens  d-none d-sm-inline -->
                      <div class="d-none d-lg-block">
                        <table class="table">
                          <tr  >
                            <th  class="w-50" >Alerts</th>
                            <th class="text-center" >Date</th>
                            <th class="text-center">Status</th>
                            <th></th>
                            <th class="text-center">Delete</th>
                          </tr>
                          <tbody *ngFor="let al of alerts ; index as idx" id="alertsDisplay">
                          <tr [id]="idx.toString()+'_alert'"  >
                            <td class="align-content-center pt-1">
                              <span [id]="idx.toString()" class="btnSelect"
                                    (click)="selectAlert(al.id , idx)"></span>
                              <span id="al_title" class="td-name ms-2 ps-2">{{al.tittle}}</span>
                            </td>
                            <td class="text-center align-content-center">
                              <p id="al_when" class="td-title mb-0 ">{{al.dt_when}}</p>
                            </td>
                            <td class="align-content-center text-center">
                              <p *ngIf="al.read==1" class="mb-0 p-read ">READ</p>
                              <p *ngIf="al.read==0" class="mb-0 p-not-read ">NOT READ</p>
                            </td>
                            <td class="align-content-center text-center button-pointer" (click)="readAlert(al)">
                                <img id="imgRead" *ngIf="al.read==1"  src="../../../../../assets/images/alerts/read_icon.svg">
                                <img id="imgNotRead" *ngIf="al.read==0" src="../../../../../assets/images/alerts/icon_not_read.svg">
                            </td>
                            <td class="align-content-center text-center">
                              <button id="btnTrash" type="button" class="btn btn-default btn-sm button-pointer" (click)="showDeleteAlert(al.id)">
                                <img id="imgTrash" src="../../../../../assets/images/alerts/trash_button.svg">
                              </button>
                            </td>
                          </tr>
                          </tbody>

                        </table>
                      </div>

                      <!-- Responsive html format: smaller screens-->
                      <div  class=" d-lg-none" >
                        <div *ngFor="let alSmall of alerts ; index as idx" class="mt-2 mb-3">

                          <div class="row align-content-center" [id]="idx.toString()+'_alert_sm'">
                            <div class="col-6 align-content-center">
                              <div class="text-center ">
                                <span [id]="idx.toString()+'_sm'" class="btnSelect"
                                      (click)="selectAlert(alSmall.id , idx)"></span>
                                <span class="td-name-small mb-0 mt-1 ms-2 ps-2">{{alSmall.tittle}}</span>
                              </div>
                            </div>
                            <div class="col-4  align-content-center">
                              <div class=" d-flex align-items-center justify-content-center h-100">
                                <p class="td-title mb-0 ">{{alSmall.dt_when}}</p>
                              </div>
                            </div>
                            <div class="col-2 align-content-center " >
                              <div  (click)="readAlert(alSmall)" class="button-pointer" >
                                <img *ngIf="alSmall.read==1"  src="../../../../../assets/images/alerts/read_icon.svg">
                                <img *ngIf="alSmall.read==0"
                                     src="../../../../../assets/images/alerts/icon_not_read.svg" >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="pt-2  border-top-solid text-center">
                      <button class="button-toggle mb-0 button-pointer " (click)="seeAllAlerts()" >{{buttonName}}</button>
                    </div>

                </div>

              </div> <!--Close card body-->
              </div>  <!--Close card -->
            </div> <!--Close col-->
          </div>  <!--Close row-->

          <div class="d-lg-none d-block" style="height: 5rem;"></div>



      <div *ngIf="read_alert">
        <app-modal-vertical>
          <app-alerts-display
            [picked_alert]= picked_alert
          ></app-alerts-display>
        </app-modal-vertical>
      </div>

</div> <!--Close container -->
