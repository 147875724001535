import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  bankAccountValidator,
  isNotEmptyValidator,
  usRoutingNumberValidator
} from "../components/Shared/forms-shared/validators";

export interface Transfer {
  id: number;
  dtWhen: string;
  amount: number;
  description: string;
  accountId: number;
}

export interface MainAccount {
  id: string,
  number: number,
  routing: string,
  balance: string,
  monthy_spending: string,
  monthly_income:string,
  dda_status: string,
  owner: string,
  is_primary: number,
  pri_account_id: string|null,
  is_email_verified: number,
  email: string
}

export interface Account {
  id: string,
  number: number ,
  routing : string,
  balance : string,
  monthy_spending: string,
  monthly_income: string,
  dda_status: string,
  owner: string,
  is_primary: number,
  pri_account_id : string|null,
  type: string,
}

export interface ExtAccount {
  primary_loan_id:string,
  typ: number,
  routingNo:string,
  accountNo :number
}

export interface AccountStatement {
  loan_id: string,
  month: number ,
  year: number ,
}

export interface AddNewAccount {
  primary_loan_id: string
}
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  formFields: FormFieldConfig[] = [
    {
      name: 'primary_loan_id',
      displayName: 'primary_loan_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'typ',
      displayName: 'typ',
      type: 'number',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'accountNo',
      displayName: 'Account Number',
      type: 'string',
      placeHolder: 'Account Number',
      validators: [isNotEmptyValidator, bankAccountValidator]
    },
    {
      name: 'routingNo',
      displayName: 'Routing Number',
      type: 'string',
      placeHolder: 'Routing Number',
      validators: [isNotEmptyValidator, usRoutingNumberValidator]
    },
    {
      name: 'routingNo',
      displayName: 'Routing Number',
      type: 'string',
      placeHolder: 'Routing Number',
      validators: [isNotEmptyValidator, usRoutingNumberValidator]
    },

]

  formFieldsAccStatement: FormFieldConfig[] = [
    {
      name: 'loan_id',
      displayName: 'Account',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'month',
      displayName: 'Month',
      type: 'number',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'year',
      displayName: 'Year',
      type: 'number',
      validators: [isNotEmptyValidator]
    },
  ]


  constructor(
    private api: ApiService
  ) {}


  createExternalAccount(data:ExtAccount):Observable<any>{
    return this.api.post('/add_ach_account/',data)
  }

  getAccounts():Observable<any> {
    return this.api.get('/accounts/')
  }

  getMainAccount() :Observable<any>{
    return this.api.get('/main_account/')
  }

  addNewAccount(data:AddNewAccount):Observable<any> {
    return this.api.post('/create_account/',data)
  }

  getAccountStatement(data:AccountStatement):Observable<any> {
    return this.api.post('/get_account_statements/',data)
  }

}


