<app-modal-none>
  <div class="container-fluid">
    <div class="row my-5">
      <div class="w-100 d-flex justify-content-center align-items-center">
       <img src="assets/images/signup/spinn_logo.svg">
      </div>
    </div>
    <div class="row">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border p-spinner" role="status"></div>
      </div>
    </div>
    <div *ngIf="message" class="row text-center my-3">
      <p class="p-message my-3">{{message}}</p>
    </div>
  </div>
</app-modal-none>
