<!--  Horizontal Menu Small Screens -->
<div class="container-fluid bg-white fixed-bottom ">
  <div>
    <div class="navbar text-center py-2">
      <a  class="ms-0 ms-md-3 button-pointer">
        <img id="img_home"  src="assets/images/nav-bar/home_icon.svg" routerLink="/main/home" alt="Home">
      </a>

      <a class="button-pointer" >
        <img id="img_acc" src="assets/images/nav-bar/accounts_icon.svg" routerLink="/main/accounts" alt="Accounts">
      </a>

      <a class="button-pointer">
        <img id="img_cards" src="assets/images/nav-bar/cards_icon.svg" routerLink="/main/cards" alt="Cards">
      </a>

      <a  class="button-pointer">
        <img id="img_tr" src="assets/images/nav-bar/transactions_icon.svg" routerLink="/main/transactions" alt="Transactions">
      </a>

      <a  class="me-0 me-md-3 button-pointer">
        <img id="img_tf" src="assets/images/nav-bar/transfer_icon.svg" routerLink="/main/paymentsTransfers" alt="transfer">
      </a>
    </div>
  </div>
</div>
