<div class="container-fluid h-75 d-flex flex-column justify-content-evenly " >

      <div id="0" class="row  px-0 my-1  " *ngFor="let rec of recipients_display ">

          <div class="col-7 px-0 " >
              <p id="fullName" class="fr-data mx-0 my-0 " >{{rec.first_name}} {{rec.last_name}}</p>
              <p id="email" class="fr-source m-0" >{{rec.email.toString().split('@')[0]}}</p>
          </div>

          <div class="col-3 fr-button d-flex justify-content-center align-items-center  "  >
            <div class="text-center button-pointer " >
              <div id="routLinkTransfer" routerLink="/main/paymentsTransfers" >
                <img src="assets/images/recipients/transfer_icon.svg"
                     class="img-fluid" routerLink="/main/paymentsTransfers"
                     alt="Transfer">
              </div>
              <p class="p-button mb-0" >Transfer</p>
            </div>
          </div>

          <div class="col-2 fr-button d-flex justify-content-center align-items-center">
            <div class="text-center button-pointer" >
              <div id="routLinkMore" routerLink="/main/recipients"  >
                <img src="assets/images/recipients/more_icon.svg" routerLink="/main/recipients"
                     class="img-fluid"
                     alt="More Options">
              </div>
              <p class="p-button mb-0">More</p>
            </div>
          </div>

      </div>  <!--Close row-->

</div>  <!--Close container-->




