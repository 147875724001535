import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HomeComponent} from "../../Home/home/home.component";
import {AccountsComponent} from "../../Accounts/accounts/accounts.component";
import {window} from "rxjs";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {

}
