<div class="container-fluid" >
  <div class="row my-5 text-center">
    <div>
      <img src="../../../../../assets/images/alerts/warning_triangle.svg" class="img-fluid">
    </div>
  </div>
  <div class="row text-center my-2">
    <p id="pInputMsg" class="p-title">{{message}}</p>
  </div>
  <div class="row text-center my-2">
    <p id="pMsg" class="p-text fw-bold my-2">Close this message and continue banking with Kredio.</p>
  </div>
  <div class="row mb-3">
    <div class=" col-lg-8 offset-lg-2  col-12 text-center">
      <button class="violet-button py-3 rounded-3 w-100 px-2" (click)="closeMessage()"> Close </button>
    </div>
  </div>
</div>
