import {Component, Inject, Input, OnInit} from '@angular/core';
import {RecPayment, RecpaymentsService, RecurrentItem} from "../../../services/recpayments.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-rec-pay-delete',
  templateUrl: './rec-pay-delete.component.html',
  styleUrls: ['./rec-pay-delete.component.css']
})
export class RecPayDeleteComponent implements OnInit{

  @Input() paymentData !:RecPayment
  @Input() paymentDataCancel !: RecurrentItem
  @Input() deleteAllPayments !: boolean


  sender_name !: string
  sender_route_number !: string
  receiver_name !:string
  network!:string
  amount!:string
  payment_id !:string

  constructor(
    private router: Router,
    private recPaymentsService : RecpaymentsService,
    public dialogRef: MatDialogRef<RecPayDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  deletePayment():void {


    // Delete all net al future payments
    if(this.deleteAllPayments ===true){
      let payload = {r_payment_id:this.payment_id}

      this.recPaymentsService.deleteRecPayments(payload).subscribe({
          next:(res)=>{
            this.dialogRef.close()
             const currentUrl = this.router.url;
             this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
               this.router.navigate([currentUrl]); })
          }
        })
    }

    // Only delete selected payment
    else {
      let payload = {
        loan_id: this.paymentData.loan_id,
        r_payment_id : this.paymentData.r_payment_id,
        lead_id:this.paymentData.lead_id
      }
      this.recPaymentsService.cancelPayment(payload).subscribe({
          next:(res)=>{
            this.dialogRef.close()
            if (res.e_code === 1) {
              const currentUrl =this.router.url;
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate([currentUrl]) })
            }}
        })
    }
  }

  cancelDeleteRecPayment ():void {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.deleteAllPayments = this.data.isDeleteAll

    if(this.deleteAllPayments ===true){
      this.paymentDataCancel = this.data.deleteData
      this.amount = this.paymentDataCancel.amount
      this.network = this.paymentDataCancel.network
      this.receiver_name = this.paymentDataCancel.recipient
      this.payment_id = this.paymentDataCancel.r_payment_id
      this.sender_name = this.paymentDataCancel.number.toString()
    }
    else {
      this.paymentData = this.data.deleteData
      this.amount = this.paymentData.amount
      this.network = this.paymentData.network
      this.receiver_name = this.paymentData.recipient
      this.sender_route_number = this.paymentData.routing
      this.payment_id = this.paymentData.r_payment_id
      this.sender_name = this.paymentData.account_number.toString()
    }
  }
}
