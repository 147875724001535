import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {emailValidator, isNotEmptyValidator, usaPhoneValidator} from "../../Shared/forms-shared/validators";
import {AuthService} from "../../../services/auth.service";
import {AgreeemailService} from "../../../services/agreeemail.service";

@Component({
  selector: 'app-profile-email-edit',
  templateUrl: './profile-email-edit.component.html',
  styleUrls: ['./profile-email-edit.component.css']
})
export class ProfileEmailEditComponent implements OnInit{


  // New email: new email_id

  emailId !:string
  // Confirm changes modal
  confirmChangesModal !:boolean
  successEmail : boolean = false
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private agreeEmailService : AgreeemailService
  ) { }

// Display current phone number and edit
  profileData !:ProfileEdit
  fieldsConfig :FormFieldConfig[] = [
    {
      name: 'email',
      displayName:'Email',
      type: 'string',
      validators : [isNotEmptyValidator,emailValidator],
    },
  ]

  validateOn:string = 'change'
  validationErrors : any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues = newFieldValues
    if (this.validateOn == 'change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  // Cancel Edit Email
  cancelEditEmail():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  confirmChanges():void{
    this.confirmChangesModal = true
  }

  agreeEmailFunction(id: string){
    this.agreeEmailService.sendVerifyEmailId({'email_id': id }).subscribe(
      {next: (res)=> {
        if(res.e_code == 1 ) { // Verification email successful
          this.successEmail = true
        }
      }})
  }

  submitNewEmail(data:boolean):void {

    this.confirmChangesModal = false
    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0)
      return

    // Get new emailId from new email
    this.profileService.updateUserEmail(this.fieldValues).subscribe({next:(res) =>{
        if(res.e_code == 1){
          this.emailId = res.email_id

          // Send verification email to user using new emailId
          this.agreeEmailFunction(this.emailId)
        }
        //this.authService.logout()
        setTimeout(()=>{ this.authService.logout() }, 2000)
      }
    });


  }

  ngOnInit() {
    this.profileService.getUserInfo().subscribe({next:(res) =>{ // CHECK
        if(res.e_code == 1){
          this.profileData = res.res
          this.fieldValues['email'] = this.profileData['email']
        }}
    });
  }

}
