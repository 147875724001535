import {Component, OnInit} from '@angular/core';
import {HomeService} from "../../../services/home.service";
import {Router} from "@angular/router";
import {Discount, DiscountService} from "../../../services/discount.service";

@Component({
  selector: 'app-coupons-discounts-user',
  templateUrl: './coupons-discounts-user.component.html',
  styleUrls: ['./coupons-discounts-user.component.css']
})
export class CouponsDiscountsUserComponent implements OnInit{

  CsAndDs !: Discount[]
  displayDescBoolean: Boolean = false

  constructor(
    private homeService: HomeService,
    private discountService: DiscountService,
  ) {
  }

  displayDescription(cd:Discount): void {
    this.displayDescBoolean = ! this.displayDescBoolean
    this.CsAndDs = [cd]
  }

  seeAllDiscounts(): void {
    this.displayDescBoolean = false
    this.discountService.getActiveDiscounts().subscribe({
      next:(res) => { this.CsAndDs = res.ret }});
  }

  ngOnInit(): void {
    this.discountService.getActiveDiscounts().subscribe({
      next:(res) => { this.CsAndDs = res.ret  }})
  }
}
