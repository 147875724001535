<div class="container-fluid h-100">

      <app-nav-buttons
        [current_route]= your_route
      ></app-nav-buttons>


      <!-- First narrow bar: Account, Cash Back, Live Payroll -->

      <div class="mx-md-3 mx-sm-1 mb-3"> <!--Second row-->
                <app-coup-disc-summary
                  (newOption)="changeOption($event)"
                  [clickedOption]="selected_option"
                ></app-coup-disc-summary>
      </div>

      <!-- DisplayDescInfo == true-->
      <div *ngIf="displayDescInfo">
        <div class="p-lg-3 p-2 mx-md-3 mx-sm-1 mx-0 rounded-4 bg-white" >

          <div class="row">
            <div class="col-12 col-lg-2 text-center p-3 d-flex justify-content-center">
                <div class="rounded-4 shadow p-1  w-75 p-2" >
                  <div class="text-center img-fluid mb-0">
                    <img src="assets/images/coupons/logo_here.svg" >
                  </div>
                  <p id="displayMerchant" class="p-merchant-solo m-0">{{discountInfo.merchant_name}}</p>
                  <p *ngIf="discountInfo.type==1" class="p-reward m-0">USD <strong>{{discountInfo.amount}}</strong> BACK</p>
                  <p id="displayPerc" *ngIf="discountInfo.type==0" class="p-reward m-0 "><strong>{{discountInfo.percentage |percent :'.2' }}
                  </strong> BACK</p>
                  <p class="p-reward m-0">EXP DATE: </p>
                  <p id="displayED" class="p-reward m-0"><strong>{{discountInfo.exp_date}}</strong></p>
                </div> <!--close card body-->
            </div> <!--close col2-->

            <div class="col-12 col-lg-9 p-3">
              <p class="p-merchant my-2">{{discountInfo.merchant_name}}</p>
              <p *ngIf="discountInfo.type==1" class="p-reward fw-bold mt-0">USD {{discountInfo.amount}}
                BACK - EXP DATE: {{discountInfo.exp_date}} </p>
              <p *ngIf="discountInfo.type==0" class="p-reward fw-bold mt-0">{{discountInfo.percentage |percent :'.2' }}
                BACK - EXP DATE: {{discountInfo.exp_date}} </p>

              <div>
                <p class="p-reward-sm">{{discountInfo.description}}</p>
              </div>

            </div>
          </div>

          <div class="row my-xs-1 my-md-2 my-3 " >

            <div class="col-12 my-2 col-md-3 offset-md-3" >
              <button id="btnActivate" class="violet-button  rounded-4 py-3 w-100"
                      (click)="activateCoupon(discountInfo.id)"
              >Activate</button>
            </div>

            <div class="col-12 my-2 col-md-3 ">
              <button id="btnAllCoupons" class="yellow-button   rounded-4 py-3 w-100"
                      (click)="seeAllDiscounts()">Back to all coupons
              </button>
            </div>

          </div> <!--Close row-->


          <div class="row">
            <div class="col-6 offset-3 mt-2">
              <div class="alert alert-warning alert-dismissible mt-1" role="alert" *ngIf="activate_coupon== true">
                {{this.activateMessage}}
                <button class="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- style="width: var(--discount-card-size);)-->
      <div *ngIf="!displayDescInfo">
        <div class="row mx-3">

          <div  *ngIf="CsAndDs" class="d-flex flex-wrap justify-content-start scroll-discounts ">
            <div id="listDiscounts " *ngFor="let cd of CsAndDs">
              <div id="btnSelected" class="m-2 button-pointer"  (click)="goToSelectedDiscounts(cd)" >
                <div class="text-center rounded-4 shadow p-1 bg-white">
                  <div class="text-center"><img src="assets/images/coupons/logo_here.svg" class="img-fluid"></div>
                  <p id="mechName" class="p-merchant  ">{{cd.merchant_name}}</p>
                  <p id="mechAmount" *ngIf="cd.type==1" class="p-reward ">USD<strong>{{cd.amount}}</strong> BACK</p>
                  <p *ngIf="cd.type==0" class="p-reward "><strong>{{cd.percentage |percent :'.2' }}</strong> BACK</p>
                  <p  id="mechExpDate" class="p-reward">DUE: <strong>{{cd.exp_date}}</strong> </p>
                </div> <!--close -->
              </div><!--close discount card-->
            </div> <!--close ngfor-->

          </div> <!--close ngif-->

        </div> <!--close row-->
      </div>  <!-- close ngIf !displayDescInfo-->

      <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>



  <div *ngIf="activate_coupon==true">
    <app-modal-mid>
      <app-coupons-add-success
        [activateMsg]="activateMessage"
      ></app-coupons-add-success>
    </app-modal-mid>
  </div>

</div>  <!--Close container-->




