import {Component, Inject, OnInit} from '@angular/core';
import {CardCancel, CardService} from "../../../services/card.service";
import {CardsSuccessMsgComponent} from "../cards-success-msg/cards-success-msg.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-card-more-options',
  templateUrl: './card-more-options.component.html',
  styleUrls: ['./card-more-options.component.css']
})
export class CardMoreOptionsComponent implements OnInit{

  loadId !:string
  cardId !:string
  isPrimary !:boolean
  cardType !:string
  cancelCardSelected :boolean = false

  confirmSelection :boolean = false

  constructor(

    public dialogRef: MatDialogRef<CardMoreOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cardService: CardService,
    public dialog : MatDialog
  ) { }


  cancel():void{
   this.dialogRef.close()
  }

  cancelCardOption():void {
    this.confirmSelection = true
    this.cancelCardSelected = true
  }

  removeCard():void {

   this.dialogRef.close()

    let payload:CardCancel = { loan_id: this.loadId, card_id: this.cardId}

    this.cardService.cancelCard(payload).subscribe({next:(res)=>{
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { title: "Delete Card" , msg: "Your card has beed deleted" }
      dialogConfig.panelClass = 'full-30-dialog';
      this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
      }})
  }

  ngOnInit() {
    this.cardId = this.data.card_id
    this.loadId = this.data.loan_id
    this.isPrimary = this.data.is_primary
    this.cardType = this.data.card_type

  }

}
