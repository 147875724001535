import {Component, OnInit} from '@angular/core';
import {distinctUntilChanged, tap} from "rxjs";
import {BreakpointObserver , Breakpoints} from "@angular/cdk/layout";
import {DeviceDetectorService} from "ngx-device-detector";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit{

  displayLarge !:boolean
  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.displayLarge = !this.deviceService.isMobile();
  }

}
