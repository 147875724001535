import { Component } from '@angular/core';

@Component({
  selector: 'app-card-modal-add',
  templateUrl: './card-modal-add.component.html',
  styleUrls: ['./card-modal-add.component.css']
})
export class CardModalAddComponent {

}
