import {Component, OnInit} from '@angular/core';
import {PayrollService} from "../../../services/payroll.service";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
declare const Argyle: any;

@Component({
  selector: 'app-payroll-enroll',
  templateUrl: './payroll-enroll.component.html',
  styleUrls: ['./payroll-enroll.component.css']
})
export class PayrollEnrollComponent implements OnInit{

  payroll_user_id !:string
  // new_linkKey :string = environment.ARGYLE_LINKKEY
  // new_userToken !:string
  constructor(
    private payrollService: PayrollService,
    private router : Router,
    private authService: AuthService
  ) {
  }

  accessArgyle(data:string):void{
  const linkInstance = Argyle.create({
    linkKey: environment.ARGYLE_LINKKEY,
    userToken:  data,
    sandbox: true, // Set to false for production environment.
    flowId: 'VKDF86WX',
    // (Optional) Add a Link flow customization created in Console:
    //    flowId: '<ID of the Link flow>',
    // (Optional) Add a deposit switch flow:
    //    ddsConfig: '<Encrypted target deposit destination value>',
    ddsConfig: "",
    // (Optional) Limit Link search to specific Items:
    //    items: ['item_000001422', 'item_000025742'],
    // (Optional) Connect directly to an existing account:
    //    accountId: '<ID of the account>', // ID of a disconnected account
    // (Optional) A few recommended callbacks:
    onAccountConnected: (payload:any) => {
      //console.log('onAccountConnected')

      let payloadData = {
        event: "accounts.added",
        name: "name-for-the-webhook-subscription",
        data: {account: payload.accountId, user: this.payroll_user_id}
      }
      // this.router.navigateByUrl('/home')
      this.payrollService.sendPayrollData(payloadData).subscribe({
        next:(res)=>{
          if (res.e_code === 1){
              this.router.navigateByUrl('/main/home')
          }
        }
      })
    }
    ,
    onAccountError: (payload:any)=> {
      linkInstance.close()
      this.authService.logout()
      sessionStorage.clear();
      this.router.navigateByUrl('login')
    },
    onDDSSuccess: (payload:any) => {
      //console.log('onDDSSuccess', payload)
      this.router.navigateByUrl('main/home')
    },
    onDDSError: (payload:any) => {
      //console.log('onDDSError', payload)
      linkInstance.close()
      this.authService.logout()
      sessionStorage.clear();
      this.router.navigateByUrl('login')
    },
    onTokenExpired: (updateToken:any) => {
      //console.log('onTokenExpired')
      linkInstance.close()
      this.authService.logout()
      sessionStorage.clear();
      this.router.navigateByUrl('login')
    }
  })
  linkInstance.open()
}
  ngOnInit() {
    this.payrollService.getPayrollUser().subscribe({ next:(res)=>{
        if(res.e_code ===1){
          this.payroll_user_id = res.res.payroll_user_id
          this.accessArgyle(res.res.payroll_user_token)
        }}
    })
  }

}
