import {Component, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-tr-dispute-msg',
  templateUrl: './tr-dispute-msg.component.html',
  styleUrls: ['./tr-dispute-msg.component.css']
})
export class TrDisputeMsgComponent {

  title !: string
  message !:string
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<TrDisputeMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  closeCardsMsg():void{
    this.dialogRef.close()
    const currentUrl =  this.router.url
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})
  }

  ngOnInit() {
    this.title = this.data.title
    this.message = this.data.msg
  }


}
