import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ErrorProfile, ProfileService} from "../../../services/profile.service";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {SignupService} from "../../../services/signup.service";
import {SharedataService} from "../../../services/sharedata.service";
import {Router} from "@angular/router";

import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {ErrorDialogMsgComponent} from "../../Errors/error-dialog-msg/error-dialog-msg.component";

@Component({
  selector: 'app-profile-create-small',
  templateUrl: './profile-create-small.component.html',
  styleUrls: ['./profile-create-small.component.css']
})
export class ProfileCreateSmallComponent implements OnInit{

  // Views
  enterLegalName : boolean = true
  enterDOB !:boolean
  enterAddress !:boolean
  enterIDDocsData !: boolean
  enterSSNITIN !: boolean
  enterAccountActivity !: boolean
  confirmProfileData !:boolean
  currentView: string = 'fullName'

  @Input() validateOn: string = 'change'
  @Output() submit = new  EventEmitter<any>()
  @ViewChild('overlay') overlay !: ElementRef;

  // WebSocket stating variables
  token!:string
  socket !: WebSocket
  spinningOn !:boolean
  weMessage !:string


  //Initial data to complete form
  idTypes !: any[][]

  tranNumber_obj :any = {}
  tranNumber !:number
  tranAmount_obj :any = {}
  tranAmount !:number
  tranCrossBorder_obj :any = {}
  tranCrossBorder !:number

  isEdit: boolean = false



  // Date of Birth
  today = new Date();
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
  oneYearFuture = new Date(new Date().setFullYear(new Date().getFullYear()+1))
  year18past = new Date(new Date().setFullYear(new Date().getFullYear()-18))
  dateOfBirth !: NgbDateStruct;
  maxDob =  new NgbDate(this.year18past.getFullYear(), this.year18past.getMonth()+1, this.year18past.getDate());
  tempDOB !:NgbDateStruct
  // Date of Issue
  dateOfIssue !: NgbDateStruct;
  maxAllowedDateOfIssue = new NgbDate(this.yesterday.getFullYear()-1, this.yesterday.getMonth()+1, this.yesterday.getDate());
  tempDOI !:NgbDateStruct

  // Expiration Date 1Yr from today or more
  dateExpiration !: NgbDateStruct;
  minAllowedDateOfExp = new NgbDate(this.oneYearFuture.getFullYear(), this.oneYearFuture.getMonth()+1, this.oneYearFuture.getDate());
  tempDOE !:NgbDateStruct

  // Verified Date
  dateVerif!: NgbDateStruct;
  maxAllowedDateOfVer = new NgbDate(this.yesterday.getFullYear() , this.yesterday.getMonth() +1, this.yesterday.getDate());
  tempDOV !:NgbDateStruct
  events: string[] = [];

  validationErrors : any = {}
  validationErrorsSmall : any = {}

  fieldValuesSmall: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  fieldsConfig !: FormFieldConfig[]
  fieldsConfigSmall !: FormFieldConfig[]

  dobRaw !: any
  fieldWasEdited: any = {}
  fieldWasEditedSmall: any = {}


  userId !:string


  constructor(
    private signUpService: SignupService,
    private profileService: ProfileService,
    private shareDataService : SharedataService,
    public dialog : MatDialog,
    private router: Router
  ) { }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }
  getvalidationErrorsSmall(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfigSmall) { //loop through fields
      if(this.fieldWasEditedSmall[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValuesSmall[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }
  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    this.fieldWasEditedSmall[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
    this.fieldValuesSmall = { ... this.fieldValuesSmall, [fieldName]: newValue}

  }
  updateDateValues():void{
    this.tempDOB = this.fieldValues['dob']
    const currentDOB = `${this.tempDOB.year}-${this.tempDOB.month}-${this.tempDOB.day}`
    this.fieldValues = { ... this.fieldValues, 'dob': currentDOB}

    this.tempDOI = this.fieldValues['id_issued_date']
    const currentIsDate = `${this.tempDOI.year}-${this.tempDOI.month}-${this.tempDOI.day}`
    this.fieldValues = { ... this.fieldValues, 'id_issued_date': currentIsDate}

    this.tempDOE = this.fieldValues['id_exp_date']
    const currentExpDate = `${this.tempDOE.year}-${this.tempDOE.month}-${this.tempDOE.day}`
    this.fieldValues = { ... this.fieldValues, 'id_exp_date': currentExpDate}

    this.tempDOV = this.fieldValues['id_verified_date']
    const currentVerDate = `${this.tempDOV.year}-${this.tempDOV.month}-${this.tempDOV.day}`
    this.fieldValues = { ... this.fieldValues, 'id_verified_date': currentVerDate}

    this.tranNumber = this.fieldValues['expected_no_transactions']
    const currentTran_data= this.tranNumber_obj[this.tranNumber ]
    this.fieldValues = { ... this.fieldValues, 'expected_no_transactions': currentTran_data}

    this.tranAmount = this.fieldValues['transaction_size']
    const currentTranSize_data= this.tranAmount_obj[this.tranAmount ]
    this.fieldValues = { ... this.fieldValues, 'transaction_size': currentTranSize_data}

    this.tranCrossBorder = this.fieldValues['cross_border_activity']
    const currentTranCB_data= this.tranCrossBorder_obj[this.tranAmount ]
    this.fieldValues = { ... this.fieldValues, 'cross_border_activity': currentTranCB_data}


  }
  confirmInformation(stage:string):void {
    this.currentView = stage

    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    } else {
      this.submitProfile()
    }
  }


  // Validate inputs and submit profile
  submitProfile(): void {

    let newFieldValues = {...this.fieldValues}

    newFieldValues['expected_no_transactions'] = Number(this.tranNumber)
    newFieldValues['transaction_size'] = Number(this.tranAmount)
    newFieldValues['cross_border_activity'] = Number(this.tranCrossBorder)

    // Submit legal terms fields
    this.profileService.postProfile(newFieldValues).subscribe((res) => {
        if (res.e_code == 1) {
          // Open websocket to receive notifications from the bank
          this.startSSE(res.token)
        }
    });
  }

  changeIsEditDOB():void {
    this.dateOfBirth = this.tempDOB
    this.fieldValues['dob'] = this.tempDOB
  }

  changeIsEditIDData():void {

    this.dateOfIssue = this.tempDOI
    this.fieldValues['id_issued_date'] = this.tempDOI

    this.dateExpiration = this.tempDOE
    this.fieldValues['id_exp_date'] = this.tempDOE

    this.dateVerif = this.tempDOV
    this.fieldValues['id_verified_date'] = this.tempDOV
  }

  changeIsEditAccountData():void {
    this.fieldValues['expected_no_transactions'] = this.tranNumber
    this.fieldValues['transaction_size'] =  this.tranAmount
    this.fieldValues['cross_border_activity'] = this.tranCrossBorder
  }
  changeIsEdit():void {
    this.changeIsEditDOB()
    this.changeIsEditIDData()
    this.changeIsEditAccountData()
  }

  // Open websocket
  startSSE(data:string):void{
    console.log('startSSE')
    const websocketUrl = environment.wsUrl + '/notification/?Token='+data;

    this.socket = new WebSocket(websocketUrl);

    this.socket.onopen = () => { this.spinningOn = true };

    this.socket.onmessage = (event) => {

      this.weMessage = JSON.parse(event.data).message

      if(JSON.parse(event.data).additional_info.status == "Success"){
        this.closeSSE()
        this.router.navigateByUrl('/success');
      }
      if(JSON.parse(event.data).additional_info.status == "Failed"){
        this.closeSSE()

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { status: "500" ,
          msg: "Unfortunately, we cannot offer you an account at this time. Please, contact customer service." }
        dialogConfig.panelClass = 'full-30-dialog';
        this.dialog.open(ErrorDialogMsgComponent,dialogConfig)
      }
    };
  }

  // Close websocket
  closeSSE() :void {
    this.socket.close(1000, "CLOSE SUCCESS")
    //console.log("CLOSED")
    this.spinningOn = false
  }
  getIdType():void {
    this.profileService.getIdType().subscribe({ next:(res)=> { if(res) {
          const newList = []
          for(let k of Object.keys(res)){ newList.push([k,res[k]]) }
          this.idTypes = newList
        }}
    })
  }
  changeFieldValuesConfigBW(stage:string){

    if(stage == 'fullName'){
      this.fieldsConfigSmall = this.profileService.formFieldsName
    } else if(stage == 'dob'){
      this.fieldsConfigSmall = this.profileService.formFieldsName
    } else if(stage == 'address'){
      this.fieldsConfigSmall = this.profileService.formFieldsDOB
    } else if(stage == 'idDocs'){
      this.fieldsConfigSmall = this.profileService.formFieldsAddress
    } else if(stage == 'ssnItin'){
      this.fieldsConfigSmall = this.profileService.formFieldsIdData
    } else  if(stage == 'confirmData') {
      this.fieldsConfigSmall = this.profileService.formFieldsSSNITIN
    }

    this.fieldValuesSmall = {}
    for (let field of this.fieldsConfigSmall){
      this.fieldValuesSmall[field.name] = this.fieldValues[field.name]
      this.fieldWasEditedSmall[field.name] = true
    }

  }

  changeViewBW(stage:string ):void{

    this.validationErrors = {}
    this.validationErrorsSmall = {}

    this.changeFieldValuesConfigBW(stage)

    this.enterLegalName = false
    this.enterDOB = false
    this.enterAddress = false
    this.enterIDDocsData = false
    this.enterSSNITIN = false
    this.enterAccountActivity = false
    this.confirmProfileData = false

    if(stage == 'fullName'){
      this.enterLegalName = true
      this.currentView = 'fullName'
    }
    else if(stage == 'dob') {
      this.enterLegalName = true
      this.currentView = 'fullName'
    }
    else if(stage == 'address') {
      this.enterDOB = true
      this.currentView = 'dob'
      this.dateOfBirth = this.fieldValues.dob
    }
    else if(stage == 'idDocs') {
      this.enterAddress = true
      this.currentView = 'address'
    }
    else if(stage == 'ssnItin') {
      this.enterIDDocsData = true
      this.currentView = 'idDocs'

      this.dateOfIssue = this.fieldValues.id_issued_date
      this.dateExpiration = this.fieldValues.id_exp_date
      this.dateVerif = this.fieldValues.id_verified_date

    } else if(stage == 'accountActivity') {
      this.enterSSNITIN = true
      this.currentView = 'ssnItin'
    }
    else if(stage == 'confirmData'){
      this.enterAccountActivity = true
      this.currentView = 'accountActivity'
      this.changeIsEdit()
    }

    else { return }

  // else if(stage == 'confirmData'){
  //     this.enterSSNITIN = true
  //     this.currentView = 'ssnItin'
  //     this.changeIsEdit()
  //   }
  }

  changeFieldValuesConfigFW(stage:string){
    if( stage == 'fullName'){
      this.fieldsConfigSmall = this.profileService.formFieldsDOB
    } else if(stage == 'dob'){
      this.fieldsConfigSmall = this.profileService.formFieldsAddress
    } else if(stage == 'address'){
      this.fieldsConfigSmall = this.profileService.formFieldsIdData
    } else if(stage == 'idDocs'){
      this.fieldsConfigSmall = this.profileService.formFieldsSSNITIN
    } else if(stage == 'ssnItin'){
      this.fieldsConfigSmall = this.profileService.formFieldsTransactionsExp
    } else if (stage == 'accountActivity' ){
      this.fieldsConfigSmall = []
    }

    this.fieldValuesSmall = {}
    for (let field of this.fieldsConfigSmall){
      this.fieldValuesSmall[field.name] = this.fieldValues[field.name]
    }

  }
  changeViewFW(stage:string):void{

    this.changeFieldValuesConfigFW(stage)

    this.enterLegalName = false
    this.enterDOB = false
    this.enterAddress = false
    this.enterIDDocsData = false
    this.enterSSNITIN = false
    this.enterAccountActivity = false
    this.confirmProfileData = false

    if(stage == 'fullName') {
      this.currentView = 'dob'
      this.enterDOB = true

    } else if(stage == 'dob') {
      this.currentView = 'address'
      this.enterAddress = true

    } else if(stage == 'address') {
      this.currentView = 'idDocs'
      this.enterIDDocsData = true

    } else if(stage == 'idDocs') {
      this.currentView = 'ssnItin'
      this.enterSSNITIN = true

    } else if(stage == 'ssnItin') {
      this.currentView = 'accountActivity'
      this.enterAccountActivity = true

    } else if(stage == 'accountActivity') {
      this.currentView = 'confirmData'
      this.updateDateValues()
      this.confirmProfileData = true

    }  else { return }

  // else if(stage == 'ssnItin') {
  //     this.currentView = 'confirmData'
  //     this.updateDateValues()
  //     this.confirmProfileData = true
  //
  //   } else { return }

  // else if(stage == 'ssnItin') {
  //     this.currentView = 'accountActivity'
  //     this.enterAccountActivity = true
  //
  //   }
  //   else if(stage == 'accountActivity') {
  //     this.currentView = 'confirmData'
  //     this.updateDateValues()
  //     this.confirmProfileData = true
  //
  //   }
  }
  continueTestErrors(stage:string){

    this.validationErrors = this.getvalidationErrors(true)
    this.validationErrorsSmall = this.getvalidationErrorsSmall(true)

    if(Object.keys(this.validationErrorsSmall).length > 0) {
      return
    } else {
      this.validationErrors = { }
      this.validationErrorsSmall = { }
      this.changeViewFW(stage)
    }
  }


  getTransactionsOptions():void{
    this.profileService.getTransactionsOptions().subscribe({
      next:(res)=>{
        // Expected number of Transactions
        this.fieldsConfig[15].options  = res.ret[0]['behaviour_values'].map((opt:any)=> [opt.name, opt.value])
        this.tranNumber_obj = res.ret[0]['behaviour_values'].reduce(
          (obj:object, item:any) => Object.assign(obj, { [item.value]: item.name }), {})

        // Expected amount
        this.fieldsConfig[16].options  = res.ret[1]['behaviour_values'].map((opt:any)=> [opt.name, opt.value])
        this.tranAmount_obj = res.ret[1]['behaviour_values'].reduce(
          (obj:object, item:any) => Object.assign(obj, { [item.value]: item.name }), {})

        // Expected cross border
        this.fieldsConfig[17].options  = res.ret[2]['behaviour_values'].map((opt:any)=> [opt.name, opt.value])
        this.tranCrossBorder_obj = res.ret[2]['behaviour_values'].reduce(
          (obj:object, item:any) => Object.assign(obj, { [item.value]: item.name }), {})
      }
    })
  }

  // Take key names and pass initial values
  ngOnInit() {
    // Get Id type from server
    this.getIdType()

    // Create empty payload and pass email
    this.fieldsConfig = this.profileService.formFields

    // Set initial values and pass user email
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ""

    // Get user Id
    this.shareDataService.getUserId().subscribe(value=> {
      this.userId = value
    })

    this.fieldValues = {...this.fieldValues, 'user_id': this.userId }

    // Create empty payload small -- fullName
    this.fieldsConfigSmall = this.profileService.formFieldsName
    this.fieldValuesSmall = {}

    for (let field of this.fieldsConfigSmall)
      this.fieldValuesSmall[field.name] = ""

    // Populate transactions options
    this.getTransactionsOptions()
  }

}
