import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-acc-report-bureau',
  templateUrl: './acc-report-bureau.component.html',
  styleUrls: ['./acc-report-bureau.component.css']
})
export class AccReportBureauComponent {

  constructor(
    private router :Router
  ) { }

  cancelReport():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }
}
