
<div class="container-fluid">

    <div class="row">

    <div class="col-2 d-flex align-items-center justify-content-center" >
      <img  class="img-fluid"  src="/assets/images/edit_profile/profile_img.svg" />
    </div>

    <div class="col-8 d-flex align-items-center" >
      <p class="p-full-name m-0" >{{profileData.first_name}} {{profileData.last_name}}</p>
    </div>

    <!--    <div class="cancel-edit d-flex justify-content-end">-->
    <div class="col-2 d-flex align-items-center justify-content-center button-pointer">
      <img class="img-fluid" (click)="cancelPhoneEdit()" src="/assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

<!--    Enter new phone number-->
    <div *ngIf="enterNewPhone" class="row my-3 d-flex justify-content-center" >

      <div class="row my-3 d-flex justify-content-center" >

        <div class="col-12">
          <p class="p-personalInfo">Edit phone number<p>
        </div>

        <div class="form-floating my-3">
          <input
            id = "inputPhone"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
            [pattern]="'[0-9]{3}-[0-9]{3}-[0-9]{4}'"
          >
          <label class="form-label ms-2">{{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
          {{this.validationErrors[fieldsConfig[0].name]}}
        </p>

      </div>


      <div class="row">
        <div class="d-flex justify-content-center ">
          <button id="btnSubmit" (click)="submitNewPhone()" class="violet-button rounded-4 w-100 py-3 m-2 ">
            Confirm Phone Number
          </button>
        </div>
      </div>


    </div>

<!--  Confirm with code sent to new phone number-->

    <div *ngIf="!enterNewPhone" class="row my-3 d-flex justify-content-center" >
<!--      <div *ngIf="successPhoneRegister === true" >-->
        <app-profile-ph-code-edit></app-profile-ph-code-edit>
<!--      </div>-->
  </div>

</div>

