<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div *ngIf="payroll_histo" >

        <div  [ngClass]=" buttonName === 'See all Activity' ? 'scroll-payroll' : 'scroll-payroll-all' ">

          <table class="table">
            <thead>
            <tr>
              <th>Transaction</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody *ngFor="let p of payrollHisto_display">
            <tr>
              <td class="td-when">{{p.date}}</td>
              <td class="td-desc">{{p.description}}</td>
              <td class="td-amount">${{p.amount|number :'.2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <button  class="button-activity"  (click)="seeAllActivity()">{{buttonName}}</button>

      </div>  <!-- payroll_histo -->

      <div *ngIf="!this.payroll_histo">
        <div class="  h-100 d-flex justify-content-center text-center align-items-center">
          <div>
            <div class="d-flex justify-content-center text-center mb-3">
              <img src="assets/images/transactions/dollar.svg">
            </div>
            <p id="emptyMsg1" class="p-b-nt" >No payroll data to display</p>
          </div>
        </div>
      </div> <!-- NO payroll_histo -->

    </div> <!--Close col-12-->
  </div> <!--Close row-->
</div>
