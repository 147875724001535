<div class="container-fluid" >
  <div class="row my-5 text-center">
    <div>
      <img src="../../../../assets/images/home/success_icon.svg" class="img-fluid">
    </div>
  </div>
  <div class="row text-center my-2">
    <p class="p-title">{{message}}</p>
  </div>
  <div class="row text-center my-2">
    <p class="p-text fw-bold my-2">Close this message and continue banking with Kredio.</p>
  </div>
  <div class="row mb-3">
    <div class=" col-lg-8 offset-lg-2  col-12 text-center">
      <button class=" py-3 rounded-3 w-100 px-2" id="btnClose" (click)="closeMessage()"> Close </button>
    </div>
  </div>
</div>
