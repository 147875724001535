import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ErrorDialogService} from "../services/error-dialog.service";
import {catchError} from "rxjs/operators";


@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private errorDialogService: ErrorDialogService
  ) {}


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error:any) => this.errorHandler(error)));
  }

  private errorHandler(error: HttpEvent<HttpErrorResponse>): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      this.errorDialogService.openDialog(error);
    }
    throw error;
  }

}
