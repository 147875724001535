import {Component, Inject} from '@angular/core';
import {Router} from "@angular/router";
import {CardService} from "../../../services/card.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-trasnfers-greendot',
  templateUrl: './trasnfers-greendot.component.html',
  styleUrls: ['./trasnfers-greendot.component.css']
})
export class TrasnfersGreendotComponent {

  search_word !: string

  error :string = ''
  constructor(
    public dialogRef: MatDialogRef<TrasnfersGreendotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  filterData(data:string):void{

    if(!data){
      this.error = 'Field cannot be empty'
    } else if ( !(Number(data) <= 500 && Number(data) >=10)) {
      this.error = 'The amount must be higher than 10 USD and lower than 500 USD.'
    } else {
      this.error = ''
    }

  }
  cancelActivate(): void{
    this.dialogRef.close()
  }
}
