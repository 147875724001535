import {Component, OnInit} from '@angular/core';
import {Card, CardSelected} from "../../../services/card.service";
import {CardService} from "../../../services/card.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedataService} from "../../../services/sharedata.service";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit{

  // Pass current route to NavBar
  your_route : string = 'Cards'

  // Display available cards to the user
  cards_list !: Card[]
  cards_list_copy !: Card[]
  card_leader !: Card[]
  accountId_List !:any
  accountDict : any = {}
  account_id !:string

  // Search bar
  search_word !: string

  // User selected card
  selectedCard: boolean = false

  constructor(
    private cardsService: CardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedataService :SharedataService
  ) {}

  displayCardInfo(data:CardSelected): void {

    this.sharedataService.setSelectedCard(data)
    this.router.navigateByUrl('/main/cards/selected')
  }

  filterCards(newValue:string):void{
    let allCards = this.cards_list_copy
    if(newValue){
      const filteredList = allCards.filter(c=>c.name.toLowerCase().includes(newValue.toLowerCase()))
      this.cards_list = filteredList
    }
    if(newValue === '')
      this.cards_list = allCards
  }

  ngOnInit() {
    this.cardsService.getCards().subscribe({ next:(res)=>{
          if(res.e_code === 1) {

            this.accountId_List = res.ret.map((elem:any)=>elem.account_id)
            this.cards_list = res.ret.map((elem:any)=>elem.card)

            this.cards_list_copy = this.cards_list
            // Primary account data
            this.card_leader = this.cards_list.filter((c:Card) => c.is_primary)

            for(let [ix , elem] of Object.entries(this.cards_list)){
              this.accountDict[elem.id] = this.accountId_List[Number(ix)]
            }
            this.account_id = this.accountDict[this.card_leader[0].id]
          }

        }})
  }
}
