import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Alert, AlertsService} from "../../../../services/alerts.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-alerts-delete',
  templateUrl: './alerts-delete.component.html',
  styleUrls: ['./alerts-delete.component.css']
})
export class AlertsDeleteComponent {
  
  alerts !:string[]
  constructor(
    private alertsService : AlertsService,
    public dialogRef: MatDialogRef<AlertsDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) { }

  ngOnInit() {
    this.alerts = this.data.data
  }

  deleteAlert():void {
    let payload = { notifications:this.alerts }
    this.alertsService.deleteAlerts(payload).subscribe({ next:(res)=>{ }})
    this.dialogRef.close()
    this.reloadPage()  
  }

  reloadPage():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }

  cancelDeleteAlert ():void {
    this.dialogRef.close()
  }
}
