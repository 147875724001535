<div class="container-fluid ">

  <!-- <div class="row my-2" *ngIf="!isEditData"> -->
  <div class="row my-2">
    <div class="col-lg-10 offset-lg-1 col-12  my-2">
      <div class="form-floating me-3 my-1" >
        <input
          id="inputMCC"
          class="form-control div-form button-pointer"
          type="string"
          style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat "
          [ngModel]="fieldValues[fieldsConfig[0].name]"
          (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
          (blur)="setFieldToEdited(fieldsConfig[0].name)"
        >
        <label class="form-label ms-2">Search</label>
        <ul class="ps-0 rounded-1 scroll-mccs" *ngIf="mccs && viewMCCList"  >
          <div *ngFor="let m of mccs">
            <li class="py-2 ps-1 "  (click)="setMCCValue(m)">{{m}}</li>
          </div>
        </ul>
      </div>
      <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>
    </div><!--Close col -->
  </div><!--Close row -->

  <!-- <div class="row my-2" *ngIf="!isEditData"> -->
  <div class="row my-2">
    <div class="col-lg-10 offset-lg-1 col-12  my-2 ">
      <div class="row">
        <div class="col-lg-6  col-12  my-2 ">
          <div class="form-floating">
            <input
              [id]="'inputAmount'"
              [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
              [placeholder]="fieldsConfig[1].placeHolder"
              [type]="fieldsConfig[1].type"
              [ngModel]="fieldValues[fieldsConfig[1].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[1].name)"
            >
            <label class="form-label">{{fieldsConfig[1].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
            {{this.validationErrors[fieldsConfig[1].name]}}
          </p>
        </div><!--Close col -->
        <div class="col-lg-6 col-12  my-2 ">
          <div class="row my-2 ">
            <div class="col-10 offset-1">
              <button [id]="'btnWeekly'"
                [class]="fieldValues[fieldsConfig[2].name] === 'Weekly'? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[2].name,'Weekly')"
              >Weekly</button>
            </div>
          </div>  <!--Close row -->
          <div class="row my-2">
            <div class="offset-lg-1 col-lg-5 my-2  col-10 offset-1">
              <button [id]="'btnBiWeekly'"
                [class]="fieldValues[fieldsConfig[2].name] === 'Bi-weekly'? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[2].name,'Bi-weekly')"
              >Bi-weekly</button>
            </div>
            <div class="col-lg-5 offset-lg-0  my-2  col-10 offset-1">
              <button [id]="'btnMonthly'"
                [class]="fieldValues[fieldsConfig[2].name] === 'Monthly'? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[2].name,'Monthly')"
              >Monthly</button>
            </div>
          </div>
          <div class="row">
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
              {{this.validationErrors[fieldsConfig[2].name]}}
            </p>
          </div>
        </div> <!--Close col -->
      </div> <!--Close row -->
    </div><!--Close col -->
  </div> <!--Close row -->

  <!--Display MCC Limits -->
  <div class="row my-2 " *ngIf="mccsList.length>0" >
    <div class="col-lg-10 offset-lg-1 col-12  my-2 ">
      <!-- <div class="row text-center my-3 " *ngIf="!isEditData"> -->
      <div class="row text-center my-3 " >
          <p  class="p-merchant-name ">Expense Category Limits</p>
        </div>
      <div class="row p-merchant-border rounded-3">
          <div *ngFor="let mcc of mccsList">
            <div class="row py-2 p-merchant-border ">
              <div class="col-lg-1 col-2 "  >
                <img  id="imgDelete" src="assets/images/cards/merchant_icon.svg"
                     (click)="deleteMCC(mcc.merchants_info[0])"
                     class="img-fluid m-0 button-pointer" >
              </div>
              <div class="col-lg-7 col-6 d-flex  align-content-center button-pointer p-0">
                <p class="p-merchant-name mb-0 ">{{mcc.merchants_info[0]}}</p>
              </div>
              <div class="col-2 d-flex justify-content-center align-content-center p-0">
                <p class="p-merchant-amount mb-0" >${{mcc.amount}}</p>
              </div>
              <div class="col-2 d-flex justify-content-center align-content-center p-0">
                <p class="p-merchant-period  mb-0  ">{{mcc.period}}</p>
              </div>
            </div>
          </div>
        </div>
    </div><!--Close col -->
  </div> <!--Close row -->

  <!--Add Button  -->
  <!-- <div class="row my-2 " *ngIf="!isEditData"> -->
  <div class="row my-2 ">
    <div class="col-lg-2 offset-lg-1 col-3  my-2 ">
      <button
        [id]="'btnAddMCC'"
        (click)="addMCC()"
        [class]="'d-flex justify-content-center align-content-center py-2 btn-add'">
        <div>
          <div><img src="assets/images/cards/add_btn.svg"></div>
          <p class="p-btn-add m-0">Add</p>
        </div>
      </button> <!--Close button -->
    </div><!--Close col -->
  </div> <!--Close row -->

</div>
