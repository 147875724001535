import {Component, Inject, Input, OnInit} from '@angular/core';
import { RecipientExtended, RecipientsService} from "../../../services/recipients.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RecipientsDisplayComponent} from "../recipients-display/recipients-display.component";

@Component({
  selector: 'app-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.css']
})
export class RecipientDetailsComponent implements OnInit{
  @Input() recipientId !:string
  selectedRecipient !:RecipientExtended
  deleted_success !:boolean

  constructor(
    private recipientsService : RecipientsService,
    private router: Router,
    public dialogRef: MatDialogRef<RecipientsDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog : MatDialog // EDIT RECIPIENT

  ) { }

  showConfirmInitialData :boolean = true
  showConfirmDelete :boolean = false
  alertDeleteRecipient(): void{
    this.showConfirmInitialData = false
    this.showConfirmDelete = true
  }
  deleteSelectedRecipient(): void{
    this.recipientsService.deleteRecipient( {recipient_id:this.data.id}).subscribe({
      next:(res)=>{
       if(res.e_code  ==1){
         this.showConfirmDelete = false
         this.deleted_success = true
       }
       // else {
       //   this.showConfirmDelete = false
       //   this.deleted_success = false
       // }
      }})
  }
  cancelDetailDisplay():void{
    this.dialogRef.close()
    const currentUrl = '/main/accounts/recipients';
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})
  }
  ngOnInit() {
    this.recipientsService.getRecipient({recipient_id:this.data.id}).subscribe({
      next:(res)=>{ if (res.e_code ===1) {
        this.selectedRecipient = res.response
      }}})
  }

}
