<div class="container-fluid h-100 " >

  <!-- Left side Image asset and right side signin/create account -->
  <div class="row h95 "  >

    <!-- Left side Image asset centered -->
    <div class="col-lg-7 col-12 d-lg-flex d-none align-items-center justify-content-center">
      <img src="assets/images/welcome/welcome_asset.svg" class="img-fluid w-75" alt="Responsive image">
    </div>

    <!-- Right side logo - login - create account - and some text -->
    <div class="col-lg-5 col-12">

      <!-- Products - contact - about - help links -->
      <div class="row align-items-center d-lg-flex d-none h20" >
        <div class="col justify-content-center d-md-flex d-none" >
          <div>
            <a ><img src="assets/images/welcome/product_asset.svg" class="img-fluid" alt="Welcome"></a>
          </div>
          <div>
            <a routerLink="/" class="help-links ps-1 pt-xl-0"> Product</a>
          </div>
        </div>
        <div class="col justify-content-center d-md-flex d-none" >
          <div>
            <a ><img src="assets/images/welcome/contact_asset.svg" class="img-fluid" alt="" height="24" width="25"></a>
          </div>
          <div>
            <a routerLink="/" class="help-links ps-1 pt-xl-0">Contact</a>
          </div>
        </div>
        <div class="col justify-content-center d-md-flex d-none" >
          <div>
            <a ><img src="assets/images/welcome/about_asset.svg" class="img-fluid" alt=""></a>
          </div>
          <div>
            <a routerLink="/" class="help-links ps-1 pt-xl-0">About</a>
          </div>
        </div>
        <div class="col justify-content-center d-md-flex d-none" >
          <div>
            <a ><img src="assets/images/welcome/help_asset.svg" class="img-fluid" alt=""></a>
          </div>
          <div>
            <a routerLink="/" class="help-links ps-1 pt-xl-0">Help</a>
          </div>
        </div>
      </div>

      <div class="row d-lg-none h5"></div>

      <!--Large Screen -->
      <div class="row  d-none d-lg-block py-3">
        <!-- "welcome to" text -->
        <p class="p-welcome text-center ">Welcome to</p>
      </div>

      <!--Small Screen -->
      <div class="row d-lg-none my-2">
        <p class="p-welcome-small text-center ">
          Welcome to
        </p>
      </div>
      <!-- Logo  d-flex align-items-center justify-content-center -->
      <div class="row text-center my-3 ">
        <div class="col-10 offset-1">
          <img class="img-fluid w-100" src="assets/images/welcome/Kredio_logo.svg" alt="Kredio">
        </div>
      </div>

      <!-- Asset image for smaller pages -->
      <div class="row d-lg-none align-items-center justify-content-center my-5" >
          <img src="assets/images/welcome/welcome_asset.svg" class="img-fluid m-0" style="scale: 80%;" alt="Responsive image">
      </div>


      <!-- Create account and sign in buttons -->

      <div class="row my-5 py-3 h-25">

        <div class="col-10 offset-1 my-2 d-flex justify-content-center align-items-center">
          <button class="violet-button  w-100 py-3 rounded-4" routerLink="/signup/main" id="create-button">Create Account </button>
        </div>

        <div class="col-10 offset-1  my-2 d-flex justify-content-center align-items-center">
          <button class="yellow-button w-100 py-3 rounded-4" routerLink="/login" id="sign-button" >Sign in</button>
        </div>

        <div class="col-10 offset-1 text-center">
          <p class="p-bottom my-4">Lorem ipseum dolor sit amet, consectetur adipiscing elit.
            Eget quam sit blandit porta nullam varius
          </p>
        </div>

      </div>

    </div>

  </div>

  <!-- Bottom Side = Standard terms and policies -->
  <div class="row d-lg-flex d-none h5">
    <div class="text-center fw-bold" >
      <a class="bottom-a" routerLink="'/'"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
      <a class="bottom-a" routerLink="'/'"> Legal&nbsp;&nbsp;|&nbsp;</a>
      <a class="bottom-a" routerLink="'/'"> Contact&nbsp;&nbsp;</a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

