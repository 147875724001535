<div class="container-fluid " >

    <!--  Heading -->
    <div class="row px-1 py-2 "  >
    <div class="col-md-1 col-2 p-0 d-flex justify-content-center align-items-center" >
        <img src="assets/images/cards/cards_icon.svg" class="img-fluid" />
    </div>

    <div class="col-md-10 col-8 "  >
      <p class="p-title mb-0 my-2 ">Set Spending Limits</p>
    </div>

    <div class="col-md-1 col-2  p-0 d-flex  align-items-center cancel-edit button-pointer " >
      <img (click)="cancelSetLimit()"
           class="img-fluid"
           src="assets/images/edit_profile/cancel_edit.svg"
      />
    </div>
  </div>

  <!--  Set spending limits-->

    <div class="row my-3 py-2 row-border px-1">

      <div class="row ">
        <div class="col-xl-11 col-10  ">
          <p [class]="this.isGeneralLimit ? 'isSelected mt-1 mb-0' : 'p-wording  mt-1 mb-0' "
             (click)="changeLimit('isGeneralLimit', !this.isGeneralLimit)"> General Limit </p>
        </div>
        <div class="col-xl-1 col-2 d-flex justify-content-center px-1">
          <img
            id="arrowDownGL"
            src="assets/images/cards/arrow_down.svg"
               class="img-fluid button-pointer"
              [hidden]="isGeneralLimit"
              (click)="changeLimit('isGeneralLimit', true)" alt="down">
          <img

            id="arrowUpGL" src="assets/images/cards/arrow_up.svg"
               class="img-fluid button-pointer"
               [hidden]="!isGeneralLimit"
               (click)="changeLimit('isGeneralLimit', false)" alt="up">

        </div>

      </div>

<!--      <div class="row  py-1 " [hidden]="!isGeneralLimit" >-->
      <div class="row  py-1 " *ngIf="isGeneralLimit" >

        <!-- General Spending Limit-->
        <app-card-limit-gl
        [isGeneralLimit] = isGeneralLimitEE
        [editData] = this.limitsListGL
        ></app-card-limit-gl>

      </div>

    </div>

    <div class="row my-3 row-border button-pointer px-1">

      <div class="row">
        <div class="col-xl-11 col-10">
          <p [class]="isMerchantLimit ? 'isSelected mt-1 mb-0'  : 'p-wording mt-1 mb-0'"
             (click)="changeLimit('isMerchantLimit', !this.isMerchantLimit)"> Limit by Merchant</p>
        </div>
        <div class="col-xl-1 col-2 d-flex justify-content-center px-1">
          <img id="arrowDownMerc" src="assets/images/cards/arrow_down.svg"
               class="img-fluid button-pointer"
               [hidden]="isMerchantLimit"
               (click)="changeLimit('isMerchantLimit', true)" alt="down">
          <img id="arrowUpMerc" src="assets/images/cards/arrow_up.svg"
               class="img-fluid button-pointer "
               [hidden]="!isMerchantLimit"
               (click)="changeLimit('isMerchantLimit', false)" alt="up">
        </div>
      </div>

      <!--    Display when  merchant is selected-->
<!--      <div class="row py-1 my-3" [hidden]="!isMerchantLimit" >-->
      <div class="row py-1 my-3" *ngIf="isMerchantLimit" >
        <app-card-limit-merc
          [isMerchLimit] = isMerchLimitEE
        ></app-card-limit-merc>
      </div>

    </div>

    <div class="row my-3 row-border button-pointer px-1">

      <div class="row">
        <div class="col-xl-11 col-10">
          <p
            [class]="isMCCLimit ? 'isSelected mt-1 mb-0' : 'p-wording mt-1 mb-0'"
            (click)="changeLimit('isMCCLimit', !this.isMCCLimit)"> Limit by Expense Category</p>
        </div>
        <div class="col-xl-1 col-2 d-flex justify-content-center px-1">
          <img id="arrowDownMCC" src="assets/images/cards/arrow_down.svg"
               class="img-fluid button-pointer"
               [hidden]="isMCCLimit"
               (click)="changeLimit('isMCCLimit', true)" alt="down">
          <img id="arrowUpMCC" src="assets/images/cards/arrow_up.svg"
               class="img-fluid button-pointer"
               [hidden]="!isMCCLimit"
               (click)="changeLimit('isMCCLimit', false)" alt="up">
        </div>
      </div>

<!--      <div class="row py-1" [hidden]="!isMCCLimit" >-->
      <div class="row py-1" *ngIf="isMCCLimit" >
        <app-card-limit-mcc
          [isMCCLimit] = isMCCLimitEE
        ></app-card-limit-mcc>

      </div>
    </div>

    <div class="row d-flex justify-content-around align-content-center px-1 ">
      <div class="col-md-6 col-12 my-2 " >
        <button class="yellow-button  rounded-3 w-100 py-3"  (click)="cancelSetLimit()">Cancel</button>
      </div>
      <div class="col-md-6 col-12 my-2" >
        <button class="violet-button rounded-3 w-100 py-3" (click)="submitLimits()">Continue</button>
      </div>
    </div>


</div>


