<div class="container-fluid">

  <div class="row">

    <div class="col-1 p-lg-1 p-0 d-flex justify-content-around" >
      <img class="img-fluid m-0 d-block" src="assets/images/recipients/card_img.svg"/>
    </div >

    <div class="col-lg-10 col-9  d-flex align-items-center px-0  "  >
      <p class="p-title  my-md-3 m-0 ">Add New Recipient</p>
    </div>

    <div class="col-lg-1 p-lg-1  col-2 p-0 d-flex justify-content-around button-pointer" >
      <img (click)="cancelNewRec()" class="img-fluid " src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <!--  this.add_new_rec == true-->
  <div *ngIf="this.add_new_rec == true" >
    <div class="row">
      <p class="div-gi ps-3" >General Information</p>
    </div>

    <div class="row" >
      <div class="col-4 offset-4">
        <div class="text-center my-1 ">
                  <img src="assets/images/recipients/new_picture.svg"
                       class="img-fluid"
                  >
                </div>
      </div>
    </div>

    <div class="row  my-3 ">
      <div class="col-lg-4 offset-lg-4 col-12 text-center" >
        <div >
          <input id="inputImage" class = "file-input" type="file"
                 (change)="handleFileInput($event)"
          >
        </div>
      </div>
    </div>
    <br>
    <div class="row" >

      <div class="col-sm-5 offset-sm-1 col-12 my-3 " >
            <div class="form-floating" >
              <input
                [id]="'inputFName'"
                [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[0].placeHolder"
                [type]="fieldsConfig[0].type"
                [ngModel]="fieldValues[fieldsConfig[0].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[0].name)"
              >
              <label class="form-label  ">{{fieldsConfig[0].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
              {{this.validationErrors[fieldsConfig[0].name]}}
            </p>
          </div>

      <div class="col-sm-5  col-12 my-3">
            <div class="form-floating">
              <input
                [id]="'inputLName'"
                [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[1].placeHolder"
                [type]="fieldsConfig[1].type"
                [ngModel]="fieldValues[fieldsConfig[1].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[1].name)"
              >
              <label class="form-label ">{{fieldsConfig[1].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
              {{this.validationErrors[fieldsConfig[1].name]}}
            </p>
          </div>

<!--    </div>-->

<!--    <div class="row">-->

      <div class="col-sm-5 offset-sm-1 col-12 my-3 " >
            <div class="form-floating" >
              <input
                [id]="'inputEmail'"
                [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[2].placeHolder"
                [type]="fieldsConfig[2].type"
                [ngModel]="fieldValues[fieldsConfig[2].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[2].name)"
              >
              <label class="form-label  ">{{fieldsConfig[2].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
              {{this.validationErrors[fieldsConfig[2].name]}}
            </p>
          </div>

      <div class="col-sm-5 col-12 my-3">
            <div class="form-floating">
              <input
                [id]="'inputPhone'"
                [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError': 'form-control'"
                [placeholder]="fieldsConfig[3].placeHolder"
                [type]="fieldsConfig[3].type"
                [ngModel]="fieldValues[fieldsConfig[3].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[3].name)"
              >
              <label class="form-label ">{{fieldsConfig[3].displayName}}</label>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
              {{this.validationErrors[fieldsConfig[3].name]}}
            </p>
          </div>

    </div>
    <br>
    <div class="row py-3" >
          <div class="col-md-8 offset-md-2 d-flex justify-content-center" >
            <button id="btnSubmit"  class="violet-button w-100 rounded-4 px-2 py-3 my-3  "
                    (click)="submitNewRecipient()"> Save</button>
          </div>
    </div>

  </div>

  <!--  this.add_new_rec == false-->
  <div class="row " *ngIf="this.add_new_rec == false">

    <div *ngIf="add_new_success ==true">
      <div class="row text-center">
        <div class="my-3">
          <img src="assets/images/recipients/success_recipient.svg"
          class="img-fluid">
        </div>

        <div class="my-3">
          <p class="p-message">{{add_message}}</p>
        </div>

      </div>
    </div>

    <div *ngIf="add_new_success ==false">

      <div class="row text-center">
        <div>
          <img src="assets/images/recipients/failure_recipient.svg"
               class="img-fluid">
        </div>

        <div class="my-3">
          <p class="p-message">{{add_message}}</p>
        </div>

      </div>
    </div>

  </div>

</div> <!--Close container-->

