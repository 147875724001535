import {Component, Input} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CardAddNewComponent} from "../card-add-new/card-add-new.component";
import {Card} from "../../../../services/card.service";

@Component({
  selector: 'app-card-add-new-button',
  templateUrl: './card-add-new-button.component.html',
  styleUrls: ['./card-add-new-button.component.css']
})
export class CardAddNewButtonComponent {

  @Input() accountId !:string
  @Input() cardList !: Card[]
  constructor(
    public dialog : MatDialog
  ) { }

  openDialog():void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { accountId : this.accountId , cardList: this.cardList }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(CardAddNewComponent,dialogConfig)

  }
}
