import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {AlertsComponent} from "../../MsgsDocs/Alerts/alerts/alerts.component";
import {Alert, AlertsService} from "../../../services/alerts.service";

@Component({
  selector: 'app-nav-buttons',
  templateUrl: './nav-buttons.component.html',
  styleUrls: ['./nav-buttons.component.css']
})
export class NavButtonsComponent implements OnInit{
  @Input() current_route!:string
  @Input() alerts_count :number = 0
  isLoggedIn: boolean | undefined;
  isEditProfile: boolean = false

  isDisplayProfile : boolean = false
  isUserMenu: boolean = false

  isPhoneNumber: boolean = false

  isEmailAddress: boolean = false
  isPostalAddress :boolean = false

  @ViewChild(AlertsComponent) AlertsComponent !: AlertsComponent ;

  constructor(
    private router: Router,
    private auth: AuthService,

    private alertsService : AlertsService

    ) { }

  navigate(url:string): void {

    // if(url=='logout'){
    //   this.auth.logout()
    // } else

    if(url==='/main/home'){
      this.isUserMenu = false
      this.cancelUserMenu()
    } else if(url==='/main/home/payroll'){
      this.isUserMenu = false
      this.router.navigateByUrl(url)
    }else if(url==='/main/alerts'){
      this.isUserMenu = false
      this.router.navigateByUrl(url)
    }
    else {
      this.isDisplayProfile = false
      this.isEditProfile = false
      this.isUserMenu = false
      this.isPhoneNumber = false
      this.isEmailAddress = false

      if(url==='isEditProfile') {
        this.isEditProfile = true
      }

      if(url==='isDisplayProfile'){
        this.isDisplayProfile = true
      }

      if(url==='isPhoneNumber'){
        this.isPhoneNumber = true
      }

      if(url==='isEmailAddress'){
        this.isEmailAddress = true
      }
      if(url==='isPostalAddress'){
        this.isPostalAddress = true
      }

    }

  }

  userMenuDisplay(): void{
    this.isUserMenu = !this.isUserMenu
    const prev = <HTMLElement> document.getElementsByClassName('carousel-control-prev')[0];
    const next = <HTMLElement> document.getElementsByClassName('carousel-control-next')[0];
    if((prev!=null) &&(next!=null)){
      prev.style.display = 'none';
      next.style.display = 'none';
    }
  }
  cancelUserMenu():void {
    this.router.navigateByUrl('/main/home', {skipLocationChange: true}).then(() => {
      this.isUserMenu = false
    });
    const prev = <HTMLElement> document.getElementsByClassName('carousel-control-prev')[0];
    const next = <HTMLElement> document.getElementsByClassName('carousel-control-next')[0];
    if((prev!=null) && (next!=null) && (prev.style.display == 'none') && (next.style.display == 'none')){
      prev.style.display = 'flex';
      next.style.display = 'flex';
    }
  }

  makeTransfer(): void {
    this.router.navigateByUrl('/main/paymentsTransfers')
  }
  refreshAlerts():void{
    this.alertsService.getAlerts().subscribe({ next:(res)=>{
        // let alerts_all = res.ret.filter((al:Alert)=> al.type !== 0)
        this.alerts_count = res.ret.filter((a:Alert) =>  (a.type !== 0 && a.read === 0)).length
      }})
  }
  ngOnInit(): void {
    this.isLoggedIn = this.auth.isValid();
    //this.refreshAlerts()

    this.alertsService.getAlerts().subscribe({ next:(res)=>{
        this.alerts_count = res.ret.filter((a:Alert) =>  (a.type !== 0 && a.read === 0)).length
      }})

  }
}
