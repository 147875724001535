import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {SharedataService} from "../../../services/sharedata.service";
import {AuthService} from "../../../services/auth.service";
import {ProfileService} from "../../../services/profile.service";
import {AgreeemailService} from "../../../services/agreeemail.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit{

  @Input() registeredEmail !:string

  emailId :string = ""
  isEmailSent :boolean = false

  @Output() closeVerifyEmail = new  EventEmitter<string>()

  constructor(
    private authService : AuthService,
    private agreeEmailService : AgreeemailService,
    private shareDataService : SharedataService,
    private profileService :ProfileService,

  ) { }

  getEmailId():void {
    this.profileService.getUserInfo().subscribe({ // CHECKC
    next:(res)=>{
      if(res.e_code == 1) {
        this.emailId = res.res.email_id
      }
    }})
  }

  sendVerificationEmail():void{ // CHECK CKCKCK
    let payload = { email_id: this.emailId }
      this.agreeEmailService.sendVerifyEmailId(payload).subscribe({
        next:(res:any)=>{
            this.isEmailSent = true
            // if(res.e_code ==1) {
            this.shareDataService.setVerifiedEmail('true')
            // }
        }}
      )
  }

  close():void {
      this.authService.logout()
  }
  cancelConfirmEmail():void {
    this.closeVerifyEmail.emit('true')
  }

  ngOnInit() {
    // Get email id
    this.getEmailId()
  }

}
