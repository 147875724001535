import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import * as url from "url";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit{

  profileData!: ProfileEdit

  isPersonalDetails !:boolean
  @Output() redirect = new EventEmitter<string>()

  constructor(
    private profileService: ProfileService
  ) { }

  redirectToUrl(str:string) {
    this.redirect.emit(str)
  }

  displayPersonalDetails() :void{
    this.isPersonalDetails = true
  }
  ngOnInit() {
    this.isPersonalDetails = false
    this.profileService.getUserInfo().subscribe({
      next:(res)=>{
         this.profileData = res.res
      }})
  }

}
