import {Injectable, Input} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";


export interface Balance {
  balance ?:string,
  paid_cash_back : string,
  cash_back_pending: string,
  total_saved_coupons: string,
}



@Injectable({
  providedIn: 'root'
})

export class HomeService {

  constructor(
    private api: ApiService
  ) { }

  getWSToken():Observable<any>{
    return this.api.get('/get_ws_token/')
  }

  activateRewards():Observable<any>{
    return this.api.post('/activate_rewards/', {})
  }

  getRewardsAccount():Observable<any>{
    return this.api.get('/get_rewards/')
  }
}
