<div class="container-fluid h-100 button-pointer" (click)="isReadAlert()">

  <div class="row mt-1 button-pointer" >
    <div class=" col-12 d-flex align-items-center">
      <p id="pTitle" class="p-title my-2 ">{{this.alert.tittle}}</p>
    </div>
  </div>

  <div class="row my-2 button-pointer">
    <p id="pMsg" class="p-wording">{{this.alert.message}}</p>
  </div>

</div>
