<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div class="row h-100" >
    <!--    <div class="row h-100" *ngIf="complete_signup==true">-->

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5">

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started d-flex align-items-end justify-content-center">
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center ">
          <img src="assets/images/login/logo.svg" class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none py-5" style="height: 70%">
        <div class="d-flex justify-content-center h-100">
          <img class="img-fluid" src="assets/images/login/picture_asset.svg" alt="">
        </div>
      </div>
      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none">
        <div class="text-center" >
          <a class="bottom-a-white" routerLink="/"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="/" > Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="/"> Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div class="col-lg-4 offset-lg-1 h-100 py-0">

      <div class="row d-lg-none h5"></div>

      <!-- Logo appears in small screen -->
      <div class="row d-lg-none my-3 px-1" >
        <img class="img-fluid " src="assets/images/login/logo_blue.svg" alt="Kredio">
      </div>

      <!-- Email was verified -->
      <div *ngIf="isEmailVerified==true" class="h-100">


        <!-- Small screen-->
        <div class="row  d-lg-none mt-5 " >
          <p class="p-title mb-0 py-2 ">Your email was verified</p>
        </div>

        <!-- Large screen-->
        <div class="row  d-none d-lg-block mt-5  " >
          <p class="p-title mb-0 py-2">Your email was verified</p>
        </div>

        <div class="row h10"></div>
        <div class="row text-center mt-5 mb-3" >
          <div>
            <img src="assets/images/login/success_logo.svg" alt="Kredio">
          </div>
        </div>

        <div class="row my-2 text-center" >
          <p class="p-wording">Your account has been successfully connected.</p>
        </div>

        <div class="row">
          <div class="d-flex justify-content-center bot-buffer1">
            <button id="btnSuccess" routerLink="/login" class="violet-button rounded-4 w-100 py-3 m-2 ">
              Start using Kredio
            </button>
          </div>
        </div>

      </div>


      <!-- Email was verified -->
      <div *ngIf="isEmailVerified==false" class="h-100">

        <div class="row d-flex align-items-center my-3 py-3 d-lg-none mt-5" >
          <p class="p-title ">Your email was NOT verified</p>
        </div>

        <div class="row d-flex align-items-center my-3 py-3  d-none d-lg-block"  >
          <p class="p-title ">Your email was NOT verified</p>
        </div>

        <div class="row text-center bot-buffer1" >
          <div>
            <img src="assets/images/dispute/failure_dispute.svg" alt="Kredio">
          </div>
        </div>

        <div class="row text-center bot-buffer1 text-center" >
          <p class="p-wording">Please, log in again to verify your email.</p>
        </div>

        <div class="row">
          <div class="d-flex justify-content-center bot-buffer1">
            <button id="btnFailure" routerLink="/login" class="violet-button rounded-4 w-100 py-3 m-2 ">
              Try verifying again
            </button>
          </div>
        </div>

      </div>


    </div>

  </div>

</div>





