<div class="container-fluid">
  <div class="row my-2 " *ngIf="isDisplayData">
    <p class="p-wording text-justify ">You can limit the card to an specific value, so after it reaches the limit,
      any transaction will automatically get declined.
    </p>
    <div class="col-lg-10 offset-lg-1 col-12  my-2 ">
      <div class="row">
        <div class="col-lg-6  col-12  my-2 ">
          <div class="form-floating">
            <input
              [id]="'inputAmount'"
              [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
              [placeholder]="fieldsConfig[0].placeHolder"
              [type]="fieldsConfig[0].type"
              [ngModel]="fieldValues[fieldsConfig[0].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[0].name)"
            >
            <label class="form-label">{{fieldsConfig[0].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
            {{this.validationErrors[fieldsConfig[0].name]}}
          </p>
        </div><!--Close col -->
        <div class="col-lg-6 col-12  my-2 ">
          <div class="row my-2 ">
            <div class="col-10 offset-1">
              <button
                [id]="'btnWeekly'"
                [class]="fieldValues[fieldsConfig[1].name] === 'Weekly' ? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[1].name,'Weekly')"
              >Weekly</button>
            </div>
          </div>  <!--Close row -->
          <div class="row my-2">
            <div class="offset-lg-1 col-lg-5 my-2  col-10 offset-1">
              <button
                [id]="'btnBiWeekly'"
                [class]="fieldValues[fieldsConfig[1].name] === 'Bi-weekly' ? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[1].name,'Bi-weekly')"
              >Bi-weekly</button>
            </div>
            <div class="col-lg-5 offset-lg-0  my-2  col-10 offset-1">
              <button
                [id]="'btnMonthly'"
                [class]="fieldValues[fieldsConfig[1].name] === 'Monthly' ? 'btn-clicked px-2 py-3' :'btn-unclicked px-2 py-3'"
                (click)="updateFieldValue(fieldsConfig[1].name,'Monthly')"
              >Monthly</button>
            </div>
          </div>
          <div class="row my-2 " *ngIf="isEditData">
            <div class="col-10 offset-1">
              <button
                [id]="'btnRemove'"
                [class]="'btn-remove px-2 py-3'"
                (click)="checkConfirmDelete(true)"
              >Remove</button>
            </div>
          </div>  <!--Close row -->
          <div class="row">
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
              {{this.validationErrors[fieldsConfig[1].name]}}
            </p>
          </div>
        </div> <!--Close col -->
      </div> <!--Close row -->
    </div><!--Close col -->
  </div> <!--Close row -->

  <div *ngIf="isDeleteGeneralLimit">
    <app-modal-new-acc>
      <app-card-limit-msg
        [title]="'Edit General Limit'"
        [message]="'The current general limit will be removed.'"
        (confirmEditLimit)= deleteGeneralLimit($event)
      ></app-card-limit-msg>
    </app-modal-new-acc>
  </div>

</div>
