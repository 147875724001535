<div class="container-fluid h-100">

<!--  <div class="d-block d-lg-none">-->
<!--    <app-nav-bar-horizontal></app-nav-bar-horizontal>-->
<!--  </div>-->

<!--  <div class="row h-100">-->

<!--    <div class="col-lg-2 bg-white h-100 d-none d-lg-block">-->
<!--      <app-nav-bar></app-nav-bar>-->
<!--    </div>-->

<!--    <div class="col-lg-10 col-sm-12 ">-->

      <app-nav-buttons [current_route]= your_route ></app-nav-buttons>

      <div class="row px-1  px-lg-4" >

            <!-- LEFT: Search bar + Add cards + Display cards-->
            <div class="col-xxl-7 col-12 pe-3 mb-xxl-0 mb-3  ">

              <!-- Cards: website -->
              <div class="d-none d-sm-block card bg-white rounded-4 h-100" >
                <div class="card-body" >

                  <!-- Header: Large Screen -->
                    <div class = "row mx-2 my-4 " >

                          <!-- Search bar: responsive -->
                          <div class="col-lg-8 col-12  align-content-center  p-0">
                            <div class="form-floating">
                              <input
                                id="inputSearch"
                                class ="form-control inputStyle "
                                [ngModel]="search_word"
                                (ngModelChange)="filterCards( $event)"
                                placeholder="'Search'"
                                style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
                              >
                              <label class="form-label">Search</label>
                            </div>  <!--Close form -->
                          </div> <!--Close col -->

                          <!-- Add New card button-->
                          <div *ngIf="card_leader" class="col-lg-4 x-3 py-0 d-none d-lg-block align-content-center">
                              <app-card-add-new-button
                                [accountId] = this.account_id
                                [cardList] = this.card_leader
                              ></app-card-add-new-button>
                          </div> <!--Close col -->

                      </div> <!--Close row -->

                    <div class="row mx-2 my-lg-2  my-sm-4  ">
                      <div class="col-4 py-sm-3 py-lg-1 ">
                        <p class="typo_size m-0"> Your Cards </p>
                      </div>
                      <div class="col-6 offset-sm-2 d-none d-sm-block d-lg-none ">
                        <div class="row h-100">
                          <div *ngIf="card_leader" class="col-lg-4 x-3 py-0 align-content-center">
                            <app-card-add-new-button
                              [accountId] = this.account_id
                              [cardList] = this.card_leader
                            ></app-card-add-new-button>
                          </div>
                        </div>

                      </div>
                    </div> <!--Close row -->

                    <!--Large Screen : Cards Summary-->
                   <div class="row mx-2  " *ngIf="cards_list" >
                     <div [ngClass]=" cards_list.length > 2 ? 'scroll-cards' : '' ">
                       <div *ngFor="let c of cards_list ; let i = index" class=" my-3 ">
                              <app-card-summary
                                [cardData]="c"
                                [accountId]="this.accountDict[c.id]"
                                (infoCard)="displayCardInfo($event)"
                              ></app-card-summary>
                            </div>
                     </div>
                   </div>

                </div> <!--Close card body-->
              </div><!--Close rounded card-->

              <!-- Cards: mobile -->
              <div class="d-block d-sm-none card  rounded-4 h-100  " >
                <div class=" card-body  responsive-card "  >

                  <!--Search Bar -->
                  <div class = "row mx-2 " >
                    <div class="col-lg-8 col-12  align-content-center  p-0">
                      <div class="form-floating">
                        <input
                          id="inputSearch2"
                          class ="form-control inputStyle"
                          [ngModel]="search_word"
                          (ngModelChange)="filterCards( $event)"
                          placeholder="'Search'"
                          style="background: #FFFEFA url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
                        >
                        <label class="form-label">Search</label>
                      </div>  <!--Close form -->
                    </div> <!--Close col -->
                  </div> <!--Close row -->

                  <div class="row m-2 d-none d-lg-block">
                    <p class="typo_size m-0 "> Your Cards </p>
                  </div> <!--Close row -->

                  <!-- Responsive Block: only appears when screen is small -->
                  <div class="row my-4 d-lg-none  mx-2">
                    <div class="col-6 py-2 ">
                      <p class="typo_size_1 my-1 ">
                        Your Cards
                      </p>
                    </div>
                    <div *ngIf="card_leader"  class="col-6 p-0 " >
                      <app-card-add-new-button
                        [accountId] = this.account_id
                        [cardList] = this.card_leader
                      ></app-card-add-new-button>
                    </div>
                  </div>


                  <!--Big Screen -->
                  <div class="row d-none d-sm-block mx-2 " *ngIf="cards_list" >
                    <div [ngClass]=" cards_list.length > 2 ? 'scroll-cards' : '' ">
                      <div *ngFor="let c of cards_list ; let i = index" class=" my-3 ">
                        <app-card-summary
                          [cardData]="c"
                          [accountId]="this.accountDict[c.id]"
                          (infoCard)="displayCardInfo($event)"
                        ></app-card-summary>
                      </div>
                    </div>
                  </div>

                  <!--Small Screen -->
                  <div class="row d-block d-sm-none h-100  " *ngIf="cards_list" >
                    <div *ngFor="let c of cards_list ; let i = index" class=" my-3 ">
                      <app-card-summary
                        [cardData]="c"
                        [accountId]="this.accountDict[c.id]"
                        (infoCard)="displayCardInfo($event)"
                      ></app-card-summary>
                    </div>
                  </div>

                  <!--                   </div> &lt;!&ndash;Close row&ndash;&gt;-->

                </div> <!--Close card body-->
              </div> <!--Close rounded card-->

            </div> <!--Close col-->

            <!--RIGHT: Display cards better than cash explanation-->
            <div class="col-xxl-5 col-12 d-xxl-block d-none">
              <div class="card bg-white rounded-4 h-100" >
                <div class="card-body" >

                  <div class="row">

                    <div class="col-1 mt-3">
                      <div class="ms-1 mb-3">
                        <img src="assets/images/nav-bar/cards_icon.svg"
                             class="me-3"
                             alt="card">
                      </div>
                    </div>

                    <div class="col-8 my-3 ">
                      <p class="title-second-row">Why are cards better than cash?</p>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-1 text-end">
                      <img src="assets/images/cards/card_bullet_point.svg"
                           class="mt-3" alt="check"
                      ></div>

                    <div class="col-11">
                      <p class="cards-text mb-0 fw-bold" >SAVE TIME</p>
                      <p class="cards-text" >
                        Cards give you spending power without the inconvenience of waiting in line at the bank.
                        You can save time by eliminating trips to banks and ATMs.
                      </p>
                    </div>

                  </div>

                  <div class="row ">
                    <div class="col-1 text-end">
                      <img src="assets/images/cards/card_bullet_point.svg"
                          class="mt-3 " alt=""></div>
                    <div class="col-11">
                      <p class="cards-text mb-0 fw-bold" >EARN REWARDS</p>
                      <p class="cards-text">
                        Many cards offer rewards programs. Some offer cash back on every purchase;
                        others pay points or discounts. Consumers can earn hundreds, even thousands
                        of dollars back each year on everyday household spending.
                      </p>
                    </div>
                  </div>

                  <div class="row ">
                    <div class="col-1 text-end">
                      <img src="assets/images/cards/card_bullet_point.svg" class="mt-3" alt=""></div>
                    <div class="col-11">
                      <p class="cards-text mb-0 fw-bold" >FEEL MORE SECURE</p>
                      <p class="cards-text" >
                        Using cards reduces personal safety threats associated with carrying large amounts of cash.
                        Unlike cash, cards that are lost or stolen can easily be replaced.
                        Federal law and card fraud protection policies limit your liability for fraudulent charges
                        made on a lost or stolen credit card. There's no comparable protection when you use cash.
                      </p>
                    </div>
                  </div>

                  <div class="row ">
                    <div class="col-1 text-end">
                      <img src="assets/images/cards/card_bullet_point.svg" class="mt-3" alt=""></div>
                    <div class="col-11">
                      <p class="cards-text mb-0 fw-bold"> MANAGE YOUR SPENDING</p>
                      <p class="cards-text" >
                        Using cash can make it more difficult to keep records, track expenses and maintain a budget.
                        You can access better budgeting tools using cards, since they offer an itemized purchase record,
                        facilitating the tracking and budgeting of spending.
                      </p>
                    </div>
                  </div>

                  <div class="row ">
                  <div class="col-1 text-end">
                    <img src="assets/images/cards/card_bullet_point.svg" class="mt-3" alt="">
                  </div>
                    <div class="col-11">
                      <p class="cards-text mb-0 fw-bold">ACCESS TO ADDITIONAL WARRANTY OR PURCHASE PROTECTION</p>
                      <p class="cards-text">
                        A card can be a great way to protect a major purchase. Most card issuers offer purchase
                        protection and an extended warranty for items bought with the card.
                      </p>
                    </div>
                  </div>

                </div> <!--Close card body-->
              </div> <!--Close rounded card-->
            </div> <!--Close col-->

            <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>

      </div>

<!--    </div> &lt;!&ndash;Close col-10&ndash;&gt;-->

<!--  </div> &lt;!&ndash;Close row&ndash;&gt;-->

</div> <!--Close container-->
