import {Component, Input, OnInit} from '@angular/core';
import {RecPayment, RecpaymentsService} from "../../../services/recpayments.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {RecPayDeleteComponent} from "../rec-pay-delete/rec-pay-delete.component";

@Component({
  selector: 'app-rec-pay-month',
  templateUrl: './rec-pay-month.component.html',
  styleUrls: ['./rec-pay-month.component.css']
})
export class RecPayMonthComponent implements OnInit{

  next_payments !:RecPayment[]
  sender_name !:string
  deleteData !: RecPayment
  isDeleteAll !:boolean

  constructor(
    private recPaymentsService : RecpaymentsService,
    public dialog : MatDialog
  ) { }

  showDeletePayment(data:RecPayment):void{

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { deleteData: data, isDeleteAll: false }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecPayDeleteComponent,dialogConfig)
  }

  ngOnInit() {
    this.recPaymentsService.getNextPayments().subscribe({
      next:(res)=>{
        if(res.e_code === 1){
          this.next_payments = res.payments
          this.sender_name = "Kredio ****" + res.payments[0]
            .account_number.toString().split('').slice(4,-1).join('')
        }
      }})
  }
}
