<div class="container-fluid h-100">

      <br>
      <br>
      <!--Title: Small screen-->
      <div class="row  d-lg-none my-3 " >
        <p class="p-title mb-0 py-2 ">Verify your phone number</p>
      </div>

      <!--Title: Large screen-->
      <div class="row  d-none d-lg-block my-3  " >
        <p class="p-title mb-0 py-2">Verify your phone number</p>
      </div>

      <!--  Enter initial phone number-->
      <div *ngIf="!this.checkCode" class="row" >
        <div class="col-12">
          <div class="row" >
            <p>To confirm your phone number (i.e. 123-456-7890), we will send you a text message with a verification code.</p>
          </div>

          <div class="row px-2 ">
            <div class="form-floating my-3" >
              <input
                [id]="'inputPhone'"
                [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
                [type]="fieldsConfig[1].type"
                [placeholder]="fieldsConfig[1].displayName"
                [ngModel]="fieldValues[fieldsConfig[1].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[1].name)"
              >
              <label  [class]="validationErrors[fieldsConfig[1].name]? 'form-label labelError ms-2': 'form-label ms-2'"
              >{{fieldsConfig[1].displayName}}</label>
              <p class="error my-1" id="errorPhone"
                 *ngIf="this.validationErrors[fieldsConfig[1].name]">
                {{this.validationErrors[fieldsConfig[1].name]}}
              </p>
            </div>

          </div>

          <div class="h-100"></div>

          <div class="row px-2 my-2">
            <div class="col-1 align-content-center">
              <span [ngClass]="agreeNotifications? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="acceptMessages()"></span>
            </div>
            <div class="col-11 p-test-agree">
               If you agree, you are providing Kredio your express consent to communicate
               with you via direct and automated calls and text messages.
               Mobile, messages, and data rates may apply.
            </div>
          </div>

          <div class="row  px-2" >
            <button class="violet-button rounded-4 redLines w-100 py-3 my-2" id="contineButton"
                    (click)="createPhoneNumberFc()"> Continue</button>
          </div>

        </div>


      </div>


      <!--  Enter check code sent to the phone number provided-->
      <div *ngIf="this.checkCode" >

        <div class="row" >
          <p>Enter the 4 digits code that was sent to: <strong>{{this.fieldValues['phone']}}</strong></p>
        </div>

        <div class="col-12 my-3 h-50">
          <div class="row py-2">
            <code-input
              id ="inputCode"
              [isCodeHidden]="true"
              [codeLength]="4"
              (codeCompleted)="onCodeCompleted($event)">
            </code-input>
          </div>

          <div class="row py-2 ">
            <div class="col-lg-1 col-2 p-0  text-center" >
              <img src="../../../../assets/images/signup/send_again.svg"
                   (click)="sendCodeAgain()"
                   class="img-fluid  button-pointer">
            </div>
            <div class="col-lg-11 col-10 ">
              <p class="p-send mb-0 button-pointer" (click)="sendCodeAgain()">
                Send code again
              </p>
            </div>
          </div>

        </div>


        <div class="row  px-2" >
          <button class="violet-button rounded-4 redLines w-100 py-3 my-2"
                  id="verifyButton" (click)="activatePhoneFc()">
            Continue</button>
        </div>


      </div>


  </div> <!-- Close container -->
