import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import { isNotEmptyValidator, usaPhoneValidator } from "../components/Shared/forms-shared/validators";

export interface SignUp {
  email: string,
  password: string,
  password_2 : string
}

export interface CreatePhone {
  phone_id:string,
  phone: string
}

export interface ActivatePhone {
  phone_id:string,
  code: number
}

export interface VerifyCode {
  phone_id: string
}

@Injectable({
  providedIn: 'root'
})


export class SignupService {

  // _errorSU !:string

  // get errorSU():string {
  //   return this._errorSU
  // }
  // set errorSU( err:string) {
  //   this._errorSU = err
  // }
  //
  // getError():string{
  //   return  this.errorSU
  // }

  // _newEmail !:string
  //
  // get newEmail():string {
  //   return this._newEmail
  // }
  // set newEmail( mail:string) {
  //   this._newEmail = mail
  // }
  //
  // getnewEmail():string{
  //   return  this.newEmail
  // }

  constructor(
    private api: ApiService
  ) {}

  fieldsConfigPhone: FormFieldConfig[] = [
    {
      name: 'user_id',
      type: 'string',
      validators : [isNotEmptyValidator],
    },
    {
      name: 'phone',
      displayName:'Phone Number',
      placeHolder: "555-555-5555",
      type: 'string',
      validators : [isNotEmptyValidator, usaPhoneValidator],
    },
  ]

  createPhone( data:CreatePhone){
    return this.api.post('/create_phone/',data)
  }


  activatePhone( data:ActivatePhone){
    return this.api.post('/activate_phone/',data)
  }

  sendVerifyCode( data:VerifyCode){
    return this.api.post('/send_code/',data)
  }


  signUp(data: SignUp) {
    return this.api.post('/reg_new_user/', data)
  }


}
