
<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div *ngIf="displayLarge" class="row h-100">

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5">

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started d-flex align-items-end justify-content-center">
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center">
          <img src="assets/images/login/logo.svg" class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none py-5" style="height: 70%">
        <div class="d-flex justify-content-center h-100">
          <img class="img-fluid" src="assets/images/login/profile_fill_in.svg" alt="">
        </div>
      </div>

      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none">
        <div class="text-center">
          <a class="bottom-a" style="text-decoration-line: none" routerLink="'/'" > Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a" style="text-decoration-line: none" routerLink="'/'" > Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a" style="text-decoration-line: none" routerLink="'/'" > Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div class="col-lg-6 col-12  py-0 scroll-profile"   >
      <!-- Logo appears in small screen -->
      <div class="row d-lg-none my-3 px-1" >
        <img class="img-fluid " src="assets/images/login/logo_blue.svg" alt="Kredio">
      </div>

      <div class="row">
        <app-profile-create
        ></app-profile-create>
      </div>

    </div>


  </div> <!--    Close row -->

  <div *ngIf="!displayLarge" class="row h-100 p-0" >
    <app-profile-create-small></app-profile-create-small>
  </div>

</div> <!--    Close container-->


