import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-coupons-add-success',
  templateUrl: './coupons-add-success.component.html',
  styleUrls: ['./coupons-add-success.component.css']
})
export class CouponsAddSuccessComponent {

  @Input() activateMsg !:string

}
