<div class="container-fluid" >

    <div class="row my-1" >

      <div class="col-lg-1 col-2 p-0 text-center px-1" >
        <img class="img-fluid"
             src="assets/images/accounts/accounts_icon.svg"
        />
      </div>

      <div class="col-lg-10 col-8 d-flex align-items-center">
        <p class="p-title mb-0 ">Add External Account</p>
      </div>

      <div class="col-lg-1 col-2 py-0 px-1 text-center button-pointer" >
        <img (click)="cancelAddAccount()" class="img-fluid"
             src="assets/images/edit_profile/cancel_edit.svg"/>
      </div>
    </div>

    <div *ngIf="!add_account_initial">

      <div class="row">
        <div class="text-center">
          <div class="my-5">
            <img src="assets/images/accounts/success.svg">
          </div>
          <p>The account was successfully added</p>
        </div>
      </div>

      <div class="row my-3">
        <div class="offset-2 col-8" >
          <button class="p-3 w-100 rounded-3" (click)="cancelAddAccount()">Ok</button>
        </div>
      </div>

    </div>

    <div  *ngIf="add_account_initial" class="my-3">

      <div class="row  my-4" >
        <div class="offset-lg-2 col-lg-8 col-12" >
          <div class="form-floating ">
            <input
              [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
              [placeholder]="fieldsConfig[2].placeHolder"
              [type]="fieldsConfig[2].type"
              [ngModel]="fieldValues[fieldsConfig[2].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[2].name)"
            >
            <label class="form-label">{{fieldsConfig[2].displayName}}</label>
          </div>
          <p  class="error p-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
            {{this.validationErrors[fieldsConfig[2].name]}}
          </p>
        </div>

      </div>


      <div class="row text-center my-4">
        <div class="offset-lg-2 col-lg-8 col-12 " >
          <div class="form-floating">
            <input
              [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError': 'form-control'"
              [placeholder]="fieldsConfig[3].placeHolder"
              [type]="fieldsConfig[3].type"
              [ngModel]="fieldValues[fieldsConfig[3].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[3].name)"
            >
            <label class="form-label">{{fieldsConfig[3].displayName}}</label>
          </div>
          <p  class="error p-1"  *ngIf="this.validationErrors[fieldsConfig[3].name]">
            {{this.validationErrors[fieldsConfig[3].name]}}
          </p>
        </div>
      </div>

      <div class="row my-4 ">
        <div class="offset-lg-2 col-lg-8 col-12" >
          <button class="violet-button rounded-3 p-3 w-100" (click)="submitNewAccount()">Add Account</button>
        </div>
      </div>

    </div> <!--Close add account ngif-->

</div> <!--Close container-->
