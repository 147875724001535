<div class="container-fluid " >

  <!--  Heading -->
  <div class="row px-1 py-2 "  >
    <div class="col-md-1 col-2 p-0 d-flex justify-content-center align-items-center" >
      <img src="assets/images/cards/cards_icon.svg" class="img-fluid" />
    </div>

    <div class="col-md-10 col-8 "  >
      <p class="p-title mb-0 my-2 ">Edit Spending Limits</p>
    </div>

    <div class="col-md-1 col-2  p-0 d-flex  align-items-center cancel-edit button-pointer " >
      <img (click)="cancelSetLimit()"
           class="img-fluid"
           src="assets/images/edit_profile/cancel_edit.svg"
      />
    </div>
  </div>


  <div class="row my-3 py-2 row-border px-1">

    <div class="row ">
      <div class="col-xl-11 col-10  ">
        <p [class]="this.isGeneralLimit ? 'isSelected mt-1 mb-0' : 'p-wording  mt-1 mb-0' "> General Limit </p>
      </div>
    </div>
    <div class="row  py-1 " *ngIf="isGeneralLimit" >
      <!-- General Spending Limit-->
      <app-card-limit-gl
        [editData] = this.limitsListGL
        [isEditData] = true
      ></app-card-limit-gl>

    </div>

  </div>

  <div class="row my-3 row-border button-pointer px-1" >

    <div class="row">
      <div class="col-xl-11 col-10">
        <p [class]="isMerchantLimit ? 'isSelected mt-1 mb-0'  : 'p-wording mt-1 mb-0'"> Limit by Merchant</p>
      </div>
    </div>

    <!--    Display when  merchant is selected-->
    <div class="row py-1" *ngIf="isMerchantLimit" >
      <app-card-l-merch-edit
        [isMerchLimit] = isMerchLimitEE
        [editDataMerc] = limitsListMerch
      ></app-card-l-merch-edit>
    </div>

  </div>

  <div class="row my-3 row-border button-pointer px-1">

    <div class="row">
      <div class="col-xl-11 col-10">
        <p
          [class]="isMCCLimit ? 'isSelected mt-1 mb-0' : 'p-wording mt-1 mb-0'"
          > Limit by Expense Category</p>
      </div>
    </div>

    <div class="row py-1" *ngIf="isMCCLimit" >
      <app-card-l-mcc-edit
        [isMCCLimit] = isMCCLimitEE
        [editDataMCC] = limitsListMCC
        [editDataCodes] = limitsListMCCCodes
      ></app-card-l-mcc-edit>

    </div>

  </div>

  <div class="row d-flex justify-content-around align-content-center px-1 ">
    <div class="col-md-6 col-12 my-2 " >
      <button class="yellow-button  rounded-3 w-100 py-3 button-pointer"  (click)="cancelSetLimit()">Cancel</button>
    </div>
    <div class="col-md-6 col-12 my-2" >
      <button class="violet-button rounded-3 w-100 py-3 button-pointer" (click)="submitLimits()">Continue</button>
    </div>
  </div>


</div>
