import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Account, AccountService} from "../../../services/account.service";

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit{

  account_main !: Account
  // account_number !: string
  // routing_number !:string
  constructor(
    private router: Router,
    private accountService : AccountService
  ) {}


  closeData(): void{
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/main/accounts']);
    })
  }

  ngOnInit() {
    this.accountService.getAccounts().subscribe({next:(res:any)=>{
     if(res.e_code == 1){
        this.account_main = res.accounts.filter((acc:Account) => acc.is_primary ==1)[0]
      }
      }})
  }
}
