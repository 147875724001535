<div class="container-fluid h-100 bg-white">

  <div *ngIf="this.transferInfo==false" class="div-profile-box">

      <!-- First Row = Some Text and Pictures -->
      <div class="row mt-3">

        <div class="col-lg-1 col-2 p-0"   >
          <div class="d-flex justify-content-around ">
            <img class="img-fluid" src="assets/images/pay_tf/transfer.svg" >
          </div>
        </div> <!-- Second Col ends -->

        <div class="col-lg-10 col-8 p-0 d-flex align-items-center " >
          <p class="transfer-text my-0">Transfer</p>

        </div> <!-- Col ends -->

        <div class="col-lg-1 col-2 p-0"  >
          <div class="d-flex justify-content-around button-pointer">
            <img (click)="cancelTransfer()"
                 src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
          </div>
        </div> <!-- Second Col ends -->

      </div> <!-- First Row ends -->

      <!-- Second Row = Transfer from/to forms -->
      <div class="row mt-1">

        <div class="col-sm-6 col-12 my-2 ">
          <div class="form-floating ">
            <select
              id="sender"
              [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError ': 'form-control '"
              [ngModel]="fieldValues[fieldsConfig[0].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[0].name)"
            >
              <option  *ngFor="let option of accounts_sender" [value] = option[0] >{{option[1]}}</option>

            </select>
            <label class="form-label" >{{fieldsConfig[0].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]"> {{this.validationErrors[fieldsConfig[0].name]}}
        </div> <!-- First Col ends -->

        <div class="col-sm-6 col-12 my-2">
          <div class="form-floating  ">
            <select
              id="receiver"
              [class] ="validationErrors[fieldsConfig[1].name]? 'form-select formError select-style': 'form-control select-style'"
              [ngModel]="fieldValues[fieldsConfig[1].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[1].name)"
            >
              <option  *ngFor="let option of accounts_receiver" [value] = option[0] >{{option[1]}}</option>
            </select>
            <label class="form-label" >{{fieldsConfig[1].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]"> {{this.validationErrors[fieldsConfig[1].name]}}
        </div> <!-- Second Col ends -->

      </div> <!-- Second Row ends -->

      <!-- Third row = Add new recipient appears if Local transfer   -->
      <div class="row mt-2"  >
        <div class="d-flex justify-content-end text-decoration-underline new-recipient fw-bold "
            ><p id="newRecipient" routerLink="/main/accounts/recipients" class="button-pointer" >+ Add New Recipient</p>
        </div>
      </div> <!-- Third Row ends -->

      <!-- Fourth row = Amount/Memo -->
      <div class="row mt-4">

        <div class="col-sm-6 col-12 my-2">
          <div class="form-floating">
            <input
              id="amount"
              [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError input-style': 'form-control input-style'"
              [type]="fieldsConfig[2].type"
              [ngModel]="fieldValues[fieldsConfig[2].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[2].name)"
            >
            <label class="form-label">{{fieldsConfig[2].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
            {{this.validationErrors[fieldsConfig[2].name]}}
          </p>
        </div> <!-- First Col ends -->

        <div class="col-sm-6 col-12 my-2">
          <div class="form-floating">
            <input  id="memo"
                    [class] ="validationRecErrors[fieldsConfig[3].name]? 'form-control formError input-style': 'form-control input-style'"
                   [type]="fieldsConfig[3].type"
                   [ngModel]="fieldValues[fieldsConfig[3].name]"
                   (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
                   (blur)="setFieldToEdited(fieldsConfig[3].name)"
            >
            <label class="form-label">{{fieldsConfig[3].displayName}}</label>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
            {{this.validationErrors[fieldsConfig[3].name]}}
          </p>
        </div> <!-- First Sec ends -->

      </div> <!-- Fourth Row ends -->

      <!-- Fifth row = Repeat Payment choice col-xl-3 -->
      <div class="row my-3">
        <div class="col-12">
          <div class="form-check form-switch text-center d-flex p-0 align-items-center">
              <label class="form-check-label label-rep-pymnt me-2 " for="flexSwitchCheckDefault">
                <img class="p-0" src="assets/images/pay_tf/rec_payment.svg" alt="Icon">
                Repeat Payment
              </label>
            <input class="input-toggle form-check-input m-0 p-0"
                   type="checkbox"
                   role="switch"
                   [checked]="repeat_payment"
                   id="flexSwitchCheckDefault"
                   (click)="repeatPaymentChoice()" />

          </div>
        </div>
      </div> <!-- Fifth Row ends -->

      <!-- Six row = Frequency/send on/duration -->
      <div class="row mt-3" *ngIf="this.repeat_payment">

        <div class="col-sm-4 col-12 my-2">
          <div class="mt-1">
            <div class="form-floating">
              <select
                class="form-select"
                      [class] ="validationRecErrors[fieldsConfigRec[0].name]? 'form-select formError ': 'form-control '"
                      [ngModel]="fieldValuesRec[fieldsConfigRec[0].name]"
                      (ngModelChange)="updateFieldValueRec(fieldsConfigRec[0].name, $event)"
                      (blur)="setRecFieldToEdited(fieldsConfigRec[0].name)"
              >
                <option value="" disabled selected></option>
                <option  value = 3 >Monthly</option>
              </select>
              <label class="form-label"> Frequency</label>
            </div>

            <p  class="error my-1" *ngIf="this.validationRecErrors[fieldsConfigRec[0].name]">
                {{this.validationRecErrors[fieldsConfigRec[0].name]}}
            </p>

          </div>
        </div> <!-- First Col ends -->

        <div class="col-sm-4 col-12 my-2">
          <div class="mt-1">
            <div class="form-floating">
              <select class="form-select"
                      [class] ="validationRecErrors[fieldsConfigRec[1].name]? 'form-select formError ': 'form-control '"
                      [ngModel]="fieldValuesRec[fieldsConfigRec[1].name]"
                      (ngModelChange)="updateFieldValueRec(fieldsConfigRec[1].name, $event)"
                      (blur)="setRecFieldToEdited(fieldsConfigRec[1].name)"
              >
                <option value="" disabled selected></option>
                <option  value = 1 >1</option>
                <option  value = 2 >2</option>
                <option  value = 3 >3</option>
                <option  value = 4 >4</option>
                <option  value = 5 >5</option>
                <option  value = 6 >6</option>
                <option  value = 7 >7</option>
                <option  value = 8 >8</option>
                <option  value = 9 >9</option>

                <option  value = 10 >10</option>
                <option  value = 11 >11</option>
                <option  value = 12 >12</option>
                <option  value = 13 >13</option>
                <option  value = 14 >14</option>
                <option  value = 15 >15</option>
                <option  value = 16 >16</option>
                <option  value = 17 >17</option>
                <option  value = 18 >18</option>
                <option  value = 19 >19</option>

                <option  value = 20 >20</option>
                <option  value = 21 >21</option>
                <option  value = 22 >22</option>
                <option  value = 23 >23</option>
                <option  value = 24 >24</option>
                <option  value = 25 >25</option>
                <option  value = 26 >26</option>
                <option  value = 27 >27</option>
                <option  value = 28 >28</option>
                <option  value = 29 >29</option>
                <option  value = 30 >30</option>

              </select>
              <label class="form-label"> Send on</label>
            </div>
            <div>
              <p  class="error my-1" *ngIf="this.validationRecErrors[fieldsConfigRec[1].name]">
                {{this.validationRecErrors[fieldsConfigRec[1].name]}}
              </p>
            </div>

          </div>
        </div> <!-- Second Col ends -->

        <div class="col-sm-4 col-12 my-2">
          <div class="mt-1">

            <div class="input-group ">
              <input
                [class] ="validationRecErrors[fieldsConfigRec[2].name]? 'input-date form-control formError py-3': ' input-date form-control  py-3'"
                [placeholder]="'End Date'"
                name="dp"
                [ngModel]="dateRecTransfer"
                (ngModelChange)="updateFieldDateValue(fieldsConfigRec[2].name,$event)"
                (blur)="setRecFieldToEdited(fieldsConfigRec[2].name)"
                ngbDatepicker
                #dov="ngbDatepicker"
                [minDate]= minRecTransferEndDate
              />
              <button class="btn btn-outline-secondary bi bi-calendar3 " (click)="dov.toggle()" type="button"></button>
            </div>
            <p  class="error" *ngIf="this.validationRecErrors[fieldsConfigRec[2].name]">
              {{this.validationRecErrors[fieldsConfigRec[2].name]}}
            </p>
          </div>

        </div> <!-- Third Col ends -->

      </div> <!-- Six Row ends -->

    <!-- Seven row = Continue Button -->
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-12 my-3">
        <div id="continueBtn" class="text-center violet-button rounded-3 py-3 mx-2 w-100 button-pointer"
             (click)="verifyInformation()"> Continue
        </div>
      </div>
      </div>


    </div>


<!--  Once the transaction details are complete the user can verify them before the transfer is executed-->
    <div *ngIf="this.transferInfo==true">

    <!-- Verify information -->

<!--        <div *ngIf="this.message ==''">-->
       <div class="row mt-3 p-0" >

            <div class="col-lg-1 col-2  p-0"  >
              <div class="d-flex justify-content-around ">
                <img class="img-fluid" src="assets/images/pay_tf/transfer.svg" >
              </div>
            </div> <!-- Second Col ends -->

            <div class="col-lg-10 col-8 p-0 d-flex align-items-center " >
              <p class="transfer-text my-0"> Verify Information</p>
            </div> <!-- Col ends -->

            <div class="col-lg-1 col-2 p-0"  >
              <div class="d-flex justify-content-around button-pointer">
                <img (click)="cancelTransfer()"
                     src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
              </div>
            </div> <!-- Second Col ends -->

          </div> <!-- First Row ends -->
<!--        </div>-->

    <!-- Success logo is displayed only when the transfer was performed -->

        <div *ngIf="is_transfer_success == 'true'">

          <div class="text-center mt-3">
            <div>
              <img src="assets/images/pay_tf/success.svg">
            </div>
            <p class="p-title my-3">Success!</p>
            <p class="p-text">{{this.message}}</p>
          </div>
        </div>

    <!-- Failure logo is displayed only when the transfer was NOT performed -->

        <div *ngIf="is_transfer_success == 'false'">
          <div class="text-center mt-3">
            <div>
              <img src="assets/images/accounts/fail_add_account.svg">
            </div>
            <p class="p-title my-3">The transfer was not successful!</p>
            <p class="p-text">{{this.message}}</p>
          </div>
        </div>

    <!-- Transaction summary details are displayed to verify data or after the transfer was successfully performed  -->

          <div class="row text-center my-5">
            <div class="col-5 text-center">
              <p class="p-verify" > ${{this.fieldValues['amount']|number :'.2'}} </p>
              <p class="p-text">Sender Information</p>
              <p class="p-account fw-bold">{{sender_account}}</p>
            </div>

            <div class="col-2 text-center">
              <img src="assets/images/pay_tf/arrow.svg" class="img-fluid">
            </div>

            <div class="col-5 text-center">
              <p class="p-verify">Local Transfer</p>
              <p class="p-text">Bank Account</p>
              <p class="p-account fw-bold">{{this.receiver_account}}</p>
            </div>
          </div>

    <!-- Recurrent payment Information Displayed -->

          <div *ngIf="this.repeat_payment" class="my-2">

            <div class="row my-1">
              <p class="p-schedule">Scheduled as recurrent payment:</p>
            </div>

            <div class="row text-center my-1 ">
              <div class="col-4 my-2 text-center">
                <p class="p-text-rp mb-1 ">Frequency</p>
                <div class="p-rec-pay rounded-3 py-3">{{this.frequency_words}}</div>
              </div>
              <div class="col-4 my-2 text-center">
                <p class="p-text-rp mb-1">Send On</p>
                <div class="p-rec-pay rounded-3 py-3 ">{{this.fieldValuesRec['send_day']}}</div>
              </div>
              <div class="col-4 my-2 text-center">
                <p class="p-text-rp mb-1">Duration</p>
                <div class="p-rec-pay rounded-3 py-3 " >{{this.displayEndDay}}</div>
              </div>
            </div>

          </div>

    <!-- Success logo is displayed only when recurrent payment is added -->

          <div *ngIf="is_recPayment_success == 'true'">

            <div class="text-center mt-3">
              <div class="my-3">
                <img src="assets/images/pay_tf/success.svg">
              </div>
              <p class="p-title">Success!</p>
              <p class="p-text">{{this.message_rec_transfer}}</p>
            </div>
          </div>

          <div *ngIf="is_recPayment_success == 'false'">
            <div class="text-center mt-3">
              <div class="my-3">
                <img src="assets/images/accounts/fail_add_account.svg">
              </div>
              <p class="p-title">The transfer was not successful!</p>
              <p class="p-text">{{this.message_rec_transfer}}</p>
            </div>
          </div>

    <!-- Buttons -->

        <!-- Div is displayed when verifying the transfer details - before attempting to transfer-->
          <div *ngIf="this.message ==''">
            <div class="row text-center my-2">

              <div class="col-lg-6 col-12">
                <button class=" yellow-button rounded-3 mx-2 w-100 py-3 my-3" (click)="editTransferInformation()">Go Back to Edit</button>
              </div>
              <div class="col-lg-6 col-12">
                <button class=" violet-button rounded-3 mx-2 w-100 py-3 my-3" (click)="submitForm()">Send Money</button>
              </div>

            </div>
          </div>

          <!-- Div is displayed if the transfer was executed successfully-->
          <div *ngIf="this.message !=='' && is_transfer_success == 'true'" class="text-center mb-3">
            <div class="row text-center my-2">
              <div class="col-lg-6 offset-lg-3 offset-0 col-12">
                <button class="violet-button rounded-3 mx-2 w-100 py-3 my-3" (click)="cancelTransfer()">Make Another Transfer</button>
              </div>
            </div>
          </div>

          <!-- Div is displayed if the transfer failed-->
          <div *ngIf="this.message !=='' && is_transfer_success == 'false' ">

            <div class="row text-center my-2">
              <div class="col-lg-6 offset-lg-3 offset-0 col-12">
                <button class="violet-button rounded-3 mx-2 w-100 py-3 my-3" (click)="verifyInformation()">Go Back to Edit</button>
              </div>
            </div>

          </div>


  </div>

</div> <!-- Container ends -->

