import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Alert, AlertsService} from "../../../../services/alerts.service";
import {AlertsDeleteComponent} from "../alerts-delete/alerts-delete.component";
import {AlertsDisplayComponent} from "../alerts-display/alerts-display.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit{
  your_route:string = "Alerts & Notifications"
  alerts_count : number = 0
  buttonName : string = 'See Alerts'
  search_word !:string

  read_alert :boolean = false
  picked_alert !: Alert

  alerts !: Alert[]
  alerts_all !: Alert[]
  alerts_selected : string[] = []
  isDeleteAll !:boolean
  deleteData !:Alert

  constructor(
    private alertsService: AlertsService,
    public dialog : MatDialog,
    private router: Router
  ) { }

  selectAlert(data: string , idx: number):void{

    let elem = document.getElementById(idx.toString())
    let elem_line = document.getElementById(idx.toString()+'_alert')
    let elem_sm = document.getElementById(idx.toString()+'_sm')

    let num = this.alerts_selected.filter((a:string)=> a == data)

    if(num.length > 0) {
      // Remove element from alerts_selected
      this.alerts_selected = this.alerts_selected.filter((a:string)=> a != data)
      // Change css class
      if(elem !=null && elem_line != null && elem_sm!=null ){
        elem.className = 'btnSelect'
        elem_line.className = ''
        elem_sm.className = 'btnSelect'
      }
    } else {
      this.alerts_selected = [ ... this.alerts_selected , data]
      // Change css class
      if(elem !=null && elem_line != null && elem_sm!=null ){
        elem.className = 'btnSelected'
        elem_line.className = 'selectedBackground'
        elem_sm.className = 'btnSelected'}
    }
  }

  selectAll():void{
    if(this.alerts_selected.length == 0) {
      this.alerts_selected = this.alerts.map((a:Alert)=> a.id)
      let len = this.alerts_selected.length
      for(let i = 0; i<len ; i++){
        let elem = document.getElementById(i.toString())
        let elem_line = document.getElementById(i.toString()+'_alert')
        let elem_sm = document.getElementById(i.toString()+'_sm')

        if((elem !=null && elem_line !== null) && (elem_sm !=null) ){
          elem.className = 'btnSelected';
          elem_line.className = 'selectedBackground'
          elem_sm.className = 'btnSelected';
        }
      }
    } else {
      let len = this.alerts.length
      this.alerts_selected = []
      for(var i = 0; i<len ; i++){
        let elem = document.getElementById(i.toString())
        let elem_line = document.getElementById(i.toString()+'_alert')
        let elem_sm = document.getElementById(i.toString()+'_sm')

        if((elem !=null  && elem_line !== null ) && (elem_sm !=null ) ){
          elem.className = 'btnSelect';
          elem_line.className = ''
          elem_sm.className = 'btnSelect'
        }
      }
    }
  }

  markAsRead():void{
    if(this.alerts_selected.length>0){
      let payload = { notifications:this.alerts_selected }
      this.alertsService.readAlerts(payload).subscribe((res)=>{ })
      //location.reload()
      this.reloadLocation()
    }
  }

  reloadLocation():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  deleteAll():void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { data: this.alerts_selected }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(AlertsDeleteComponent,dialogConfig)
  }

  filterAlert(data:string): void{
    if(data !==undefined){
      const alertList = this.alerts_all.filter(a=>a.tittle.toLowerCase().includes(data.toLowerCase()))
      this.alerts = alertList
    } else{
      this.alerts = this.alerts_all
    }
  }

  showDeleteAlert(data:string):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { data: [data] }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(AlertsDeleteComponent,dialogConfig)
  }


  readAlert(data:Alert):void{
    this.read_alert = true
    this.picked_alert = data
  }


  seeAllAlerts(): void {
    const alertsShortList = (this.alerts_all.length<=3 ? this.alerts_all : this.alerts_all.slice(0,3))
    this.alerts = (this.buttonName == "See Alerts") ? this.alerts_all : alertsShortList
    this.buttonName = (this.buttonName == "See Alerts") ? "Hide Alerts" : "See Alerts"
  }

  refreshAlerts():void{
    this.alertsService.getAlerts().subscribe({
      next:(res)=>{
        this.alerts_all = res.ret.filter((al:Alert)=> al.type !== 0)
        this.alerts_count = this.alerts_all.filter((a:any) => a.read === 0).length
        this.alerts = this.alerts_all.slice(0,3)
      }})
  }

  ngOnInit() {
    this.refreshAlerts()
  }
}
