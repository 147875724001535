import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {CardService, EditCardGLLimit} from "../../../../services/card.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {Subject} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CardAddNewComponent} from "../../CardNew/card-add-new/card-add-new.component";
import {CardsSuccessMsgComponent} from "../../cards-success-msg/cards-success-msg.component";
import {CardLimitMsgComponent} from "../card-limit-msg/card-limit-msg.component";


@Component({
  selector: 'app-card-limit-gl',
  templateUrl: './card-limit-gl.component.html',
  styleUrls: ['./card-limit-gl.component.css']
})
export class CardLimitGlComponent {

  @Input() validateOn: string = 'change'
  @Input() isGeneralLimit: Subject<boolean> = new Subject<boolean>();
  @Input() editData !:EditCardGLLimit

  @Input() isEditData:boolean = false
  isDisplayData :boolean = true

  constructor(
    private router : Router,
    private cardService : CardService,

  ) { }

  isDeleteGeneralLimit  :boolean = false
  fieldWasEdited: any = {}
  fieldsConfig !: FormFieldConfig[]
  validationErrors : any = {}
  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:any) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }

  getEmptyForms():void {
    this.fieldsConfig = this.cardService.formFieldsGenLimit
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''
    this.fieldValues = {...this.fieldValues , ['type'] : 0}
    this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}
  }

  checkConfirmDelete(data:boolean):void {
    this.isDeleteGeneralLimit = data
  }

  deleteGeneralLimit(data:boolean):void {
    this.isDeleteGeneralLimit = false
    if(data === true) {

      let payload = {
        spendinglimit_id: this.editData.spendinglimit_id,
        card_id:this.editData.card_id,
        loan_id:this.editData.loan_id,  limited: 1
      }

      this.cardService.deleteSpendLimit(payload).subscribe({ next:(res) =>{
          this.isDisplayData = false
          this.fieldValues = undefined
      }})

      const currentUrl = '/main/cards';
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);})
    }
  }
  returnLimitData(){
    return this.fieldValues
  }
  ngOnInit() {
    this.getEmptyForms()

    // Hide html display to the user
    if( this.isEditData === true && this.editData === undefined  ) {
      this.isDisplayData = false
    } else {
      this.isDisplayData = true
    }

    // Display current GL limit to the user
    if (this.isEditData){

      let keyObject = Object.keys(this.editData)
      let valuesObject = Object.values(this.editData)

      for(let key of Object.keys(this.fieldValues) ) {
       this.fieldValues[key] = valuesObject[keyObject.indexOf(key)] || ""
        this.fieldWasEdited[key] = true
      }
      this.fieldValues = {...this.fieldValues ,
        ['merchants_info'] : [],
        ['spendinglimit_id'] : valuesObject[keyObject.indexOf('spendinglimit_id')],
        ['type'] : 0
      }

      this.validationErrors = {}

    } else {
      this.isGeneralLimit.subscribe(response => { // if true means user has selected to edit GL (child) in SetSpendLimit (parent)
        if(response === true){
          this.getEmptyForms()
          this.fieldValues = {...this.fieldValues , ['type'] : 0}
          this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}
        } else {
          this.getEmptyForms() // Empty forms when user decides for other limit
          this.validationErrors = {}
        }
      })
    }
  }

}
