import { Injectable } from '@angular/core';
import {myCreditScore} from "../components/Accounts/credit-score/mockData";
import {ApiService} from "./api.service";

export interface CSRecord {
  date: string,
  score: number
}
export interface CreditScore  {
  userId: string,
  rating: number,
  ratingText: string,
  date_updated: string,
  score_histo: CSRecord[],
  isEnrolled: Boolean,
  checkProcess: Boolean
}

@Injectable({
  providedIn: 'root'
})
export class CreditscService {

  creditScore: CreditScore = myCreditScore

  constructor(private api: ApiService) { }

  getCreditScore() {
    // this.api.get('/creditScore/').subscribe(
    //   res => {
    //     this.creditScore = res as CreditScore;
    //   }
    // );
    // return <CreditScore>this.creditScore;
  }
}
