<div class="container-fluid" >
  <app-modal-mid-error>
    <div class="row my-5 text-center">
      <div>
        <img src="assets/images/errors/failure.svg" class="img-fluid">
      </div>
    </div>
    <div class="row text-center my-2">
      <p class="p-title-error">Something went worng</p>
    </div>
    <div class="row text-center my-2">
      <p id="p-status" class="p-text fw-bold">{{status}}</p>
      <p id="p-status-text" class="p-text">{{statusText}}</p>

      <div *ngIf="errorsList" >
        <div *ngFor="let err of this.errorsList" id="error-list">
          <p class="p-text fw-bold" >{{err}}</p>
        </div>
      </div>

      <p class="p-text fw-bold my-2">Close this message and continue banking with Kredio.</p>
    </div>
    <div class="row  ">
      <div class=" col-lg-8 offset-lg-2  col-12 text-center my-2">
        <button id="btnClose" class=" py-3 rounded-3 w-100 px-2"
                (click)="closeMessage()"> Close </button>
      </div>
    </div>
  </app-modal-mid-error>

</div>











<!--<div class="modal-header">-->
<!--  <h4 class="modal-title">{{ title }}</h4>-->
<!--  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.dismiss()">-->
<!--    <span aria-hidden="true">&times;</span>-->
<!--  </button>-->
<!--</div>-->
<!--<div class="modal-body">-->
<!--  <div class="alert" role="alert">-->
<!--    <p><strong>HTTP code:</strong> {{ status }}</p>-->
<!--    <p><strong>Code description:</strong> {{ statusText }}</p>-->
<!--    <p class="text-wrap text-break"><strong>URL: </strong> {{ url }}</p>-->
<!--    <p class="text-wrap text-break"><strong>Error message: </strong> {{ message }}</p>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="modal-footer">-->
<!--  <button type="button" class="btn btn-primary" (click)="this.activeModal.dismiss()">Close</button>-->
<!--</div>-->

