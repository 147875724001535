import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {HttpEvent} from "@angular/common/http";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  checkMaxLengthValidator,
  checkMinValueValidator,
  isNotEmptyValidator
} from "../components/Shared/forms-shared/validators";

export interface TransferKredio {
  loan_id: string,
  receiver_id:string,
  amount:string,
  memo: string,
  receiver_type:number
}

export interface TransferKredioRec {
  loan_id: string,
  receiver_id: string,
  network: number
  amount:string,
  frequency:number,
  memo?: string,
  send_day: number,
  end_date: string,
}
@Injectable({
  providedIn: 'root'
})
export class TransferLocalService {

  fieldsConfig: FormFieldConfig[] = [
    {
      name: 'loan_id',
      displayName: 'Sender',
      type: 'dropdown',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'receiver_id',
      displayName:'Receiver',
      type: 'dropdown',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'amount',
      displayName:'Amount',
      type: 'string',
      validators : [isNotEmptyValidator, checkMinValueValidator(10)],
    },
    {
      name: 'memo',
      displayName:'Memo',
      type: 'string',
      validators : [checkMaxLengthValidator(100), isNotEmptyValidator],
    },
    {
      name: 'receiver_type',
      type: 'number',
      validators : [isNotEmptyValidator],
    }
  ]
  fieldsConfigRec: FormFieldConfig[] = [

    {
      name: 'frequency',
      displayName:'Frequency',
      type: 'dropdown',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'send_day',
      displayName:'Send on',
      type: 'dropdown',
      validators:[isNotEmptyValidator]
    },

    {
      name: 'end_date',
      displayName:'Duration',
      type: 'string',
      validators:[isNotEmptyValidator]
    }

  ]
  fieldsConfigAll : FormFieldConfig[] = [

    {
      name: 'loan_id',
      displayName: 'Sender',
      type: 'string',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'receiver_id',
      displayName:'Receiver',
      type: 'string',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'amount',
      displayName:'Amount',
      type: 'string',
      validators : [isNotEmptyValidator, checkMinValueValidator(10)],
    },
    {
      name: 'memo',
      displayName:'Memo',
      type: 'string',
      validators : [checkMaxLengthValidator(100), isNotEmptyValidator],
    },
    {
      name: 'frequency',
      displayName:'Frequency',
      type: 'string',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'send_day',
      displayName:'Send on',
      type: 'string',
      validators:[isNotEmptyValidator]
    },

    {
      name: 'end_date',
      displayName:'Duration',
      type: 'string',
      validators:[isNotEmptyValidator]
    },

    {
      name: 'network',
      type: 'number',
      validators:[isNotEmptyValidator]
    },
  ]
  constructor(
    private api : ApiService
  ) { }


  makeLocalTransfer(data: TransferKredio): Observable<any>{
    return  this.api.post('/transfer/', data)
  }

  addRecurrentPayment(data: TransferKredioRec):Observable<any>{
    return this.api.post('/add_recurring/', data)
  }


}
