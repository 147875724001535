<div class="container-fluid h-100" >
  <!-- Logo -->
  <div class="row">
    <img class="col-10 offset-1 my-5" src="assets/images/nav-bar/logo.svg" alt="Kredio Logo"/>
  </div>

  <!-- Vertical Menu Large Screens -->
  <div class="row " >

      <a id="home_rl" routerLink ="/main/home"   class="p-3 pb-5 rounded-3">
        <div class="img">
          <img id="img_home" src="assets/images/nav-bar/home_icon.svg" class="me-3 img-tag" alt="Home"><p class="d-inline">Home</p>
          <img id="img_home_hover" src="assets/images/nav-bar/home_hover.svg" class="me-3 hover_img" alt="Home">
        </div>
      </a>

      <a id="acc_rl" routerLink ="/main/accounts" class="p-3 pb-5 rounded-3" >
        <div class="img">
          <img id="img_acc" src="assets/images/nav-bar/accounts_icon.svg" class="me-3 img-tag" alt="Accounts"><p class="d-inline">Accounts</p>
          <img id="img_acc_hover" src="assets/images/nav-bar/accounts_hover.svg" class="me-3 hover_img" alt="Accounts" >
        </div>
      </a>

      <a id="cards_rl" routerLink ="/main/cards"   class="p-3 pb-5 rounded-3">
        <div class="img">
          <img id="img_cards" src="assets/images/nav-bar/cards_icon.svg" class="me-3 img-tag" alt="Cards"><p class="d-inline">Cards</p>
          <img id="img_cards_hover" src="assets/images/nav-bar/cards_hover.svg" class="me-3 hover_img" alt="Cards">
        </div>
      </a>

      <a id="tr_rl" routerLink ="/main/transactions"  class="p-3 pb-5 rounded-3">
        <div class="img">
          <img id="img_tr" src="assets/images/nav-bar/transactions_icon.svg" class="me-3 img-tag" alt="Cards"><p class="d-inline">Transactions</p>
          <img id="img_tr_hover" src="assets/images/nav-bar/trasactions_hover.svg" class="me-3 hover_img" alt="Transactions">
        </div>
      </a>

  </div> <!--  Close vertical menu div -->

</div> <!--  Close container -->
