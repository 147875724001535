import {Component, Input, OnInit} from '@angular/core';
import {Account, AccountService} from "../../../services/account.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import numbers = _default.defaults.animations.numbers;

@Component({
  selector: 'app-acc-statements',
  templateUrl: './acc-statements.component.html',
  styleUrls: ['./acc-statements.component.css']
})
export class AccStatementsComponent  implements OnInit{

  @Input()  url_display !:string
  @Input()  accounts !:Account[]

  year_last = new Date().getFullYear()
  years !: number[]
  accounts_data!:string[][]

  statements_data !: string[][] | undefined

  display_scroll !:boolean

  isRequested :boolean = false

  fieldsConfig !: FormFieldConfig[]

  validateOn: string = 'change'
  _fieldValues: any = {}

  validationErrors : any = {}
  fieldWasEdited: any = {}
  constructor(
    private accountService: AccountService
  ) { }
  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  updateFieldValue(fieldName:string, newValue:string) {
    if(fieldName !=='loan_id') {
      this.fieldValues = { ... this.fieldValues, [fieldName]: Number(newValue)}
    } else {
      this.fieldValues = { ... this.fieldValues, [fieldName]: newValue }
    }

  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setInitialParamenters() :void {
    this.fieldsConfig = this.accountService.formFieldsAccStatement

    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ""
  }

  selectStatement():void {

    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }
    this.accountService.getAccountStatement(this.fieldValues).subscribe({next:(res)=>{
      if(res.e_code ===1) {
        this.isRequested = true
        let data = res.res
        if (data.length>0) {
          this.statements_data = data.map((data:any) => [data.dt_end , data.doc_url])
        } else {
          this.statements_data = undefined
        }

        if(this.statements_data !==undefined) {
          this.display_scroll = this.statements_data.length>4 ? true : false
        }

      }
    }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }

  generateYears():void {
    let arr = Array.from(Array(5).keys());
    this.years = arr.map((num:number) => this.year_last - num )
  }

  ngOnInit() {

    let arr = Array.from(Array(5).keys());
    this.years = arr.map((num:number) => this.year_last - num )

    this.fieldsConfig = this.accountService.formFieldsAccStatement

    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ""

    this.accountService.getAccounts().subscribe({ next:(res) =>{
        if(res.e_code === 1){
          this.accounts = res.accounts
          this.accounts_data = this.accounts.map((acc:Account)=>
            [acc.id, "Kredio" + acc.number.toString().slice(-4)])
        }
      }})

  } // Close ngOnInit
}
