<div class="container-fluid ">

  <div class="row mt-1" >

    <div class="col-lg-1 col-2 p-3 d-flex justify-content-center " >
      <img class="img-fluid " src="assets/images/rec-payments/trash_button.svg">
    </div>

    <div class="col-lg-10 col-8 d-flex align-items-center">
      <p class="p-title my-2 "
      >Delete Recurrent Payment</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex justify-content-center  cancel-edit" >
      <img (click)="cancelDeleteRecPayment()" class="img-fluid button-pointer"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row my-2  mx-1 ">
    <p class="p-wording px-2 ">Confirm you agree to delete this recurrent payment</p>
  </div>

  <div class="row mx-1 ">

    <div class="col-lg-4 col-12 px-2 my-2 ">
      <div class="rounded-4 border-row h-100 w-100 py-2 ps-4 ">
        <div>
          <p  id="p_sender" class="p-data mb-0 ">{{sender_name}}
          </p>
          <p id="p_rn" class="p-data-sm mb-0">Routing No. {{sender_route_number}}</p>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-12 px-2 my-2 ">
      <div class="rounded-4 border-row h-100 w-100 py-2 ps-4 ">
        <div>
          <p  id="p_receiver" class="p-data mb-0 ">{{receiver_name}}
          </p>
          <p   id="p_net" class="p-data-sm mb-0">{{network}}</p>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-12 px-2 my-2 ">
      <div class="rounded-4 border-row h-100 w-100 py-2 ps-4 ">
        <div>
          <p id="p_amount" class="p-data mb-0 ">${{amount|number :'.2'}}
          </p>
          <p class="p-data-sm mb-0">Amount</p>
        </div>
      </div>
    </div>




  </div>

  <div class="row my-4 mx-1">
    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center align-items-center">
      <button class="yellow-button   py-3 w-100 rounded-4 button-pointer  " (click)="deletePayment()">Delete </button>
    </div>

    <div class="col-lg-6 col-12  my-2 d-flex justify-content-center align-items-center">
      <button class="violet-button  w-100 py-3 rounded-4 button-pointer" (click)="cancelDeleteRecPayment()">Cancel</button>
    </div>
  </div>

</div>
