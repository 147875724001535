<div class="container-fluid h-100">
      <app-nav-buttons
        [current_route]= your_route
      ></app-nav-buttons>

      <div *ngIf="isEnrolledPayroll">

        <div class="row mt-3 px-lg-4" >
          <div class="card border-0" >
            <div class="card-body " >

              <div class="row">
                <div class="col-lg-6 col-12">
                  <p class="p-title">Get your money as you earn it</p>
                </div>
              </div> <!--Close row-->

              <div class="row mt-3">
                      <div class="col-6 ">
                        <div class="div-button w-100 rounded-4 d-flex align-items-center justify-content-center"
                            id="btnTransfer" (click)="transferWages()">
                          <div class="img-fluid mx-2">
                            <img src="assets/images/payroll/transfer_wages.svg">
                          </div>

                          <p class="p-button p-button rounded-4 py-2  button-pointer my-2"> Set & Update Transfer Wages</p>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="div-button w-100 rounded-4 d-flex align-items-center justify-content-center"
                             (click)="unenrollPayroll()">
                          <div class="img-fluid mx-2" >
                            <img src="assets/images/payroll/unenroll.svg">
                          </div>

                          <p class="p-button rounded-4 py-2  button-pointer my-2">Unenroll Payroll</p>
                        </div>
                      </div>
                    </div> <!--Close row-->

            </div> <!--Close card body-->
          </div> <!--Close rounded card-->
        </div> <!--Close rounded row-->

        <div class="row mt-3 px-lg-4 "  >
          <div class="card border-0" >
            <div class="card-body " >
                <app-payroll-display
                [payrollHisto_display]="payrollHisto_display"
                [payroll_histo]="payroll_histo"
                ></app-payroll-display>
            </div> <!--Close card body-->
          </div> <!--Close rounded card-->
        </div> <!--Close rounded row-->

      </div> <!--Close ngif is enrolled-->

      <div *ngIf="!isEnrolledPayroll">
        <div class="row mt-3 px-lg-4" >
          <div class="card card-payroll" >
            <div class="card-body " >

              <div class="row ms-4">

                    <div *ngIf="!hasAddedPayroll">

                      <div class="row">
                        <div class="d-flex justify-content-center my-4 ">
                          <p class="p-enroll">Enroll to live Payroll</p>
                        </div>
                      </div>

                      <div class="row my-2">
                        <p class="p-enroll-small">Unforseen circumstances don't wait until payday</p>
                        <p class="p-enroll-small">Live payroll puts you in control and gives you access to
                          the wages you already earned without waiting for the payday</p>
                      </div>

                      <div class="row my-3">
                        <p class="p-enroll-small-color py-1">With live payroll, you:</p>
                        <div class="d-flex" >
                          <div class="me-2" >
                            <img src="assets/images/payroll/check.svg">
                          </div>
                          <p class="p-enroll-small fw-bold py-1">Get your money as you earn it</p>
                        </div>

                        <p class="p-enroll-small my-2">Your available earned balance increases</p>

                        <div class="d-flex">
                          <div class="me-2">
                            <img src="assets/images/payroll/check.svg">
                          </div>
                          <p class ="p-enroll-small fw-bold my-1">Access your earned wages at no cost</p>
                        </div>

                        <p class="p-enroll-small my-2">Kredio can let you access your money when you need it,
                          at little or no cost to you</p>

                      </div>





                      <div class=" row d-flex justify-content-center my-4">
                        <div class="col-lg-6 col-12 text-center">
                          <button id="btnEnroll" class="yellow-button rounded-4 py-4 w-75 button-pointer my-2"
                                  (click)="enrollPayroll()"
                          >Continue to Enroll</button>
                        </div>


                        <div class="col-lg-6 col-12 text-center">
                          <button id="btnCancel"  class="violet-button rounded-4 py-4 w-75 button-pointer my-2"
                                  (click)="cancelEnrollment()"
                          >Cancel</button>
                        </div>

                    </div>

                    </div> <!--Closes has NOT addedPayroll -->

                    <div *ngIf="hasAddedPayroll">

                      <app-modal>

                        <div class="row ">

                          <div class="col-md-11 col-10 p-0 d-flex " >
                            <p class="p-enroll-small fw-bold ms-3 mb-0">Enroll to live Payroll</p>
                          </div>

                          <div class="col-md-1 px-md-1 col-2 px-2 d-flex align-items-center justify-content-center button-pointer ">
                            <img class=" img-fluid "  (click)="cancelEnrollmentModal()"
                                 src="assets/images/payroll/close.svg"/>
                          </div>

                        </div>

                            <div class="row my-3">
                              <div  class="text-center ">
                                <img class="img-fluid" src="assets/images/payroll/enrolled.svg">
                              </div>
                            </div>

                            <div class="row mb-5">
                              <p class="p-enroll-small fw-bold text-center mt-3">You are ready to access your daily earned wages
                                with Kredio's live payroll</p>

                              <p class="p-enroll-small text-center px-3">Now you will be able to see your earned wages any time on the
                                home page and transfer money from the available amount into your account</p>
                            </div>
                            <div class="row ">
                              <div class="col-md-6 offset-md-3 col-12 mt-3 mb-2">
                                <button id="btnRedirect" class="violet-button rounded-3 p-3 my-4 w-100"
                                        (click)="redirectToArgylePayroll()">Enroll now
                                </button>
                              </div>
                            </div>

                        </app-modal>

                     </div> <!--Close has addedPayroll -->
              </div>
            </div> <!--Close card body-->
          </div> <!--Close rounded card-->
        </div> <!--Close rounded row-->
      </div> <!--Close ngif is NOT enrolled-->


  <div class="d-lg-none d-block" style="height: 5rem;"></div>


  <div *ngIf="unenrolled">
    <app-modal>
      <app-payroll-unenroll></app-payroll-unenroll>
    </app-modal>
  </div>

</div> <!--Close container-->




