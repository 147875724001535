
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {
  Router} from "@angular/router";
import {SharedataService} from "../../../services/sharedata.service";
import {ProfileService} from "../../../services/profile.service";
import {fromEvent, Subject, takeUntil} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-legal-terms',
  templateUrl: './legal-terms.component.html',
  styleUrls: ['./legal-terms.component.css']
})
export class LegalTermsComponent implements OnInit{

  @ViewChild('accDepositAgreement')  accDepositAgreementBtn !: ElementRef;
  @ViewChild('eSignatureAgreement')  eSignatureAgreementBtn !: ElementRef;
  @ViewChild('policyPrivacy')  policyPrivacyBtn !: ElementRef;
  @ViewChild('policyPrivacyCBR')  policyPrivacyCRBBtn !: ElementRef;
  @ViewChild('patrioticAct')  patrioticActBtn !: ElementRef;
  // @Output() legalTerms = new EventEmitter<any>()
  // @Output() continueConfirm = new EventEmitter<boolean>()


  userId !: string
  accAgreementComplete: boolean = false

  eSignaturePolicyComplete: boolean = false
  privatePolicyComplete: boolean = false

  privatePolicyCRBComplete: boolean = false
  USAPActComplete: boolean = false

  docsComplete: boolean = false
  alert_msg :boolean = false



  // Display policy content from BE

  policy_e_signature !: string
  policy_depositAccount !: string
  policy_privacy !: string
  policy_patriotic_act !: string
  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string

  fieldsConfig !: FormFieldConfig[]

  constructor(
    private router: Router,
    private shareService : SharedataService,
    private profileService: ProfileService,
    private sanitizer: DomSanitizer
  ) { }


  validateOn: string = 'change'
  fieldWasEdited: any = {}
  validationErrors : any = {}

  fieldValuesEmpty: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  // Handle input values
  updateFieldValue(fieldName: string, newValue: string) {
    if(fieldName === 'deposit_account_agreement' && this.fieldValues['deposit_account_agreement'] === newValue ) {
      this.fieldValues = {...this.fieldValues, 'deposit_account_agreement': ''}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()

    } else if(fieldName === 'esig_disclosure' && this.fieldValues['esig_disclosure'] === newValue ) {
      this.fieldValues = {...this.fieldValues, 'esig_disclosure': ''}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()

    } else if(fieldName === 'privacy_policy_kredio' && this.fieldValues['privacy_policy_kredio'] === newValue ) {
      this.fieldValues = {...this.fieldValues, 'privacy_policy_kredio': ''}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()

    } else if(fieldName === 'privacy_policy_crb' && this.fieldValues['privacy_policy_crb'] === newValue ) {
      this.fieldValues = {...this.fieldValues, 'privacy_policy_crb': ''}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()

    } else if(fieldName === 'patriot_act' && this.fieldValues['patriot_act'] === newValue ) {
      this.fieldValues = {...this.fieldValues, 'patriot_act': ''}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()
    } else {
      this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
      this.updateButtonStyle(fieldName)
      this.completeLegalDocs()
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }


  private unsubscriber: Subject<void> = new Subject<void>();
  preventBrowsingHistory():void{
    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
      });
  }
  redirectToFile(url:string){
    window.open(url, "_blank");
  }
  pfdToEmbed(url:string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  checkESignature(){
    if(this.eSignaturePolicyComplete == false){
      this.accAgreementComplete = false
      this.privatePolicyComplete = false
      this.privatePolicyCRBComplete = false
      this.USAPActComplete = false

      // Force user restart legal terms
      this.fieldValues = this.fieldValuesEmpty
      this.validationErrors = {}

    }
  }
  updateButtonStyle(name:string){
    if (name == 'esig_disclosure') {
      this.eSignaturePolicyComplete = !this.eSignaturePolicyComplete
      this.checkESignature()
    } else if(name == 'deposit_account_agreement') {
      this.accAgreementComplete = !this.accAgreementComplete
      this.checkESignature()
    }  else if (name == 'privacy_policy_kredio') {
      this.privatePolicyComplete = !this.privatePolicyComplete
      this.checkESignature()
    }  else if (name == 'privacy_policy_crb') {
      this.privatePolicyCRBComplete = !this.privatePolicyCRBComplete
      this.checkESignature()
    } else if( name == 'patriot_act'){
      this.USAPActComplete = !this.USAPActComplete
      this.checkESignature()
    }
  }

  completeLegalDocs():void{
    if(
      this.fieldValues.deposit_account_agreement == '' ||
      this.fieldValues.esig_disclosure == '' ||
      this.fieldValues.privacy_policy_kredio == '' ||
      this.fieldValues.privacy_policy_crb == '' ||
      this.fieldValues.patriot_act == ''
    ){
      this.docsComplete = false
    } else {
      this.docsComplete = true
    }
  }

  agreeTermsOfUse():void {
    if(Object.keys(this.validationErrors).length>0){
      this.alert_msg = true
    } else {
      this.alert_msg = false
    }
  }

  submitForm(): void {

    this.fieldValues = {...this.fieldValues, ['user_id'] :this.userId}

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0) {
     this.alert_msg = true
      return
    } else {
      // Update fieldValues to match BE
      this.fieldValues = {...this.fieldValues, ['deposit_account_agreement'] : 1}
      this.fieldValues = {...this.fieldValues, ['esig_disclosure'] : 1}
      this.fieldValues = {...this.fieldValues, ['privacy_policy_kredio'] : 1}
      this.fieldValues = {...this.fieldValues, ['privacy_policy_crb'] : 1}
      this.fieldValues = {...this.fieldValues, ['patriot_act'] : 1}

      // Save Legal terms and redirect to /phone
      this.profileService.signLegalDicsKYC(this.fieldValues).subscribe({next:(res)=>{
          if(res.e_code ==1){
            this.router.navigateByUrl('/signup/phone')
          }
        }})
    }

  }

  closeAlertMsg():void{
    this.alert_msg = false
  }
  getUserId():void{
    this.shareService.getUserId().subscribe((value)=>{
      this.userId = value
    })
  }

  ngOnInit() {
    this.preventBrowsingHistory()

    this.getUserId()

    this.fieldsConfig = this.profileService.formFieldsLegal

    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.fieldValuesEmpty = this.fieldValues

    this.profileService.getLegalDicsKYC().subscribe({next:(res)=>{
        if(res.e_code == 1){

          if(res.res[0].name == "Deposit Account Agreement" ){
            this.policy_depositAccount = res.res[0].content
          }
          if(res.res[1].name == "E-Sign Disclosure" ){
            this.policy_e_signature = res.res[1].content
          }
          if(res.res[2].name == "Kredio Privacy Policy" ){
            // this.policy_privacy = res.res[2].content
            this.docsPrivatePolicy = res.res[2].doc
          }
          if(res.res[3].name == "Patriot Act" ){
            this.policy_patriotic_act = res.res[3].content
          }
          if(res.res[4].name == "CRB Private Policy" ){ // CHANGE INDEX NUMBER
            // this.policy_privacy = res.res[2].content
            this.docsPrivatePolicyCRB = res.res[4].doc
          }
        }
      }})

  }


}
