import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  checkEqualLengthFc, checkLengthChrValidator,
  checkMinValueValidator, isNotEmptyValidator, ssnValidator,
} from "../components/Shared/forms-shared/validators";

import {Observable, of} from "rxjs";


export interface Card {
  id: string,
  name: string,
  network: string,
  number: string,
  status: string,
  is_locked: boolean,
  balance: string,
  exp_date: string | null,
  is_primary: boolean,
  total_amount: string | null,
  period: number | null,
  type: number | null,
  start_period: string | null,
  end_period: string | null,
  current_spending: string,
  is_over_limit: boolean | null
  physical_active?: number,
  card_type:string
}

export interface ChildCardData  {
  first_name: string,
  last_name: string,
  ssn: string,
  dob:string
}
export interface ChildCardAdd  {
  profile_id: string,
  loan_id: string,
  name: string,
  limited:number
}
export interface CardAccount {
  account_id: string,
  number: number,
  card: Card
}
export interface CardSelected extends Card{
  account_id: string
}
export interface CardSetDefault{
  loan_id: string,
  card_id: string,
}
export interface CardBlock {
  loan_id: string,
  card_id: string
}
export interface CardCancel {
  loan_id: string,
  card_id: string
}
export interface NewCard {
  loan_id:string,
  name: string,
  limited: number
  // spending_limit: string
}
export interface SpendingLimit {
  loan_id: string,
  card_id: string,
  amount: string,
  period: number,
  type: number,
  merchants_info :string[]
}
export interface MerchantCard {
  merchants_info:string[],
  amount:string,
  period: string,
  type: number,
  spendinglimit_id ?:string
}
export interface MerchantSummary {
  merchants_info:string[],
  amount:string,
  period: string,
  type: number,
}
export interface DeleteSpendLimit {
  spendinglimit_id: string,
  card_id:string,
  loan_id:string
  limited:number
}
export interface ActivateCard {
  loan_id: string,
  card_id:string,
  card_number:string,
  card_exp_date:string,
  card_sec_code:string
}
export interface MCCCard {
  merchants_info:string[],
  amount:string,
  period:number | string,
  type ?: number,
  spendinglimit_id ?:string
}
export interface MCCData {
  mcc_name:string,
  mcc_code:string,

}
export interface MCCDataAll {
  amount: string,
  card_id: string,
  current_spending: string,
  dt_when: string,
  end_period: string,
  is_over_limit: boolean,
  loan_id: string,
  merchants: MCCData[],
  merchants_info:string [],
  period: string,
  spendinglimit_id: string,
  start_period: string,
  type: number
}
export interface CardLimitData {
  loan_id:string,
  card_id:string
}
export interface EditCardGLLimit {
  amount: string,
  card_id: string,
  current_spending: string,
  dt_when: string,
  end_period: string,
  is_over_limit: boolean,
  loan_id: string,
  merchants: string[],
  period: string,
  spendinglimit_id: string,
  start_period: string,
  type: number
}
@Injectable({
  providedIn: 'root'
})
export class CardService {
  formFieldsNewCard: FormFieldConfig[] = [
    {
      name: 'loan_id',
      displayName: 'loan_id',
      type: 'string',
      placeHolder: 'Link to Account',
      options:[],
      validators: [isNotEmptyValidator]
    },
    {
      name: 'name',
      displayName: 'Card Nickname',
      type: 'string',
      placeHolder: 'Card Nickname',
      validators: [isNotEmptyValidator, checkLengthChrValidator(4)]
    },
    {
      name: 'limited',
      displayName: 'Limited',
      type: 'number',
      validators: [isNotEmptyValidator]
    },
  ]
  formFieldsSpendLimit: FormFieldConfig[] = [
    {
      name: 'loan_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'card_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'amount',
      type: 'string',
      displayName:'Enter limit amount',
      validators: [isNotEmptyValidator,checkMinValueValidator(1)]
    },
    {
      name: 'period',
      type: 'number',
      displayName:'Select limit frequency',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'type',
      type: 'number',
      validators: []
    },
    {
      name: 'merchants_info',
      type: 'string',
      options:[],
    }
  ]
  formFieldsMCCLimit: FormFieldConfig[] = [
    {
      name: 'merchants_info',
      type: 'string',
      options:[],
      validators: [isNotEmptyValidator]
    },
    {
      name: 'amount',
      type: 'string',
      displayName:'Enter limit amount',
      validators: [isNotEmptyValidator,checkMinValueValidator(1)]
    },
    {
      name: 'period',
      type: 'number',
      displayName:'Select limit frequency',
      validators: [isNotEmptyValidator]
    }
  ]
  formFieldsMercLimit: FormFieldConfig[] = [
    {
      name: 'merchants_info',
      type: 'string',
      options:[],
      validators: [isNotEmptyValidator]
    },
    {
      name: 'amount',
      type: 'string',
      displayName:'Enter limit amount',
      validators: [isNotEmptyValidator,checkMinValueValidator(1)]
    },
    {
      name: 'period',
      type: 'number',
      displayName:'Select limit frequency',
      validators: [isNotEmptyValidator]
    }
  ]
  formFieldsGenLimit: FormFieldConfig[] = [
    {
      name: 'amount',
      type: 'string',
      displayName:'Enter limit amount',
      validators: [isNotEmptyValidator,checkMinValueValidator(1)]
    },
    {
      name: 'period',
      type: 'string',
      displayName:'Select limit frequency',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'type',
      type: 'number',
      validators: [isNotEmptyValidator]
    },
  ]
  formFieldsActivateCard: FormFieldConfig[]  = [
    {
      name: 'card_number',
      displayName: 'Card Number',
      type: 'string',
      validators: [isNotEmptyValidator, checkEqualLengthFc(16)]
    },
    {
      name: 'card_sec_code',
      displayName: 'Security Code',
      type: 'string',
      validators: [isNotEmptyValidator, checkEqualLengthFc(3)]
    },
    {
      name: 'card_exp_date',
      displayName: 'Expiration Date',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'loan_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'card_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    },
  ]
  formFieldsChildCardData: FormFieldConfig[] = [
    {
      name: 'first_name',
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'ssn',
      displayName: 'SSN Number',
      type: 'string',
      placeHolder: 'SSN Number',
      validators: [isNotEmptyValidator, ssnValidator]
    },
    {
      name: 'dob',
      displayName: 'Date of Birth',
      type: 'string',
      placeHolder: 'Date of Birth',
      validators: [isNotEmptyValidator]
    },
  ]
  formFieldsAddChildCard: FormFieldConfig[] = [
    {
      name: 'name',
      displayName: 'Card Name',
      type: 'string',
      placeHolder: 'Card Name',
      validators: [isNotEmptyValidator ]
    },
    {
      name: 'profile_id',
      displayName: 'Profile Id',
      type: 'string',
      placeHolder: 'Profile Id',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'loan_id',
      displayName: 'Loan Id',
      type: 'string',
      placeHolder: 'Loan Id',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'limited',
      displayName: 'Limited',
      type: 'string',
      placeHolder:'Limited',
      validators: [isNotEmptyValidator]
    },
  ]
  constructor(
    private api: ApiService
  ) { }

  getCards():Observable<any> {
    return  this.api.get('/cards/')
  }

  blockCard(data:CardBlock){
    return  this.api.post('/lock_card/',data)
  }

  unblockCard(data:CardBlock){
   return  this.api.post('/unlock_card/',data)
  }

  setDefaultCard(data:CardSetDefault){
   return  this.api.post('/set_default_card/',data)
  }

  addNewCard (data:NewCard){
    return this.api.post('/add_secondary_card/', data)
  }

  setSpendLimit(data:SpendingLimit):Observable<any>{
    return this.api.post('/set_spending_limit/',data)
  }

  editSpendLimit(data:any):Observable<any>{
    return this.api.post('/edit_spending_limit/', data)
  }
  getSpendLimit(data :CardLimitData):Observable<any>{
    return this.api.post('/get_spending_limit/', data)
  }

  deleteSpendLimit(data :DeleteSpendLimit):Observable<any>{
    return this.api.post('/delete_spending_limit/', data)
  }
  activateCard (data:ActivateCard){
    return this.api.post('/activate_card/', data)
  }

  processChildCardData(data:ChildCardData){
    return this.api.post('/process_pci_child_card/', data)
  }

  addChildCard(data:ChildCardAdd){
    return this.api.post('/add_child_card/', data)
  }

  cancelCard(data:CardCancel){
    return this.api.post('/cancel_card/', data)
  }

}
