import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {Router} from "@angular/router";
import {SignupService} from "../../../services/signup.service";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit{
  // @Input() inputProfileData :{ [key: string]: any } = {}
   @Output() submit = new EventEmitter<string>()


  // Confirm changes modal
  confirmChangesModal !:boolean
  isDataEdited :boolean = false
  dataEditSuccess !:boolean

  idTypes !: any[][]
  fieldsConfig !:FormFieldConfig[]
  inputLength: number = 0

  profileData !: ProfileEdit


  // Date of Birth
  today = new Date();
  dateOfBirth !: NgbDateStruct;
  maxDob =  new NgbDate(this.today.getFullYear() - 18, this.today.getMonth()+1, this.today.getDate());
  tempDOB !:NgbDateStruct


  dateOfIssue !: NgbDateStruct;
  maxAllowedDateOfIssue = new NgbDate(this.today.getFullYear() , this.today.getMonth()+1, this.today.getDate());
  tempDOI !:NgbDateStruct

  // Expiration Date 1Yr from today or more
  dateExpiration !: NgbDateStruct;
  minAllowedDateOfExp = new NgbDate(this.today.getFullYear() + 1, this.today.getMonth()+1, this.today.getDate());
  tempDOE !:NgbDateStruct

  // Verified Date
  dateVerif!: NgbDateStruct;
  maxAllowedDateOfVer = new NgbDate(this.today.getFullYear() , this.today.getMonth() +1, this.today.getDate());

  tempDOV !:NgbDateStruct

  constructor(
    private profileService: ProfileService,
    private signUpService: SignupService,
    private router: Router
  ) {}


  validateOn:string = 'change'
  validationErrors : any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues = newFieldValues
    if (this.validateOn == 'change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  confirmChanges():void{
    this.confirmChangesModal = false
  }

  submitEditProfile(data:boolean): void {

    this.confirmChangesModal = data

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0) {
      return
    }


    this.tempDOB = this.fieldValues['dob']
    const currentDay = `${this.tempDOB.year}-${this.tempDOB.month}-${this.tempDOB.day}`
    this.fieldValues = { ... this.fieldValues, 'dob': currentDay}

    this.tempDOI = this.fieldValues['id_issued_date']
    const currentIsDate = `${this.tempDOI.year}-${this.tempDOI.month}-${this.tempDOI.day}`
    this.fieldValues = { ... this.fieldValues, 'id_issued_date': currentIsDate}

    this.tempDOE = this.fieldValues['id_exp_date']
    const currentExpDate = `${this.tempDOE.year}-${this.tempDOE.month}-${this.tempDOE.day}`
    this.fieldValues = { ... this.fieldValues, 'id_exp_date': currentExpDate}

    this.tempDOV = this.fieldValues['id_verified_date']
    const currentVerDate = `${this.tempDOV.year}-${this.tempDOV.month}-${this.tempDOV.day}`
    this.fieldValues = { ... this.fieldValues, 'id_verified_date': currentVerDate}

    // Pass form data to parent component
      this.profileService.updateUserData(this.fieldValues).subscribe({
        next:(res)=>{
          this.isDataEdited  = true

              if (res.e_code ===1) {
                this.dataEditSuccess  = true
              }

              // Redirect to home page
              // setTimeout(()=>{
                const currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                })
              //}, 2000)
        }
      })
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }

  cancelEditProfile():void {
    this.submit.emit('isDisplayProfile')
  }

  getIDTypeList():void{
    this.profileService.getIdType().subscribe({next:(res)=>{
        if(res) {
          const newList = []
          for(let k of Object.keys(res)){
            newList.push([k,res[k]])
          }
          this.idTypes = newList
        }
      }
    })
  }
  ngOnInit() {

    // Get Id Types from BE
    this.getIDTypeList()
    this.fieldsConfig =this.profileService.formFieldsDisplay

    //Retrieve profile data
    this.profileService.getUserInfo().subscribe({ next:(res)=>{
        this.profileData = res.res

        let profileFields : string[] = Object.keys(this.profileData)
        let profileValues : string[] = Object.values(this.profileData)

        const date_fields:any = ['dob', 'id_issued_date', 'id_exp_date', 'id_verified_date']

        for (let field of this.fieldsConfig){
            let idx = profileFields.indexOf(field.name)
            this.fieldValues[field.name] = profileValues[idx] || ''

            if(date_fields.includes(field.name) === true){
              const b = profileValues[idx].split(/\D+/)
              const d = new Date(Number(b[0]),Number( b[1])-1, Number(b[2]))
              this.fieldValues[field.name] = { year : Number(b[0]) , month : Number(b[1]), day : Number(b[2])}

              if( field.name === 'dob') this.dateOfBirth = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
              if( field.name === 'id_issued_date') this.dateOfIssue = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
              if( field.name === 'id_exp_date') this.dateExpiration = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
              if( field.name === 'id_verified_date') this.dateVerif = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());

            }
          }
    }})
  }

}
