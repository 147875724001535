<div class="container-fluid" >

  <div class="row mt-3 mb-4" >

<!--    <div class="col-lg-1 col-2 p-0 d-flex align-items-center justify-content-center" >-->
<!--        <img (click)="cancelReport()" class="img-fluid h-100"-->
<!--             src="assets/images/accounts/report_cb.svg" />-->
<!--    </div>-->

    <div class="col-lg-11 col-10 d-flex align-items-center">
      <p id="title" class="p-title  d-none d-lg-block d-xl-block my-2" >Report to Credit Bureau - Authorization Request</p>

      <p id="subTitle" class="p-title   d-lg-none d-xl-none my-2" >Report to Credit Bureau</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex align-items-center justify-content-center  cancel-edit" >
      <img (click)="cancelReport()" class="img-fluid h-100"
           src="assets/images/edit_profile/cancel_edit.svg"
      />
    </div>
  </div>

  <div class="row text-center" >
    <div class="my-3">
      <img id="imgQuestion" src="assets/images/accounts/questionmark.svg">
    </div>

  </div>


  <div class="row " >
      <div class="col-10 offset-1 ">
        <p  id="p1" class="p-subtitle  text-center my-2" >
          Share your positive financial habits with credit bureaux
        </p>
        <p id="p2" class="p-wording w-100">
          Get credit for the bills you already pay (rent & utilities)
          and boost your credit score by start reporting them.
        </p>
        <p id="p3" class="p-wording  w-100">
          By clicking on “Agree”, you authorize us to obtain your consumer report.
        </p>
        <p id="p4" class="p-subtitle my-3">FPO</p>
        <p id="p5" class="p-wording  w-100">
          This authorization is valid for purposes of verifying information
          given pursuant to employment, leasing, rental or any other lawful purpose.
        </p>
      </div>

    <div class="my-4 text-center d-none d-lg-block d-xl-block">
      <button id="btnCancelReportL" class="w-75 p-3 my-3 border-0 rounded-4 "
              (click)="cancelReport()"
      >Agree</button>
    </div>


    <div class="my-3 text-center d-lg-none d-xl-none">
      <button id="btnCancelReportS" class="w-100 p-3 my-3 border-0 rounded-4 "
              (click)="cancelReport()"
      >Agree</button>
    </div>

  </div>

  <div class="row text-center">


  </div>

</div>
