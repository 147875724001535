<div class="container-fluid">

  <div class="row" >
    <div class="col-lg-1 col-2 p-0 d-flex align-content-around" >
      <img
        class="img-fluid m-0"
        src="assets/images/accounts/accounts_icon.svg"
       >
    </div>
    <div class="col-lg-10 col-8 d-flex align-content-center " >
      <p class="p-title my-2  fw-bold mb-0  " >Add New Account </p>
    </div>
    <div class="col-lg-1 col-2 p-0 d-flex align-content-around " >
      <img (click)="return()"
           class="img-fluid m-0"
           src="assets/images/accounts/close.svg">
    </div>
  </div> <!--Close row-->

  <div *ngIf="!isConfirmed">
    <div class="row text-center my-3" >
      <div>
        <img src="assets/images/accounts/warning.svg" alt="bullet">
      </div>
      <p id="confirmMsg" class="p-title my-4 fw-bold">Confirm you want to add a new account or cancel.</p>
    </div>

    <div class="text-center"  (click)="newAccount()">
      <button id="btnConfirm" class="py-3 px-5 rounded-4 border-0 w-100">Confirm</button>
    </div>

  </div>

  <div *ngIf="isConfirmed">
    <div class="row text-center my-3" >
      <div>
        <img src="assets/images/accounts/success.svg" alt="bullet">
      </div>

      <div class="row">

        <p id="successMsg" class="p-title my-4 fw-bold">A new account has been created successfully.</p>
      </div>

    </div>
    <div class="text-center"  (click)="return()">
      <button class="py-3 px-5 w-100 rounded-3 border-0" id="btnReturn">Return</button>
    </div>
  </div>

</div>
