<div class="container-fluid">
  <div class="row scroll-recpayments ">

    <!-- Responsive html format: large screens-->
    <div class="d-none d-lg-block">
      <table class="table ">
        <tbody *ngFor="let tr of next_payments">
        <tr >
          <td class="text-center">
            <p id="pSender" class="td-name mb-0">{{sender_name}}</p>
            <p id="pRoutNum" class="td-routeNum mb-0 ">Routing No. {{tr.routing}}</p>
          </td>
          <td id="pMemo" class="td-memo text-center">{{tr.memo}}</td>
          <td id="pAmount" class="td-amount text-center">${{tr.amount|number :'.2'}}</td>
          <td id="pDate" class="td-memo text-center">{{tr.due_date}}</td>
          <td id="btnCancel" class="text-center button-pointer"(click)="showDeletePayment(tr)" >
            <p class="tr-cancel mb-0 text-decoration-underline">Cancel this payment</p>
          </td>

        </tr>
        </tbody>

      </table>
    </div>

    <!-- Responsive html format: smaller screens-->
    <div  class=" d-lg-none" >
      <div *ngFor="let tr of next_payments" class="my-3">

        <div class="row text-center">
          <p class="td-name mb-0">{{sender_name}}</p>
          <p class="td-routeNum mb-0 fw-bold ">Routing No. {{tr.routing}}</p>
        </div>

        <div class="row my-2">

          <div class="col-4 p-0">
            <p class="td-memo-resp  mb-0 text-center">{{tr.memo}}</p>
          </div>
          <div class="col-4 p-0">
            <p class="td-amount-resp text-center mb-0 ">${{tr.amount|number :'.2'}}</p>
          </div>
          <div class="col-4 p-0">
            <p class="td-memo-resp text-center mb-0">{{tr.due_date}}</p>
          </div>

        </div>

        <div class="row text-center borderBottom pb-3 button-pointer">
          <p class="tr-cancel-resp mb-0 text-decoration-underline "
             (click)="showDeletePayment(tr)" >Cancel this payment</p>
        </div>

      </div>
    </div>

  </div>

</div>


<!--<div *ngIf="delete_payment==true">-->
<!--  <app-modal-mid>-->
<!--    <app-rec-pay-delete-->
<!--      [paymentData]="deleteData"-->
<!--      [deleteAllPayments]="isDeleteAll"-->
<!--    ></app-rec-pay-delete>-->
<!--  </app-modal-mid>-->
<!--</div>-->
