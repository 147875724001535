import {Component, OnInit,Input} from '@angular/core';
import {Transaction, TransactionService} from "../../../services/transaction.service";
import {Account, AccountService} from "../../../services/account.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";

import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TrDisputeMsgComponent} from "../../Transactions/tr-dispute-msg/tr-dispute-msg.component";

@Component({
  selector: 'app-accounts-tr-display',
  templateUrl: './accounts-tr-display.component.html',
  styleUrls: ['./accounts-tr-display.component.css']
})
export class AccountsTrDisplayComponent implements OnInit{
  @Input() year!:number
  @Input() month!: number
  productId !:string
  account_number !:string
  accounts_list !: Account[]
  transactions_list !:Transaction[]
  transactions_display !: Transaction[]

  displayTransactions : boolean = false

  constructor(
    private accountService : AccountService,
    private transactionsService : TransactionService,
    public dialog : MatDialog
  ) {}

  buttonName : string = 'See All Activity'
  disputeTransaction :boolean = false
  search_word !: string
  dispute_info !: any
  showAll : boolean = false
  account_numbers !: string[]
  account_ids !:string[]
  formFields: FormFieldConfig[] = [
    {
      name: 'list_accounts',
      displayName: 'Current Account View',
      type: 'dropdown',
      options:[],
      options_values:[]
    }]

  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
  }
  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
    this.getTransactions(newValue)
  }

  disputeThisTransaction(data: any): void {
    //
    // this.disputeTransaction = true
    // this.dispute_info = data
    // this.dispute_info = {...this.dispute_info , ['source']:0}
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:"Dispute Transaction",
      msg:"Please, contact customer service to dispute this transaction."
    }
    dialogConfig.panelClass = 'full-50-dialog';
    this.dialog.open(TrDisputeMsgComponent, dialogConfig)

  }

  seeAllActivity(): void {

    this.showAll = !this.showAll

    const displayShortList = (this.transactions_list.length<=3 ? this.transactions_list : this.transactions_list.slice(0,3))

    this.transactions_display = (this.showAll == true) ? this.transactions_list : displayShortList
    this.buttonName = (this.showAll == true) ? "Hide Activity" : "See all Activity"
  }

  filterTransaction(newValue:any):void{

    if(newValue){
      const filteredList = this.transactions_list.filter(t=>t.desc.toLowerCase().includes(newValue.toLowerCase()))
      this.transactions_display = filteredList
    } else {
      let payload =  { loan_id:this.productId, year:this.year, month:this.month,
        type_in: [], limit: 1000, period: 0}
      this.transactionsService.getTransactions(payload).subscribe({
        next:(res)=>{
          this.transactions_list = res.transfers.reverse()
          this.transactions_display = (this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3))
        }})
    }
  }

  getTransactions(prod:string):void {

    let payload = { loan_id:prod, year:this.year,
                    month:this.month, type_in: [],
                    limit: 1000, period: 0
                  }
    this.transactionsService.getTransactions(payload)
      .subscribe({ next: (res) =>{
          this.transactions_list = res.transfers.reverse()
          this.transactions_display = (this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3))


          if(this.transactions_list.length > 0) {
            this.displayTransactions = true
          } else{
            this.displayTransactions = false
          }
      } })


  }

  ngOnInit() {

    this.accountService.getAccounts().subscribe({ next:(res) =>{
        if(res.e_code === 1){

          let main_account = res.accounts.filter((acc:Account)=>acc.is_primary == 1)[0]
          this.productId = main_account.id
          this.account_number = main_account.number.toString()

          this.account_ids = res.accounts.map((acc:Account) => acc.id)

          this.account_numbers = res.accounts.map((acc:Account) => acc.number.toString())

          this.formFields[0].options = this.account_numbers
          this.formFields[0].options_values = this.account_ids

          this.fieldValues.list_accounts = this.productId

          this.getTransactions(this.productId);
        }
      }})

  }

}
