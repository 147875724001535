import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {CardLimitGlComponent} from "../card-limit-gl/card-limit-gl.component";
import {CardLimitMercComponent} from "../card-limit-merc/card-limit-merc.component";
import {CardLimitMccComponent} from "../card-limit-mcc/card-limit-mcc.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {CardLimitSummaryComponent} from "../card-limit-summary/card-limit-summary.component";
import {EditCardGLLimit} from "../../../../services/card.service";


@Component({
  selector: 'app-card-spend-limit-set',
  templateUrl: './card-spend-limit-set.component.html',
  styleUrls: ['./card-spend-limit-set.component.css']
})
export class CardSpendLimitSetComponent {

  @Input() loan_id !:string
  @Input() card_id !:string
  @Input() validateOn: string = 'change'
  @Output() closeSpendLimit  = new EventEmitter<boolean>();

  isGeneralLimitEE :Subject<boolean> = new Subject<boolean>()
  isMerchLimitEE :Subject<boolean> = new Subject<boolean>()
  isMCCLimitEE :Subject<boolean> = new Subject<boolean>()
  constructor(
    public dialog : MatDialog,

  ) { }

  //Access Child components
  @ViewChild(CardLimitGlComponent) GeneralLimitComponent:any ;
  @ViewChild(CardLimitMercComponent) MerchantLimitComponent:any ;
  @ViewChild(CardLimitMccComponent) CardLimitMccComponent:any ;

  // List of Limits
  limitsListMCC !: any[]
  limitsListMCCCodes !: any[]
  limitsListMerch !: any[]
  limitsListGL !: EditCardGLLimit

  // Choose type of limit
  isGeneralLimit :boolean = false
  isMerchantLimit :boolean = false
  isMCCLimit :boolean = false


  cancelSetLimit():void{
      this.closeSpendLimit.emit(false)
  }
  changeLimit(c:string,b:boolean){
    if( c === 'isGeneralLimit'){
      this.isGeneralLimit = b
      this.isGeneralLimitEE.next(b)
      if(b===false){ // Empty/clean GL data
        this.GeneralLimitComponent.ngOnInit()
      }
    }
    if( c === 'isMerchantLimit'){
      this.isMerchantLimit = b
      this.isMerchLimitEE.next(b)
      if(b===false){ // Empty/clean Merc data
        this.MerchantLimitComponent.ngOnInit()
      }
    }
    if( c === 'isMCCLimit'){
      this.isMCCLimit = b
      this.isMCCLimitEE.next(b)
      if(b===false){ // Empty/clean MCC data
        this.CardLimitMccComponent.ngOnInit() /// CHECK IMPORTANT MCC
      }
    }
  }

  submitLimits():void {
    // Check errors and display summary

    if(this.isGeneralLimit === true) {
      let errorsGL = this.GeneralLimitComponent.getvalidationErrors()
      if( errorsGL ==!{}) return
      this.limitsListGL = this.GeneralLimitComponent.returnLimitData()
    }
    if(this.isMerchantLimit === true) {
      let errorsML = this.MerchantLimitComponent.getvalidationErrors()
      if( errorsML ==!{}) return
      this.limitsListMerch = this.MerchantLimitComponent.returnMerchantData()
    }

    if(this.isMCCLimit === true) {
      let errorsMCC = this.CardLimitMccComponent.getvalidationErrors()
      if( errorsMCC ==!{}) return
      this.limitsListMCC = this.CardLimitMccComponent.returnMCCData()
      this.limitsListMCCCodes = this.CardLimitMccComponent.returnMCCCodes()
    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { glLimits :this.limitsListGL ,
      merchLimits : this.limitsListMerch,
      mccLimits : this.limitsListMCC,
      mccCodes : this.limitsListMCCCodes,
      card_id : this.card_id,
      loan_id : this.loan_id,
      isEdit:  false,
    //  getData: false,
    }
   if(
      dialogConfig.data.glLimits !==undefined ||
      dialogConfig.data.merchLimits !==undefined ||
      dialogConfig.data.mccLimits !==undefined
    ) {
     dialogConfig.panelClass = 'full-60-dialog';
     this.dialog.open(CardLimitSummaryComponent, dialogConfig)
   }

  }

}
