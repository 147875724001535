import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {CardService} from "../../../../services/card.service";
import {Card} from "../../../../services/card.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {CardsSuccessMsgComponent} from "../../cards-success-msg/cards-success-msg.component";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-card-add-new',
  templateUrl: './card-add-new.component.html',
  styleUrls: ['./card-add-new.component.css']
})
export class CardAddNewComponent implements OnInit{

  // New Card Options

  card_options = [['Virtual Card', 'virtual'],['New Card Holder','newHolder']]

  // Select type of new card
  selectTypeOfCard  :boolean = true
  newCardType !:string

  isSumbitDataPhyCard :boolean = false
  dateOfBirth !: NgbDateStruct

  tempDOB !:NgbDateStruct

  // Input data coming from /cards
  accountId !: string
  cardList !: Card[]

  // Display request card or result of request
  newCardHolder !:boolean


  // Form fields and validation tests
  fieldsConfig !: FormFieldConfig[]
  @Input() validateOn: string = 'change'
  validationErrors : any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  constructor(
    private router:Router,
    private cardService : CardService,
    public dialog : MatDialog,
    public dialogRef: MatDialogRef<CardAddNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  getvalidationErrors(isSubmit:boolean){

    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }

    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  selectCardType():void {

    if(this.newCardType === 'newHolder') {
      this.selectTypeOfCard = false
      // Request physical card
      this.newCardHolder = true

      this.fieldsConfig = this.cardService.formFieldsChildCardData

      // Set initial values and pass user email
      for(let field of this.fieldsConfig) {
        this.fieldValues[field.name] = ""
      }


    }

    else if(this.newCardType === 'virtual') { // Request virtual card
      this.selectTypeOfCard = false
      this.newCardHolder = false

      this.fieldsConfig = this.cardService.formFieldsNewCard

      // Set initial values and pass user email
      for(let field of this.fieldsConfig) {
        this.fieldValues[field.name] = ""
      }
      this.fieldValues["loan_id"] = this.accountId // Set as default. Remove to allow multiple options
      this.fieldValues['limited'] = 0 // CHANGE
    }

    else { return }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }

  cancelAddCard():void{
   this.dialogRef.close();
  }

  submitNewCardRequest(typ:string): void {

    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0)
      return

    if(typ === 'newVirtualCard') {

      this.cardService.addNewCard(this.fieldValues).subscribe({next:(res)=>{
          if(res.e_code == 1){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = { title: "Request Virtual Card" ,
              msg: "Your new virtual card has been successfully created." }
            dialogConfig.panelClass = 'full-30-dialog';
            this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
            this.dialogRef.close()
          }
        }})
    }

    else { // new physical card -> request data from new user

      this.tempDOB = this.fieldValues['dob']
      const currentDay = `${this.tempDOB.year}-${this.tempDOB.month}-${this.tempDOB.day}`
      this.fieldValues = { ... this.fieldValues, 'dob': currentDay}

      this.cardService.processChildCardData(this.fieldValues).subscribe({
      next:(res)=>{

        this.fieldsConfig = this.cardService.formFieldsAddChildCard

        for(let field of this.fieldsConfig) {
          this.fieldValues[field.name] = ""
        }

        this.fieldValues["loan_id"] = this.accountId
        this.fieldValues['limited'] = 1
        this.fieldValues['profile_id'] = res.profile_id

        this.isSumbitDataPhyCard = true
      }})
    }
  }

  addChildCard():void {
    this.dialogRef.close()
    this.cardService.addChildCard(this.fieldValues).subscribe({
      next:(res)=>{
        if(res.e_code == 1){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = { title: "Request New Card Holder" ,
            msg: "Your request has been processed. " +
              "The new physical card will be shipped to the address " +
              "available in the profile section." }
          dialogConfig.panelClass = 'full-30-dialog';
          this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
          this.dialogRef.close()
        }
      }})
  }

  ngOnInit() {
    // Inject data from card-add-new-button component
    this.accountId = this.data.accountId
    this.cardList = this.data.cardList
  }


}

// Virtual Card ---> limited:1
// Set up general limit/ Merchant or Merchant Category

// Add new card holder ---> limited : 0

