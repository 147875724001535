<div class="container-fluid h-100">
          <app-nav-buttons
            [current_route]= your_route
          ></app-nav-buttons>

    <!--   Phone Responsive Bar   -->
      <div class="row d-lg-none d-flex justify-content-center mx-3 bg-white rounded-4 p-2 ">

            <div id="btnMove" class="col-4 text-center hovering rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
                 (click)="moveMoney(move_money, send_money, request_money)" >
              <p class="title-text hovering title-text-mobile m-0" >
                Move Money
              </p>
            </div>
            <div id="btnSend" class="col-4 text-center hovering rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
                 (click)="sendMoney(move_money, send_money, request_money)">
              <p class="title-text hovering title-text-mobile m-0" >
                Send Money
              </p>
            </div>
            <div id="btnRequest" class="col-4 text-center hovering rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
                 (click)="requestMoney(move_money, send_money, request_money)">
              <p class="title-text hovering title-text-mobile m-0">
                Request Money
              </p>
            </div>
      </div>

      <!-- Move money white box -->
      <div class="row bg-white d-block d-lg-block rounded-4 p-3 m-3 ms-4" #move_money >
        <div>
          <div class="row">
            <p class=" title-text">Move Money</p>
          </div>

          <div class="row">
            <!-- First option -->
            <div class="col-lg-4 col-md-6  my-1" >
              <div id="internal" class="row rounded-4 box-borders hovering-box h-100 mx-1"
                   (click)="makeInternalTransfer()">
                <div class="col-2 d-flex align-items-center justify-content-center px-0" >
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/trans_bet_accts.svg" alt="trans_bet_accts"
                  >
                </div>
<!--                -->
                <div class="col-10" >
                  <div class="my-1">
                    <p class="box-text my-0">Transfer between my accounts</p>
                    <p class="sub-text my-0">including connected accounts</p>
                  </div>
                </div>

              </div>
            </div> <!-- First option ends-->

            <!-- Second option -->
            <div class="col-lg-4 col-md-6 my-1">
              <div id="local" class="row h-100 box-borders rounded-4 hovering-box mx-1"
                   (click)="makeLocalTransfer()" >
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/trans_another_c.svg" alt="trans_o_accts">
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Transfer money to another customer</p>
                    <p class="sub-text my-0">that also has a Kredio Account</p>
                  </div>
                </div>
              </div>
            </div> <!-- First option ends-->

            <!-- Third option -->
            <div class="col-lg-4 col-md-6 my-1">
              <div id="manage" class="row h-100 box-borders rounded-4 hovering-box mx-1" routerLink="/main/recpayments" >
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/trans_another_c.svg" alt="trans_o_accts">
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Manage recurrent payments</p>
                    <p class="sub-text my-0">scheduled to your recipients</p>
                  </div>
                </div>
              </div>
            </div> <!-- First option ends-->

            <!-- Fourth option -->
            <div class="col-lg-4 col-md-6 my-1 ">
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1"
                   (click)="greenDotTransfer()">
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/green_dot_logo.svg" alt="GD"
                  >
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Deposit cash with Green Dot</p>
                    <p class="sub-text my-0">Add cash balance to your account</p>
                  </div>
                </div>
              </div>
            </div> <!-- Fourth option ends-->

          </div>
        </div>
      </div> <!-- Move money white box ends here-->

      <!-- Send money white box -->
      <div class="row d-none d-lg-block bg-white rounded-4 p-3 m-3 ms-4" #send_money>
          <div class="row">
            <p class="title-text"> Send Money </p>
          </div>

          <div class="row">
            <!-- First option -->
            <div class="col-lg-4 col-md-6 my-1">
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1" >

                <div class="col-2  d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/zellePic.svg" alt="Zelle">
                </div>

                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Send money using <img src="assets/images/pay_tf/zelle_letters.svg" alt=""></p>
                    <p class="sub-text my-0">Send money to others using their phone number or email</p>
                  </div>
                </div>

              </div>
            </div> <!-- First option ends-->

            <!-- Second option -->
            <div class="col-lg-4 col-md-6 my-1" >

              <div class="row rounded-4 box-borders hovering-box h-100 mx-1" >
                <div class="col-2  d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/cashapp.svg" alt="CashApp"
                  >
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Send money using <img src="assets/images/pay_tf/cashapp_letters.svg" alt=""></p>
                    <p class="sub-text my-0">Send money to others using their Cashtag, phone number or email</p>
                  </div>
                </div>

              </div>
            </div> <!-- Second option ends-->

            <!-- Third option -->
            <div class="col-lg-4 col-md-6 my-1 " >

              <div class="row rounded-4 box-borders hovering-box h-100 mx-1" >
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                <img class="img-fluid w-75"
                     src="assets/images/pay_tf/ach.svg" alt="ACH"
                >
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Make an ACH Transfer</p>
                    <p class="sub-text my-0">Send money to other banks</p>
                  </div>
                </div>
              </div>
            </div> <!-- Third option ends-->

            <!-- Fourth option -->
            <div class="col-lg-4 col-md-6 my-1 ">
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1" >
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/int_transfer.svg" alt="IT"
                  >
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Make an international transfer</p>
                    <p class="sub-text my-0">Send money to your loved ones outside the United States</p>
                  </div>
                </div>
              </div>
            </div> <!-- Fourth option ends-->

        </div>
      </div> <!-- Move money white box ends here-->

      <!-- Request money white box -->
      <div class="row pt-3 d-none d-lg-block bg-white rounded-4 p-3 ms-4 m-3 request-box" #request_money>

          <div class="row">
            <p class="title-text"> Request Money</p>
          </div>

          <div class="row ">
            <!-- First option -->
            <div class="col-lg-4 col-md-6 my-1">
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1">
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/request_kredio.svg" alt="Request_Kredio">
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Request money with Kredio</p>
                    <p class="sub-text my-0">Request money to other Kredio users</p>
                  </div>
                </div>
              </div>
            </div> <!-- First option ends-->

            <!-- Second option -->
            <div class="col-lg-4 col-md-6 my-1" >
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1" >
                <div class="col-2  d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75" src="assets/images/pay_tf/zellePic.svg" alt="Zelle">
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Request money using <img src="assets/images/pay_tf/zelle_letters.svg" alt=""></p>
                    <p class="sub-text my-0">Send money to others using their Cashtag, phone number or email</p>
                  </div>
                </div>
              </div>
            </div> <!-- Second option ends-->

            <!-- Third option -->
            <div class="col-lg-4 col-md-6 my-1" >
              <div class="row rounded-4 box-borders hovering-box h-100 mx-1">
                <div class="col-2 d-flex align-items-center justify-content-center px-0">
                  <img class="img-fluid w-75"
                       src="assets/images/pay_tf/ach.svg" alt="ACH"
                  >
                </div>
                <div class="col-10">
                  <div class="my-1">
                    <p class="box-text my-0">Make an ACH Transfer</p>
                    <p class="sub-text my-0">Send money to other banks</p>
                  </div>
                </div>
              </div>
            </div> <!-- Third option ends-->

          </div>
      </div> <!-- Request money white box ends here-->

<!--    </div> &lt;!&ndash; Right side Transfers Large screens Ends &ndash;&gt;-->

    <div class="d-lg-none d-block" style="height: 5rem;"></div>

<!--  </div> &lt;!&ndash; Main row ends &ndash;&gt;-->

</div> <!-- Container End -->


<!-- Local transfer visible on large screens this.isLocalTransfer -->
<!--<div *ngIf="this.isInternalTransfer === true">-->
<!--  <app-modal-l-transfers>-->
<!--    <app-transfer-internal-->
<!--      [accounts_sender]="this.sender_accounts"-->
<!--      [accounts_receiver]="this.receiver_accounts"-->
<!--    ></app-transfer-internal>-->
<!--  </app-modal-l-transfers>-->
<!--</div>-->

<div *ngIf="this.isLocalTransfer === true">
  <app-modal-l-transfers>
    <app-transfer-local
      [accounts_sender]= "this.sender_accounts"
    ></app-transfer-local>
  </app-modal-l-transfers>
</div>







