<div class="container">
  <div class="row">
        <div class="col-10 d-flex">
          <img src="assets/images/payroll/u-payment.svg">
          <p class="p-title ms-2 mt-3">Unenroll to Live Payroll</p>
        </div>
  </div> <!--Close row -->

  <!--Confirm Unenrollment -->
  <div  *ngIf="isConfirmed === false" >

        <div class="row">
          <div class="d-flex justify-content-center my-5">
            <img src="assets/images/payroll/warning.svg">
          </div>
          <p class="p-first">Please confirm you want to unenroll to Live Payroll</p>
          <p class="p-second">Once you unenroll, you will not have access to your wages and not Kredio's benfits.</p>
        </div>

        <div class="row d-flex justify-content-center my-1">
          <div class="col-md-6 col-12">
            <button id="btnCancel"  class="yellow-button rounded-3 w-100 py-3 m-2"
                    (click)="cancelUnenrollment()" >Cancel</button>
          </div>
          <div class="col-md-6 col-12">
            <button id="btnUnrollUser" class="violet-button rounded-3 w-100 py-3 m-2 "
                    (click)="unrollUser()">Unenroll</button>
          </div>
        </div>

  </div>

  <!--Success Unenrollment -->
  <div class="row" *ngIf="isConfirmed === true">
    <div class="row">
      <div class="d-flex justify-content-center my-5">
        <img src="assets/images/payroll/success.svg">
      </div>
      <p class="p-first">You have unenrolled to Live Payroll</p>
      <p class="p-second">Return soon to continue enjoying Live Payroll benefits</p>
    </div>

    <div class="row d-flex justify-content-center my-1">
      <div class="col-md-6  col-12">
        <button class="violet-button rounded-3 w-100 py-3 m-2"
                (click)="cancelUnenrollment()">Continue</button>
      </div>

    </div>
  </div>


</div> <!--Close container -->
