  <div class="container-fluid h-100" >

    <div class="row p-1" >

      <div class="col-1 d-flex align-items-center justify-content-center p-0 " >
        <img  class="img-fluid  "  src="assets/images/edit_profile/profile_img.svg" />
      </div>

      <div class="col-9 d-flex align-items-center" >
        <p id="p_fullName"  class="p-full-name m-0 "  >{{profileData.first_name}} {{profileData.last_name}}</p>
      </div>

      <div class="col-2 d-flex align-items-center justify-content-center p-1 ">
        <img id="img_home" class=" img-fluid "
             (click)="redirectToUrl('/main/home')"
             src="assets/images/edit_profile/cancel_edit.svg"/>
      </div>

    </div>

    <!-- Account : isPersonalDetails == FALSE-->
    <div class="row my-4 menu-separator rounded-3 py-1" *ngIf="!isPersonalDetails">
      <p id="acc_title" class="text-kredio-hint">Account</p>
      <!-- Personal Details -->
      <div class="col-2 p-0 d-flex justify-content-center"  >
        <img id="img_pd" (click)="displayPersonalDetails()"
          class="img-fluid" src="assets/images/user-menu/personal_details.svg" >
      </div>
      <div class="col-9 col-sm-8" (click)="displayPersonalDetails()">
        <p id="p_pd" class="mt-2 option-menu mb-0">Personal Details</p>
      </div>
      <div id="btn_pd" class="col-1 col-sm-2 div-pointer p-0" (click)="displayPersonalDetails()">
        <img class="img-fluid " src="assets/images/user-menu/continue.svg" >
      </div>

    </div>

    <!-- Account : isPersonalDetails == TRUE -->
    <div class="row my-4 menu-separator rounded-3 py-1" *ngIf="isPersonalDetails">
      <p class="text-kredio-hint">Account</p>

      <!-- Personal Details -->
      <div class="col-2 p-0 d-flex justify-content-center"  >
        <img (click)="redirectToUrl('isDisplayProfile')"
             class="img-fluid p-1"
             src="assets/images/user-menu/profile_display.svg" >
      </div>
      <div class="col-9 col-sm-8" >
        <p class="mt-2 option-menu mb-0">User Profile</p>
      </div>
      <div class="col-1 col-sm-2 div-pointer p-0" (click)="redirectToUrl('isDisplayProfile')">
        <img
          class="img-fluid "
          src="assets/images/user-menu/continue.svg" >
      </div>

      <!-- Email Address -->
      <div class="col-2 p-0 d-flex justify-content-center"  >
        <img (click)="redirectToUrl('isEmailAddress')"
             class="img-fluid p-1"
             src="assets/images/user-menu/profile_display.svg" >
      </div>
      <div class="col-9 col-sm-8" >
        <p class="mt-2 option-menu mb-0">Email Address</p>
      </div>
      <div class="col-1 col-sm-2 div-pointer p-0" (click)="redirectToUrl('isEmailAddress')">
        <img
          class="img-fluid "
          src="assets/images/user-menu/continue.svg" >
      </div>

      <!-- Phone Number -->
      <div class="col-2 p-0 d-flex justify-content-center"  >
        <img (click)="redirectToUrl('isPhoneNumber')"
             class="img-fluid p-1"
             src="assets/images/user-menu/profile_display.svg" >
      </div>
      <div class="col-9 col-sm-8" >
        <p class="mt-2 option-menu mb-0">Phone Number</p>
      </div>
      <div class="col-1 col-sm-2 div-pointer p-0" (click)="redirectToUrl('isPhoneNumber')">
        <img
          class="img-fluid "
          src="assets/images/user-menu/continue.svg" >
      </div>

      <!-- Addresss -->
      <div class="col-2 p-0 d-flex justify-content-center"  >
        <img (click)="redirectToUrl('isPostalAddress')"
             class="img-fluid p-1"
             src="assets/images/user-menu/profile_display.svg" >
      </div>
      <div class="col-9 col-sm-8" >
        <p class="mt-2 option-menu mb-0">Postal Address</p>
      </div>
      <div class="col-1 col-sm-2 div-pointer p-0" (click)="redirectToUrl('isPostalAddress')">
        <img
          class="img-fluid "
          src="assets/images/user-menu/continue.svg" >
      </div>

    </div>


    <!-- Bank Account Actions -->
    <div class="row my-4 menu-separator rounded-3 py-1">
      <p id="ba_title" class="text-kredio-hint">Bank Account Actions</p>

      <div id="ba_img" class="col-2 p-0 d-flex justify-content-center"
           (click)="redirectToUrl('/main/home/payroll')" >
           <img class="img-fluid" src="assets/images/user-menu/payroll.svg" >
      </div>
      <div class="col-9 col-sm-8">
          <p id="ba_p" class="mt-2 option-menu mb-0"
             (click)="redirectToUrl('/main/home/payroll')">Configure Payroll</p>
        </div>
      <div id="ba_btn" class="col-1 col-sm-2 div-pointer p-0"
           (click)="redirectToUrl('/main/home/payroll')">
          <img class="img-fluid " src="assets/images/user-menu/continue.svg" >
      </div>

    </div>

    <!-- Settings -->
    <div class="row my-4 menu-separator rounded-3 py-1">

      <p id="p_settings" class="text-kredio-hint">Settings</p>

      <div class="col-12 my-2" >
        <div class="row">
          <div id="al_img" class="col-2 p-0 d-flex justify-content-center"
               (click)="redirectToUrl('/main/alerts')" >
            <img class="img-fluid" src="assets/images/user-menu/notifications.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p id="al_p" class="mt-2 option-menu mb-0"
               (click)="redirectToUrl('/main/alerts')" >Notifications</p>
          </div>
          <div id="al_btn" class="col-1 col-sm-2 div-pointer p-0" (click)="redirectToUrl('/main/alerts')">
            <img class="img-fluid " src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-2">
        <div class="row">
          <div class="col-2 p-0 d-flex justify-content-center" >
            <img
              class="img-fluid"
              src="assets/images/user-menu/faq.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">FAQ</p>
          </div>
          <div class="col-1 col-sm-2 div-pointer p-0">
            <img
              class="img-fluid "
              src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-2" >
        <div class="row">
          <div class="col-2 p-0 d-flex justify-content-center" >
            <img
              class="img-fluid"
              src="assets/images/user-menu/help.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">Help</p>
          </div>
          <div class="col-1 col-sm-2 div-pointer p-0">
            <img
              class="img-fluid "
              src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-2" >
        <div class="row">
          <div class="col-2 p-0 d-flex justify-content-center" >
            <img
              class="img-fluid"
              src="assets/images/user-menu/tAndc.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">Terms & Conditions</p>
          </div>
          <div class="col-1 col-sm-2 div-pointer p-0">
            <img
              class="img-fluid "
              src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-2" >
        <div class="row">
          <div class="col-2 p-0 d-flex justify-content-center" >
            <img
              class="img-fluid"
              src="assets/images/user-menu/privacy.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">Privacy Policy</p>
          </div>
          <div class="col-1 col-sm-2 div-pointer p-0">
            <img
              class="img-fluid "
              src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-2" >
        <div class="row">
          <div class="col-2 p-0 d-flex justify-content-center" >
            <img
              class="img-fluid"
              src="assets/images/user-menu/about.svg" >
          </div>
          <div class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">About App</p>
          </div>
          <div class="col-1 col-sm-2 div-pointer p-0">
            <img
              class="img-fluid "
              src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

      <div class="col-12 my-5" >
        <div class="row">
          <div id="lo_img" class="col-2 p-0 d-flex justify-content-center" >
            <img id="lo_img_fc" class="img-fluid" (click)="redirectToUrl('logout')"
              src="assets/images/user-menu/logout.svg" >
          </div>
          <div id="lo_p" class="col-9 col-sm-8">
            <p class="mt-2 option-menu mb-0">Logout</p>
          </div>
          <div id="lo_btn" class="col-1 col-sm-2 div-pointer p-0">
            <img (click)="redirectToUrl('logout')" class="img-fluid"
                 src="assets/images/user-menu/continue.svg" >
          </div>
        </div>
      </div>

    </div>

    <div class="d-lg-none d-block" style="height: 3rem;"></div>

  </div>







