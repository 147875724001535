import {Component, Inject} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-change-psw-msg',
  templateUrl: './change-psw-msg.component.html',
  styleUrls: ['./change-psw-msg.component.css']
})
export class ChangePswMsgComponent {
  message !: string

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ChangePswMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeMessage():void{
    this.dialogRef.close()
    this.router.navigateByUrl('/')
  }
  ngOnInit( ) {
    this.message = this.data.message
  }

}
