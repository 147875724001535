import {Validator} from "./typesForm";

/// Validate field is not empty
const isNotEmpty = (str:string) => str.length != 0

export const isNotEmptyValidator: Validator = {
  checkFn: isNotEmpty,
  errorMsg: 'Field cannot be empty'
}

/// Validate field is not empty
const termsAccepted = (str:string) => str != ''

export const isTermAcceptedValidator: Validator = {
  checkFn: termsAccepted,
  errorMsg: 'Accept the terms in order to complete the sign up'
}

/// Validate field is empty or higher than  0
const higherEqualtoZero = (str:string) => Number(str) >= 0 || str == ''

export const isPositiveOrEmptyValidator: Validator = {
  checkFn: higherEqualtoZero,
  errorMsg: 'Input mush be positive or empty'
}

/// Validate field is higher than 0 & < 100
const higher0Lower100  = (str:string) => (Number(str) > 0 && Number(str) <= 100) || str == ''

export const isHigher0Lower100Validator: Validator = {
  checkFn: higher0Lower100,
  errorMsg: 'Input mush be between 1 and 100'
}

/// Validate input length is min length or longer
const checkMinLength = (minLength:number) => (str: string) => str.length >= minLength
export const checkLengthChrValidator = (minLength:number) => ( {
  checkFn: checkMinLength(minLength),
  errorMsg: `Field must be at least ${minLength} characters`
})


/// Validate input length is == 4
const checkEqualLength = (eLength:number) => (str: string) => str.length >= eLength
export const checkEqualLengthFc = (eLength:number) => ( {
  checkFn: checkEqualLength(eLength),
  errorMsg: `Field must be ${eLength} characters long`
})


/// Validate input length limit
const checkMaxLength = (maxLength:number) => (str: string) => str.length <= maxLength
export const checkMaxLengthValidator = (maxLength:number) => ( {
  checkFn: checkMaxLength(maxLength),
  errorMsg: `Character limit: ${maxLength}`
})

// Validate input value is min  or higher
const checkMinValue = (minValue:number) => (num: number) => Number(num) >= minValue
export const checkMinValueValidator = (minValue:number) => ( {
  checkFn: checkMinValue(minValue),
  errorMsg: `Field must be ${minValue} or higher`
})


// Validate SSN

const ssnRegex =/^\d{3}-?\d{2}-?\d{4}$/
const ssnCheck  = (str:string) => ssnRegex.test(str)

export const ssnValidator =  {
  checkFn: ssnCheck,
  errorMsg: `Field must be a valid SSN`
}


// Validate zip code

const zipcodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/
const zipCodeCheck  = (str:string) => zipcodeRegex.test(str)

export const zipCodeValidator = {
  checkFn: zipCodeCheck,
  errorMsg: `Provide a valid zip code`
}

// Validate address
const addressRegex = /\d+[ ](?:[A-Za-z0-9.-]+[ ]?)+(?:Avenue|Lane|Road|Boulevard|Drive|Street|Place|Ave|Dr|Rd|Blvd|Ln|St|Pl|ave|dr|rd|blvd|ln|st|pl)\.?/
const addressCheck = (str:string) => addressRegex.test(str)

export const addressValidator = {
  checkFn: addressCheck,
  errorMsg: 'Provide a valid address'
}

// Validate state
export const statesUSA = [
  'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FL','GA',
  'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD',
  'MA','MI','MN','MS','MO','MT','NE',
  'NV','NH','NJ','NM','NY','NC', 'ND','MP','OH','OK','OR','PA','PR',
  'RI','SC','SD','TN','TX','UT','VT','VA','VI','WA','WV','WI','WY'
]

const isState  = (state:string) => statesUSA.includes(state)

export const sateValidator = {
  checkFn: isState,
  errorMsg: 'Pick a valid state'
}

// Validate birthdate

const isValidDateRegex =  /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/

const dateCheck = (str:string) => isValidDateRegex.test(str)

export const dateValidator:Validator = {
  checkFn: dateCheck,
  errorMsg: 'Provide a valid date'
}

// Validate US bank account numbers

const isBankAccountRegex = /^[0-9]{4,17}$/

const validBankAccount = (str:string) => isBankAccountRegex.test(str)

export const  bankAccountValidator: Validator = {
  checkFn: validBankAccount,
  errorMsg: 'Provide a valid bank account number'
}

// Validate US routing number

const isUSRoutingNumber = /^[012346789][0-9]{8}$/
const validUSRoutingNumber = (str:string) => isUSRoutingNumber.test(str)

export const usRoutingNumberValidator:Validator = {
  checkFn: validUSRoutingNumber,
  errorMsg: 'Provide a valid US routing number'
}

// Validate email address

const isEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const validEmailAddress = (str:string) => isEmailRegex.test(str)

export const emailValidator: Validator = {
  checkFn: validEmailAddress,
  errorMsg: 'Provide a valid email address'
}

// Validate currency
export const globalCurncy = [
  'AED',	'AFN',	'ALL',	'AMD',	'AOA',	'ARS',	'AUD',	'AZN',	'BAM',	'BBD',
  'BDT',	'BGN',	'BHD',	'BIF',	'BND',	'BOB',	'BRL',	'BSD',	'BTN',	'BWP',
  'BYN',	'BZD',	'CAD',	'CDF',	'CHF',	'CLP',	'CNY',	'COP',	'CRC',	'CUP',
  'CVE',	'CZK',	'DJF',	'DKK',	'DOP',	'DZD',	'EGP',	'ERN',	'ETB',	'EUR',
  'FJD',	'GBP',	'GEL',	'GHS',	'GMD',	'GNF',	'GTQ',	'GYD',	'HNL',	'HTG',
  'HUF',	'IDR',	'ILS',	'INR',	'IQD',	'IRR',	'ISK',	'JMD',	'JOD',	'JPY',
  'KES',	'KGS',	'KHR',	'KMF',	'KPW',	'KRW',	'KWD',	'KZT',	'LAK',	'LBP',
  'LKR',	'LRD',	'LSL',	'LYD',	'MAD',	'MDL',	'MGA',	'MKD',	'MMK',	'MNT',
  'MRO',	'MUR',	'MVR',	'MWK',	'MXN',	'MYR',	'MZN',	'NAD',	'NGN',	'NIO',
  'NOK',	'NPR',	'NZD',	'OMR',	'PAB',	'PEN',	'PGK',	'PHP',	'PKR',	'PLN',
  'PYG',	'QAR',	'RON',	'RSD',	'RUB',	'RWF',	'SAR',	'SBD',	'SCR',	'SDG',
  'SEK',	'SGD',	'SLL',	'SOS',	'SRD',	'SSP',	'STD',	'SYP',	'SZL',	'THB',
  'TJS',	'TMT',	'TND',	'TOP',	'TRY',	'TTD',	'TWD',	'TZS',	'UAH',	'UGX',
  'USD',	'UYU',	'UZS',	'VEF',	'VND',	'VUV',	'WST',	'XAF',	'XCD',	'XOF',
  'YER',	'ZAR',	'ZMW',

]

const isCurrency  = (cur:string) => globalCurncy.includes(cur)

export const curncyValidator = {
  checkFn: isCurrency,
  errorMsg: 'Pick a valid currency'
}




// Validate Cash App tag

const cashAppTagRegex = /^[$]+\b[A-Za-z0-9]{1,}\b$/
const tagCACheck = (str:string) => cashAppTagRegex.test(str)

export const tagCAValidator: Validator = {
  checkFn: tagCACheck,
  errorMsg: 'Provide a valid cashTag i.e $tag11'
}


// Validate Cash App Tag is not taken

const existingCashAppTags = ['$maby', '$andrei']

const isAvailableCATag = (str:string) => !existingCashAppTags.includes(str)

export const tagAvailableValidator: Validator = {
  checkFn: isAvailableCATag,
  errorMsg:'The tag provided is already taken'
}


// Validate US phone

const usaPhoneRegex = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
const usaPhoneCheckorEmpty  = (str:string) =>  usaPhoneRegex.test(str) || str ==''

export const usaPhoneorEmptyValidator: Validator =  {
  checkFn: usaPhoneCheckorEmpty ,
  errorMsg: `Field must be a valid US phone number or empty`
}


// Validate US phone

const usaPhoneCheck  = (str:string) =>  usaPhoneRegex.test(str)

export const usaPhoneValidator: Validator =  {
  checkFn: usaPhoneCheck,
  errorMsg: `Field must be a valid US phone number`
}

// Validate US phone or Empty

const usaPhoneOrEmptyCheck  = (str:string) =>  usaPhoneRegex.test(str) || str == ''

export const usaPhoneOrEmptyValidator: Validator =  {
  checkFn: usaPhoneOrEmptyCheck,
  errorMsg: `Field must be a valid US phone number or empty`
}

// Validate email or US phone

const emailRegex =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const emailOrPhoneCheck  = (str:string) => emailRegex.test(str) || usaPhoneRegex.test(str)

export const emailOrPhoneValidator: Validator =  {
  checkFn: emailOrPhoneCheck,
  errorMsg: `Field must be a valid email or US phone number`
}

// Validate Email or Empty

const emailOrEmptyCheck  = (str:string) => emailRegex.test(str) || str == ''

export const emailOrEmptyValidator: Validator =  {
  checkFn: emailOrEmptyCheck,
  errorMsg: `Field must be a valid email or empty`
}

// Validate bank
export const banksIntegrated = [
  'Bank of America' , 'Chase',  'First Bank', 'Kredio' , 'Wells Fargo'
]

const isBanksIntegrated  = (cur:string) => banksIntegrated.includes(cur)

export const bankValidator = {
  checkFn: isBanksIntegrated,
  errorMsg: 'Pick a valid bank name'
}


// Validate international bank account numbers

const ibanRegex = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/

const isIBAN = (str:string) => ibanRegex.test(str)

export const isIBANValidator = {
  checkFn: isIBAN,
  errorMsg: 'Enter a valid bank account number'
}


// Validate country
export const validCountryIntTransfers = [
  'USA', 'Canada', 'Italy','Spain'
]

const isValidCountry  = (str:string) => validCountryIntTransfers.includes(str)

export const countryIntTransferValidator = {
  checkFn: isValidCountry,
  errorMsg: 'Pick a valid country'
}


// Validate place where to pick up a transfer
export const validPickUpSpots = [
  'Gas Station', 'Supermarket', 'Farmacy','Shopping Center'
]

const isValidSpot  = (str:string) => validPickUpSpots.includes(str)

export const pickUpSpotValidator = {
  checkFn: isValidSpot,
  errorMsg: 'Pick a valid spot to pick up the transfer'
}



// Validate password

// Minimum eight characters, at least one uppercase letter, one digit and one special character:

const validPassword = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

const isValidPassword = (str:string) => validPassword.test(str)

export const isPasswordValidator = {
  checkFn: isValidPassword,
  errorMsg: 'Enter a valid password'
}



// Validate area code

const areaCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/

const isValidAreaCode = (str:string) => areaCodeRegex.test(str)

export const isAreaCodeValidator = {
  checkFn: isValidAreaCode,
  errorMsg: 'Pick a valid area code'
}


// Validate age 18 years old or older

const today = new Date();

const minAge = new Date(today.getFullYear() - 18,
  today.getMonth(), today.getDate()
);
const isValidAge = (d:object) => d < minAge
export const isMinAgeValidator = {
  checkFn: isValidAge,
  errorMsg: 'You must be 18 years or older to open an account with Kredio'
}

// Validate date is future

const todayBis = new Date();
const isValidDate = (d:object) => d > todayBis
export const isMinDateValidator = {
  checkFn: isValidDate,
  errorMsg: 'The expiration date must be al least tomorrow'
}

// Validate date is past today
const isValidPastDate = (d:object) => d <= todayBis
export const isPastDateValidator = {
  checkFn: isValidPastDate,
  errorMsg: 'The issuing date is not valid'
}

// Validate expiration date is future

const minValidExpDate = new Date(today.getFullYear() + 1,
  today.getMonth(), today.getDate()
);
const isValidExpDate = (d:object) => d > minValidExpDate
export const isMinExpDateValidator = {
  checkFn: isValidExpDate,
  errorMsg: 'The expiration date must be al least one year from today.'
}
