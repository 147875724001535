import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-card-limit-msg',
  templateUrl: './card-limit-msg.component.html',
  styleUrls: ['./card-limit-msg.component.css']
})
export class CardLimitMsgComponent {

  @Input() title !: string
  @Input() message !:string
  @Output() confirmEditLimit = new EventEmitter<boolean>()
  constructor(

  ) { }
  cancel(){
    this.confirmEditLimit.emit(false)
  }

  confirm() {
    this.confirmEditLimit.emit(true)
  }
  ngOnInit() {

  }


}
