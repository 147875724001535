import {Component, Inject, Input, OnInit} from '@angular/core';
import { RecipientsService} from "../../../services/recipients.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {Router} from "@angular/router";
import {
  emailOrEmptyValidator,
  isNotEmptyValidator,
  usaPhoneOrEmptyValidator
} from "../../Shared/forms-shared/validators";

@Component({
  selector: 'app-recip-edit',
  templateUrl: './recip-edit.component.html',
  styleUrls: ['./recip-edit.component.css']
})
export class RecipEditComponent implements OnInit {

  deleted_success !:boolean
  @Input() validateOn: string = 'change'
  fieldsConfig !: FormFieldConfig[]

  selectedFile !: any
  edit_message :string = ''

  edit_rec : boolean = true

  edit_rec_success !:boolean

  constructor(
    private recipientsService: RecipientsService,
    private router:Router,
    public dialogRef: MatDialogRef<RecipEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  fieldWasEdited: any = {}
  validationErrors : any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){

    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }

  handleFileInput(event:any) {
    this.selectedFile = event.target.files[0]
    this.fieldValues = {... this.fieldValues, ['file']: event.target.files[0]}
  }
  submitEditRecipient(): void {

    this.fieldsConfig[2].validators = [ emailOrEmptyValidator ]
    this.fieldsConfig[3].validators = [ usaPhoneOrEmptyValidator ]

    // Check user inputs either email or phone number

    if( (!this.fieldValues.email) && (!this.fieldValues.phone) ) {
      this.fieldsConfig[2].validators = [ isNotEmptyValidator, emailOrEmptyValidator]
      this.fieldsConfig[3].validators = [ usaPhoneOrEmptyValidator ]
    }

    this.validationErrors = this.getvalidationErrors(true)

    if (Object.keys(this.validationErrors).length > 0)
      return

    const formData = new FormData()

    formData.append('recipient_id', this.fieldValues['recipient_id'].toString())
    formData.append('first_name', this.fieldValues['first_name'].toString())
    formData.append('last_name', this.fieldValues['last_name'].toString())
    formData.append('email', this.fieldValues['email'] )
    formData.append('phone', this.fieldValues['phone'] )
    formData.append('recipient_img', this.selectedFile)


    this.recipientsService.editRecipient(formData).subscribe({
      next: (res) => {
        if(res.e_code == 1){
          this.edit_message = res.e_msg
          this.edit_rec  = false
          this.edit_rec_success =true

          this.dialogRef.close()

          //setTimeout(()=>{
              const currentUrl = this.router.url;
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate([currentUrl]);})
          //  } , 1500)

          }}})
  }

  cancelEditRecipient():void{
    this.dialogRef.close()
    this.closeMsg()
  }

  closeMsg():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);})
  }

  ngOnInit() {
    this.fieldsConfig = this.recipientsService.formFields
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = this.data.recipient[field.name] || ""

    this.fieldValues['recipient_id'] = this.data.recipient.id
  }
}
