import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-complete',
  templateUrl: './modal-complete.component.html',
  styleUrls: ['./modal-complete.component.css']
})
export class ModalCompleteComponent {

}
