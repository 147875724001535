import {Component, OnInit} from '@angular/core';
import {Card, CardService} from "../../../services/card.service";

@Component({
  selector: 'app-cards-display',
  templateUrl: './cards-display.component.html',
  styleUrls: ['./cards-display.component.css']
})
export class CardsDisplayComponent implements OnInit{
  cards_list !: Card[]

  constructor(
    private cardsService: CardService
  ) {}

  ngOnInit() {
    this.cardsService.getCards().subscribe(res => {
      if(res.e_code === 1) {
        this.cards_list = res.ret.map((elem:any)=>elem.card)
      }
    })
  }

}
