import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-new-acc',
  templateUrl: './modal-new-acc.component.html',
  styleUrls: ['./modal-new-acc.component.css']
})
export class ModalNewAccComponent {

}
