<div class="container-fluid">
  <div class="row">
    <div class="text-center">
      <div class="my-5">
        <img src="assets/images/accounts/success.svg">
      </div>
      <p id="msgActivate">{{activateMsg}}</p>
    </div>
  </div>
  <div class="row my-3">
    <div class="offset-2 col-8" >
      <button class="violet-button p-3 w-100 rounded-3" routerLink="/home" >Enjoy your benefits!</button>
    </div>
  </div>
</div>
