import {Component, Input} from '@angular/core';
import {filter, Subject} from "rxjs";
import {Router} from "@angular/router";
import {CardService, MerchantCard} from "../../../../services/card.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {environment} from "../../../../../environments/environment";
import {HomeService} from "../../../../services/home.service";

@Component({
  selector: 'app-card-limit-merc',
  templateUrl: './card-limit-merc.component.html',
  styleUrls: ['./card-limit-merc.component.css']
})
export class CardLimitMercComponent {
  @Input() validateOn: string = 'change'
  @Input() isMerchLimit: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router : Router,
    private cardService : CardService,
    private homeService: HomeService

  ) { }

  // Web socket Token
  socket !: WebSocket
  token !:string

  merchantsFilter :string[] =[]
  merchants : string[] = []
  merchant !:string
  viewMerchList:boolean = false

  // Merchants
  merchantsList : any [] = []


  fieldWasEdited: any = {}
  fieldsConfig !: FormFieldConfig[]
  validationErrors : any = {}
  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }

  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }
  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  updateFieldValue(fieldName:string, newValue:any) {
    if(fieldName === 'merchants_info') {
      this.openSSE()
      this.sendMsgMerchantSSE(newValue)
    } else {
      this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
    }
  }

  // Merchants
  addmerchant():void{
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }

    let merch = this.fieldValues.merchants_info
    // Include new merch into filter list
    this.merchantsFilter.push(merch)

    let pushObj = { ...this.fieldValues, 'merchants_info':[merch]}
    this.merchantsList.push(pushObj)
    this.getEmptyForms()
    this.validationErrors = {}
    this.fieldWasEdited = {}
  }

  deleteMerchant(data:string):void {
      let  newMerchantList = this.merchantsList.filter((m:MerchantCard) => m.merchants_info[0]!==data)
      this.merchantsList = newMerchantList
      this.merchantsFilter = this.merchantsList.map((m:MerchantCard) => m.merchants_info[0])
  }

  getEmptyForms():void {
    this.fieldsConfig = this.cardService.formFieldsMercLimit
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.fieldValues = {...this.fieldValues , ['type'] : 1}
   this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}

  }

  returnMerchantData(){ // Executed by parent component Card-spend-limit-set
    return this.merchantsList
  }

  // SSE
  setMerchantValue(data:string){

    this.fieldValues = {...this.fieldValues, ['merchants_info']: data}

    this.setFieldToEdited('merchants_info')

    this.viewMerchList = false
    this.merchants = []
    this.closeSSE()
  }

  openSSE():void{
    this.homeService.getWSToken().subscribe(
      (res) => { if (res.e_code == 1) {
        // Open websocket to get merchants
        this.startSSE(res.Token)
      }});
  }

  startSSE(data:string):void{
    const websocketUrl = environment.wsUrl + '/merchant_name/?Token='+data;
    this.socket = new WebSocket(websocketUrl);
    this.socket.onopen = () => {
      console.log("OPEN");
    };
  }

  sendMsgMerchantSSE(data:string){
    let merchant = { merchant_info: data, type:0}
    this.socket.send(JSON.stringify(merchant))
    this.socket.onmessage = (event) => {
      const merchantList = JSON.parse(event.data).results.map((res:any)=> res.name)
      if(merchantList.length>0 && data!==""){
        this.viewMerchList = true

        // Exclude merchants already included in the limits list
        this.merchants = merchantList.filter((m:string) => !this.merchantsFilter.includes(m))

      } else {
        this.viewMerchList = false
        this.merchants = []
      }
    };}

  // Close websocket
  closeSSE() :void {
    this.socket.close(1000, "CLOSE SUCCESS")
    //console.log("CLOSE")
  }

  getMerchantsFilter(data:any) { // Executed by parent component
    this.merchantsFilter = data.map((limit:any)=>limit.merchants_info[0])
  }
  ngOnInit() {
      this.merchantsList = []
      this.getEmptyForms()
      this.isMerchLimit.subscribe(response => {
        if(response === true){
          this.fieldValues = {...this.fieldValues , ['type'] : 1}
          this.fieldValues = {...this.fieldValues , ['merchants_info'] : []}
        } else {
          this.validationErrors = {}
        }
      })
      this.openSSE()
  }
}
