<div class="container-fluid">
  <br>
  <br>
  <div class="row d-flex align-items-center  py-3 d-lg-none" >
    <p class="p-title ">Forgot Password</p>
  </div>

  <div class="row d-flex align-items-center mb-5 d-none d-lg-block "  >
    <p class="p-title">Forgot Password</p>
  </div>

  <!-- Confirm phone number -->
  <div *ngIf="confirmPhoneNumber">

    <p class="p-wording">Confirm you phone number to submit the confirmation code.</p>

    <div class="row my-3" >
      <form #loginForm="ngForm" (ngSubmit)="submitPhoneNumber(loginForm.value)" id="formPhone">

        <div class="d-flex justify-content-center">
          <input placeholder="Enter you phone number"
                 type="string"
                 name="phone"
                 id="inputPhone"
                 class="ps-3 rounded-3 py-4 w-100 border-0" ngModel>
        </div>

        <div class="h-100"></div>

        <div class="d-flex justify-content-center bot-buffer1">
          <button class="violet-button rounded-4 w-100 py-3 m-2 " id="btnConfirm"
          (click)="sendCodeAgain()"> Confirm </button>
        </div>

        <div class="d-flex justify-content-center ">
          <button class="yellow-button rounded-4 w-100 py-3 m-2 " id="btnReturn"
            routerLink="/" > Return </button>
        </div>

      </form>
    </div>

  </div>

  <!-- Submit code -->
  <div *ngIf="confirmCodeNumber">

    <p class="p-wording">Submit the code 4-digit code sent to your phone number.</p>

    <div  class="row my-3">
      <div class="col-12">
        <code-input
          id="codeForm"
          [isCodeHidden]="true"
          [codeLength]="4"
          (codeCompleted)="onCodeCompleted($event)">
        </code-input>
      </div>
    </div>

    <div class="row my-3">
      <div id="sendCodeAgain" (click)="sendCodeAgain()">
        <img (click)="sendCodeAgain()"
             src="../../../../assets/images/signup/send_again.svg"
             class="img-fluid button-pointer">
      </div>
      <p class="p-resend my-2 fw-bold" >Send code again</p>
    </div>

    <div class="row d-flex justify-content-center bot-buffer1">
      <button class="violet-button rounded-4 w-100 py-3 m-2 ">
        Confirm
      </button>
    </div>


  </div>

  <!-- Change password credentials -->
  <div *ngIf="changePassword">

    <div class="row" >
      <form #loginForm="ngForm" id="passwordForm" (ngSubmit)="submitNewPassword(loginForm.value)">

        <div class="d-flex justify-content-center my-3">
          <input placeholder="Password"
                 type="string"
                 id="new_password"
                 name="new_password"
                 class="ps-3 rounded-3  w-100 border-0" ngModel>
        </div>

        <div class="d-flex justify-content-center my-3">
          <input placeholder="Confirm password"
                 type="string"
                 id ="new_password_2"
                 name="new_password_2"
                 class="ps-3 rounded-3  w-100 border-0"  ngModel>
        </div>


        <div class="d-flex align-items-center my-3 w-100 p-wording-pswd">
          <p> Password must contain at least eight characters, one uppercase letter,
            one digit and one special character
          </p>
        </div>

        <div class="d-flex justify-content-center bot-buffer1">
          <button class="violet-button rounded-4 w-100 py-3 m-2 ">
            Confirm
          </button>
        </div>

      </form>
    </div>

  </div>

</div>

