<div class="container-fluid py-1">

  <div class="row px-2" >
    <div class="col-1 text-center p-0" >
      <img class="img-fluid" src="assets/images/cards/cards_icon.svg">
    </div>
    <div class="col-10 d-flex align-items-center" >
      <p id="pTitle" class="p-title mb-0" >Card Options</p>
    </div>
    <div  id="btnClose" class="col-1 text-center p-0 button-pointer"
          (click)="cancel()">
      <img class="img-fluid"  src="assets/images/accounts/close.svg">
    </div>
  </div> <!--Close row-->

  <!--List of more options available-->
  <div *ngIf="!confirmSelection && !isPrimary" class="px-2" >
    <div>
      <p class="p-sub-title my-1">Select card option</p>
    </div>
    <div class="row py-3">
      <div class="col-11 offset-1">
        <div *ngIf="cardType !='Physical'" class="form-check  checkbox-lg">
          <label class="form-check-label pt-3" for="checkboxCard">
            <input  class="form-check-input me-3" type="checkbox"
                   [ngModel]="cancelCardSelected" (ngModelChange)="cancelCardOption()"
                   id="checkboxCard">
            Cancel Card
          </label>
        </div>
      </div>
    </div>
    <div  class="row text-center px-1 my-3"  id="btnCloseBottom">
      <div class="col-10 offset-1">
        <button id="btnCancel" class="violet-button py-3 px-2 w-100 rounded-4 border-0"
                (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>

  <!--Selection: Card Cancel-->
  <div *ngIf="confirmSelection">
    <div *ngIf="cancelCardSelected" class="row">
      <div class="col-10 offset-1">
        <div class="row text-center my-5">
          <p id="p-msg-cs" class="p-msg">Confirm you want to delete the card selected</p>
        </div>
        <div  class="row text-center px-2 my-3">
          <button class="violet-button py-3 px-5 w-100 rounded-4 border-0" id="btnRemoveCard"
                  (click)="removeCard()">Remove Card</button>
        </div>
        <div  class="row text-center px-2 my-3">
          <button class="yellow-button py-3 px-5 w-100 rounded-4 border-0" id="btnCancelSelection"
                  (click)="cancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</div><!--Close container-->


