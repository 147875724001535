
<!--Display cards : Large screen-->
<div class="container-fluid d-none d-sm-block">

  <div class="row" >

    <div class="col-3 p-0" >
      <img id="cardImg"
        src='assets/images/cards/card_template_black.svg'
           (click)="displayCardInfo(cardData)"
           class="img-fluid h-100 rounded-4"
           alt="Kredio Card"
      >
    </div>

    <div class="col-4 py-3 ">
      <div class="ms-2  h-100 d-flex flex-column justify-content-between" >
        <p  id="cardName" class="p-title mb-1">{{cardData.name}}</p>
        <p *ngIf="cardData.card_type=='Virtual'" id="Virtual" class="p_cardType mb-1">Virtual</p>
        <p *ngIf="cardData.card_type=='Kids Physical'" id="Kids Physical" class="p_cardType mb-1">Physical</p>
        <p class="p-details mb-1">Available Balance</p>
        <p  id="cardAmount"  class="p-amount mb-1">
          ${{cardData.balance|number :'.2'}}
        <p class="text-kredio-hint mb-0">Link to Kredio Account - {{this.lastFourDigits}}</p>
      </div>

    </div>

    <div class="col-2 d-flex align-items-center justify-content-center p-0">

      <img id="cardNOTprimary" *ngIf="!cardData.is_primary"
           src="assets/images/cards/default.svg"
           alt="Lock" class="img-fluid  h-75 w-75" >

      <img  id="cardPrimary"  *ngIf="cardData.is_primary"
           src="assets/images/cards/default_yellow.svg"
           alt="Lock" class="img-fluid  h-75 w-75" >
    </div>

    <div class="col-2  d-flex align-items-center justify-content-center p-0">
      <img  id="cardNOTLocked" *ngIf="!cardData.is_locked"
            (click)="toggleLock(this.accountId , this.cardData.id)"
           src="assets/images/cards/lock.svg"
           alt="Lock" class="img-fluid h-75 w-75"
      >
      <img  id="cardLocked" *ngIf="cardData.is_locked"
           (click)="unlockCard(this.accountId , this.cardData.id)"
           src="assets/images/cards/lock_yellow.svg"
           alt="Lock" class="img-fluid h-75 w-75" >
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center p-0 "  >
      <img id="cardArrow" src="assets/images/cards/arrow.svg" class="img-fluid m-0 w-50"
           (click)="displayCardInfo(cardData)">
    </div>

  </div>

  <div id="msgDefault" class="alert alert-warning alert-dismissible" role="alert" *ngIf="activate_default == true">
    {{this.message_default}}
    <button class="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
  </div>

  <div id="msgLocked" class="alert alert-danger alert-dismissible" role="alert" *ngIf="activate_lock== true">
   {{this.message_lock}}
    <button class="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
  </div>

</div>



<!--Responsive display cards-->

<div class="container-fluid d-sm-none d-block p-0 responsive-card p-2 rounded-3">

  <div class="row">
    <div class="ms-2" >
      <p *ngIf="cardData.card_type=='Virtual'" class="p-title mb-0 fw-bold">{{cardData.name}} <span class="p_cardType"> Virtual</span></p>
      <p *ngIf="cardData.card_type=='Kids Physical'" class="p-title mb-0 fw-bold">{{cardData.name}} <span class="p_cardType"> Cashback</span></p>
      <p class="p-details mb-0">Available Balance</p>
      <p class="p-amount mb-0">
        ${{cardData.current_spending|number :'.2'}}
      </p>
      <p class="text-kredio-hint">Link to Kredio Account - {{this.lastFourDigits}}</p>
    </div>
  </div>

  <div class="row p-0" >
    <div class="col d-flex text-center" >
      <img src='assets/images/cards/card_template_black.svg' class=" img-fluid mx-0"
           (click)="displayCardInfo(cardData)"
           alt="Kredio Card"
      >
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-4 d-flex align-items-center justify-content-center">

      <img *ngIf="!cardData.is_primary"
           src="assets/images/cards/default.svg"
           alt="Lock"class=" img-fluid " >

      <img *ngIf="cardData.is_primary"
           src="assets/images/cards/default_yellow.svg"
           alt="Lock"class=" img-fluid " >
    </div>

    <div class="col-4  d-flex align-items-center justify-content-center p-0 ">
      <img  *ngIf="!cardData.is_locked"
            (click)="toggleLock(this.accountId , this.cardData.id)"
            src="assets/images/cards/lock.svg"
            alt="Lock"class=" img-fluid  "
      >
      <img *ngIf="cardData.is_locked"
           (click)="unlockCard(this.accountId , this.cardData.id)"
           src="assets/images/cards/lock_yellow.svg"
           alt="Lock"class=" img-fluid  "
      >
    </div>

    <div class="col-4 d-flex align-items-center justify-content-center p-0" >
      <img src="assets/images/cards/more.svg"
           class="img-fluid" (click)="displayCardInfo(cardData)">
    </div>

  </div>

</div>
