
<div class="container-fluid h-100">
  <div class="row h-100">

    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5">

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started d-flex align-items-end justify-content-center">
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center">
          <img src="assets/images/login/logo.svg" class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none py-5" style="height: 70%">
        <div class="d-flex justify-content-center h-100">
          <img *ngIf="currentRoute=='/signup/main'"
               class="img-fluid" src="../../../../assets/images/login/picture_asset.svg" alt="Signup">
          <img *ngIf="currentRoute=='/signup/legal'"
               class="img-fluid" src="../../../../assets/images/login/legal_terms.svg" alt="Legal">
          <img *ngIf="currentRoute=='/signup/phone'"
               class="img-fluid" src="../../../../assets/images/login/phone_number_signup.svg" alt="Phone">
        </div>
      </div>

      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none">
        <div class="text-center">
          <a class="bottom-a-white" routerLink="'/'"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="'/'"> Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white" routerLink="'/'"> Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div><!--    Close col-->

    <div class="col-lg-4 offset-lg-1 h-100 py-0">
      <router-outlet></router-outlet>
    </div> <!--    Close col-->

  </div><!--    Close row-->
</div><!--    Close container-->
