<div class="container-fluid">

  <div class="row" >

    <div class="col-lg-1 col-2 p-0 d-flex align-content-around" >
      <img class="img-fluid"
           src="assets/images/accounts/accounts_icon.svg"/>
    </div>

    <div class="col-lg-10 col-8 d-flex align-items-center">
      <p class="p-title mb-0" >Account Information</p>
    </div>

    <div class="col-lg-1 col-2 p-0 d-flex align-content-around close-button" >
      <img (click)="closeData()" class="img-fluid"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div> <!--Close row-->

  <div class="row my-2">

    <div class="col-md-6 offset-md-3 col-12">

      <div class="d-flex my-3">
        <div   class="d-flex align-content-center">
          <img  src="assets/images/cards/card_bullet_point.svg"
                alt="bullet"
                class=" img-fluid"
          >
        </div>
        <div class="ps-2">
          <p class="p-subtitle mb-0" > Account Number</p>
          <p id="accountData" class="p-wording mb-0" >{{account_main.number}}</p>
        </div>
      </div> <!--Close row-->

      <div class="d-flex my-3">
        <div   class="d-flex align-content-center">
          <img  src="assets/images/cards/card_bullet_point.svg"
                alt="bullet"
                class=" img-fluid"
          >
        </div>
        <div class="ps-2">
          <p class="p-subtitle mb-0" >Routing Number</p>
          <p id="routingData"  class="p-wording mb-0" >{{account_main.routing}}</p>
        </div>
      </div> <!--Close row-->

    </div>

  </div> <!--Close row-->

  <div class="row">
    <div class="col-12">
      <button class="violet-button rounded-4 w-100 py-3"
              (click)="closeData()" id="btnClose"
      >Close</button>
    </div>
  </div> <!--Close row-->

</div>
