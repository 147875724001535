import {Component, Input} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {CardService, MCCCard, MCCDataAll} from "../../../../../services/card.service";


@Component({
  selector: 'app-card-l-mcc-edit',
  templateUrl: './card-l-mcc-edit.component.html',
  styleUrls: ['./card-l-mcc-edit.component.css']
})
export class CardLMccEditComponent {

  @Input() editDataMCC !:MCCDataAll[]
  @Input() editDataCodes !:string[]
  @Input() validateOn: string = 'change'
  @Input() isMCCLimit: Subject<boolean> = new Subject<boolean>();
  constructor(
    private router : Router,
    private cardService : CardService,

  ) { }

  // Web socket Token
  socket !: WebSocket
  token !:string

  // MCC Codes
  mccCodes:string[] =[] // SSE complete list
  codeList:string[] = []

  // Confirm message
  isDeleteMCCLimit :boolean = false
  nameDeleteMCC !:string


  // MCCs
  mccsFilter :string[] =[]
  codeMccsFilter :string[] =[]

  mccsList : MCCCard [] = []


  deleteMCC(data:string):void {
    if(this.editDataMCC) {
      let dataMerc = this.editDataMCC.filter((m:any) => m.merchants_info[0] === data)[0]
      let payload = {
        spendinglimit_id: dataMerc.spendinglimit_id,
        card_id:dataMerc.card_id,
        loan_id:dataMerc.loan_id,  limited: 1
      }
      this.cardService.deleteSpendLimit(payload).subscribe({ next:(res) =>{

          let mercIdx = this.mccsList.map((m:any)=> m.merchants_info[0]).indexOf(data)
          let mercCodeDelete = this.codeList[mercIdx]

          let  newMerchantCodeList = this.codeList.filter((m:string) => m!==mercCodeDelete)
          this.codeList = newMerchantCodeList

          let  newMerchantList = this.mccsList.filter((m:any) => m.merchants_info[0]!==data)
          this.mccsList = newMerchantList

          // Update filter arrays

          this.codeMccsFilter = this.codeMccsFilter.filter((code:string) =>code!== mercCodeDelete)
          this.mccsFilter = this.mccsFilter.filter((mcc:string) => mcc!== data)

        }})

    } else {
      let  newMerchantList = this.mccsList.filter((m:any) => m.merchants_info[0]!==data)
      this.mccsList = newMerchantList

      let idx = this.mccsFilter.indexOf(data)
      let code = this.codeMccsFilter[idx]
      this.mccsFilter = this.mccsFilter.filter((mcc:string) => mcc!== data)
      this.codeMccsFilter = this.codeMccsFilter.filter((c:string) =>c!== code)
    }
  }

  returnMCCData(){ // function is executed by parent component
    if (this.mccsList.length === 0){
      return undefined
    } else {
      return this.mccsList
    }
  }

  returnMCCCodes(){ // function is executed by parent component
    if (this.codeList.length === 0){
      return undefined
    } else {
      return this.codeList
    }
  }

  deleteMCCLimitAlert(merch:string){
    this.isDeleteMCCLimit = true
    this.nameDeleteMCC = merch
  }

  // Confirm delete MCC
  delete(data:boolean){
    this.isDeleteMCCLimit = false
    if (data) {
      this.deleteMCC(this.nameDeleteMCC)
    }
  }

  ngOnInit() {
    for(let limit of this.editDataMCC){
        let lim = {
          merchants_info: limit.merchants_info,
          amount:limit.amount, period: limit.period,
          type: limit.type, spendinglimit_id : limit.spendinglimit_id
        }
        this.mccsList.push(lim)
      }
    this.codeList = this.editDataCodes
  }
}
