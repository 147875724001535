<div class="container-fluid px-0 background-container  h-100 py-0 mt-1 ">
  <!-- Transactions List -->
  <div *ngIf="displayTransactions" >


    <div class = "row my-2 mx-1 " >

        <div class="col-sm-7 col-12 ">
            <div class="form-floating">
              <input [class] ="'form-control inputStyle'"
                     [ngModel]="search_word"
                     [placeholder]="'Search'"
                     (ngModelChange)="filterTransaction('search_word', $event)"
              >
              <label class="form-label">Search</label>
          </div>  <!--Close form -->
        </div> <!--Close col -->

      </div> <!--Close row -->


    <!--Table displayed on large screens -->

    <div class=" d-none d-sm-inline my-1">
      <div [ngClass]=" transactions_display.length > 4 ? 'scroll-cardstr' : '' ">
        <table class="table">
          <thead>
          <tr>
            <th>Transactions</th>
            <th>Description</th>
            <th>Status</th>
            <th class="text-center">Amount</th>
            <th class="text-center">Balance</th>
          </tr>
          </thead>
          <tbody  *ngFor="let tr of transactions_display">
          <tr>
            <td class="td-when">{{tr.dt_when}}</td>
            <td class="td-desc">{{tr.desc}}</td>
            <td class="td-desc">{{tr.status}}</td>
            <td class="td-amount text-center"  (click)="disputeThisTransaction(tr )" >
              <button  class="span-btn rounded-4 text-center "
                       data-toggle="tooltip"
                       title="Dispute Transaction"
              > ${{tr.amount|number :'.2'}}
              </button>
            </td>
            <td id="balance" class="text-center td-balance">${{tr.account_balance|number :'.2'}}</td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>

    <!--Table displayed on small screens -->

    <div class=" d-sm-none d-inline">
      <div [ngClass]=" transactions_display.length > 4 ? 'scroll-cardstr' : '' " >
        <div class="row responsive-tran" *ngFor="let tr of transactions_display">
          <div class="col-7 d-flex align-items-center">
            <div>
              <p class="p-desc">{{tr.desc}}</p>
              <p class="p-status">{{tr.status}}</p>
            </div>
          </div>
          <div class="col-5">
            <p class=""  (click)="disputeThisTransaction(tr)">
              <button  class="p-amount border-0"
                       data-toggle="tooltip"
                       title="Dispute Transaction"
              > ${{tr.amount|number :'.2'}}
              </button>
            </p>
            <p class="text-center p-desc border-0 text-muted">{{tr.account_balance}}</p>
          </div>

        </div>
      </div>
    </div>

    <div class="border-top-solid d-sm-none d-md-none d-lg-block">
      <button id="buttonSeeAll" class="mb-0 pt-2 button-toggle" (click)="seeAllActivity()">{{buttonName}}</button>
    </div>

    <div class="d-none d-sm-block d-md-block d-lg-none ">
      <button id="buttonSeeAllsm" class="mb-0 pt-2" (click)="seeAllActivity()">{{buttonName}}</button>
    </div>

  </div>

<!--   NO Transactions List-->
  <div *ngIf="!displayTransactions" class="h-100">
    <div class="h-100 d-flex justify-content-center text-center align-items-center ">
      <div>
        <div class="d-flex justify-content-center text-center align-items-center ">
          <img src="assets/images/transactions/dollar.svg">
        </div>
        <p class="p-b-nt">No transactions yet</p>
        <p class="p-b-nt">Start using your card and you will see the transactions displayed here.</p>
      </div>
    </div>
  </div>

</div>




