import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AccountService} from "../../../services/account.service";

@Component({
  selector: 'app-account-new',
  templateUrl: './account-new.component.html',
  styleUrls: ['./account-new.component.css']
})
export class AccountNewComponent implements OnInit{


  @Input() account_id !: string

  isConfirmed :boolean = false
  constructor(
  private router: Router,
  ) { }


  newAccount():void{
    this.isConfirmed = true
      // this.accountService.addNewAccount(this.account_id).subscribe({
      //   next:(res)=>{
      //     this.isConfirmed = true
      //   }})
  }


  return():void{
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/main/home']);})
      }

      ngOnInit() {
    alert(this.account_id)
      }
}
