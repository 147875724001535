import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-bar-horizontal',
  templateUrl: './nav-bar-horizontal.component.html',
  styleUrls: ['./nav-bar-horizontal.component.css']
})
export class NavBarHorizontalComponent {

}
