import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Subject, takeUntil} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-signup-main',
  templateUrl: './signup-main.component.html',
  styleUrls: ['./signup-main.component.css']
})
export class SignupMainComponent  implements OnInit, OnDestroy {

  currentRoute !:string
  showError: boolean = false;
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url

    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
