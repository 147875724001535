import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {Router} from "@angular/router";
import {CardService} from "../../../services/card.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-card-activate',
  templateUrl: './card-activate.component.html',
  styleUrls: ['./card-activate.component.css']
})
export class CardActivateComponent implements OnInit{

  @Input() validateOn: string = 'change'
  fieldsConfig !: FormFieldConfig[]

  validationErrors : any = {}
  fieldWasEdited: any = {}
  startActivation: boolean = true
  cardWasActivated!: boolean


  today = new Date();
  dateExpiration !: NgbDateStruct;
  minAllowedDateOfExp = new NgbDate(this.today.getFullYear() + 1, this.today.getMonth()+1, this.today.getDate());
  tempDOE !:NgbDateStruct


  constructor(
    private router: Router,
    private cardService: CardService,
    public dialogRef: MatDialogRef<CardActivateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }
  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }
  cancelActivate(): void{
    this.dialogRef.close()
  }
  activateCard():void {
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0)
      return

    // Apply correct YYYY-MM-DD format to exp date
   this.tempDOE = this.fieldValues['card_exp_date']
   const currentExpDate = `${this.tempDOE.year}-${this.tempDOE.month}-${this.tempDOE.day}`
   this.fieldValues = { ... this.fieldValues, 'card_exp_date': currentExpDate}

   this.cardService.activateCard(this.fieldValues).subscribe({
        next:(res)=>{
          if(res.e_code===1){
            this.startActivation = false
            this.cardWasActivated = true
          }
        }})
  }
  ngOnInit() {

     this.fieldsConfig =  this.cardService.formFieldsActivateCard

     for (let field of this.fieldsConfig)
       this.fieldValues[field.name] = ''

     this.fieldValues['loan_id'] = this.data.idAccount
     this.fieldValues['card_id'] = this.data.idCard
     this.fieldValues['card_number'] = this.data.numCard
   }
}
